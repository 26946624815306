import React, { useState, useEffect } from 'react';
import {
  StyledCustomerDetailsPage,
  StyledCustomerInfoCard,
  StyledCustomerDetailsHeader,
  StyledCustomerDetailsBody,
  StyledShippingCard,
  StyledTagsCard,
  StyledLoader,
} from './styles';
import {
  StyledAdditionalCustomerInfoCard,
  StyledExpandableCard } from './stylesCDP';
import ButtonWithDropdown from 'components/buttonWithDropdown';
import { Link, navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetailsById } from './actions';
import customerDetailsSelectors from './selectors';
import {
  Loading,
  Snackbar,
  InputChips,
  CardLoader,
  Button
} from '@teamfabric/copilot-ui';
import PageHeading from 'components/page-heading';
import { AddressLink } from 'modules/Customers/styles';
import { CSRController } from '@copilot/mfa-communication';
import { hasPermission } from 'src/hooks/hasPermission';
import {
  CUSTOMER_PERMISSIONS,
  TAG_FIELD_VALIDATION,
} from 'src/lib/data/constants';

const CustomerDetailsPageCDP = props => {
  const dispatch = useDispatch();
  const { customerId } = props;
  const customer = useSelector(customerDetailsSelectors.getCustomerDetails);
  const customerLoading = useSelector(
    customerDetailsSelectors.customerLoadingStatus
  );

  const [tagError, setTagError] = useState(null);
  const [tagFieldError, setTagFieldError] = useState('');
  const [userTags, setUserTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [customerStatus, setCustomerStatus] = useState(true);
  const canEditCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_UPDATE]);
  const canReadAddress = hasPermission([
    CUSTOMER_PERMISSIONS.CUST_ADDRESS_READ,
  ]);
  const canReadTags = hasPermission([CUSTOMER_PERMISSIONS.CUST_TRAIT_READ]);
  const canCreateTag = hasPermission([CUSTOMER_PERMISSIONS.CUST_TRAIT_CREATE]);

  useEffect(() => {
    dispatch(getCustomerDetailsById(customerId));
  }, [dispatch, customerId]);
  useEffect(() => {
    setUserTags(customer.traits ? customer.traits?.map(trait => trait) : []);
    setCustomerStatus(customer.isActive);
  }, [customer]);

  const CustomerDetailsHeader = () => {
    return (
      <StyledCustomerDetailsHeader>
        <div className='header-top'>
          <div className='title'>
            {customer.name} <CustomerStatus isActive={customerStatus} />
          </div>
          <div className='last-updated'>
            Last updated {formatDateTime(customer.updatedAt)}
          </div>
        </div>
        <div className='header-bottom'>
          <div className='header-buttons'>
            {canEditCustomer && (
              <Button
                onClick={() => {
                  navigate('/customer/customers/edit');
                }}
                size='large'
                text='Edit customer info'
                isPrimary={false}
              />
            )}
          </div>
          <div>
            {canReadAddress && (
              <AddressLink>
                <Link to={`/customer/customers/${customer._id}/addresses`}>
                  Manage Addresses
                </Link>
              </AddressLink>
            )}
          </div>
        </div>
      </StyledCustomerDetailsHeader>
    );
  };

  const CustomerStatus = ({ isActive }) => {
    return (
      <ButtonWithDropdown
        disabled={!canEditCustomer}
        data={[
          {
            type: 'simpledropdown',
            data: [
              { id: 1, label: 'Active', value: true },
              { id: 2, label: 'In-Active', value: false },
            ],
            onClickHandler: toggleCustomerStatus,
          },
        ]}
        label={isActive ? 'Active' : 'Inactive'}
        buttonClass={isActive ? 'active-cx' : 'inactive-cx'}
        btnIcon='DownArrow'
        iconPosition='right'
        iconSize={18}
      />
    );
  };

  const toggleCustomerStatus = () => {
    const current = customerStatus;
    setCustomerStatus(!customerStatus);
    CSRController.updateCustomer(
      {
        name: customer.name,
        email: customer.email,
        partyType: customer.partyType,
        isActive: !customer.isActive,
      },
      customerId
    )
      .then(async res => {
      })
      .catch(err => {
        setCustomerStatus(current);
      });
  };

  const CustomerInfoCard = () => {
    return (
      <StyledCustomerInfoCard>
        <div className='title'>
          <div className='label'>Basic information</div>
          <div className='attribute-count'>Showing 3 attributes</div>
        </div>
        <div className='card-content'>
          <div className='card-row'>
            <div className='card-col'>
              <div className='card-col-title'>Customer name</div>
              <div className='card-col-body'>{customer.name}</div>
            </div>
          </div>
          <div className='card-row'>
            <div className='card-col'>
              <div className='card-col-title'>Email</div>
              <div className='card-col-body'>{customer.email}</div>
            </div>
          </div>
          <div className='card-row'>
            <div className='card-col'>
              <div className='card-col-title'>Created on</div>
              <div className='card-col-body'>
                {formatDate(customer.createdAt)}
              </div>
            </div>
          </div>
        </div>
      </StyledCustomerInfoCard>
    );
  };

  const AdditionalCustomerInfoCard = () => {
    let attributes = [];
    for (const field in customer.additionalAttributes) {
      const transformed = field.replace(/([A-Z])/g, ' $1');
      let attribute = (
        <div className='card-item' key={field}>
          <div className='card-item-title'>
            {transformed.charAt(0).toUpperCase() + transformed.slice(1)}
          </div>
          <div className='card-item-body'>
            {customer.additionalAttributes[field]}
          </div>
        </div>
      );
      attributes = [...attributes, attribute];
    }
    return customer.additionalAttributes ? (
      <StyledAdditionalCustomerInfoCard>
        <div className='title'>
          <div className='label'>Additional information</div>
          <div className='attribute-count'>
            Showing {Object.keys(customer.additionalAttributes).length}{' '}
            attributes
          </div>
        </div>
        <div className='card-content'>{attributes}</div>
      </StyledAdditionalCustomerInfoCard>
    ) : (
      null
    );
  };

  const ShippingAddressCard = ({ addressCustomer, type }) => {
    const defaultAddress =
      addressCustomer.defaultAddress &&
      addressCustomer.defaultAddress.filter(
        address => address.type === type
      )[0];
    const fullAddress = [];
    if (defaultAddress) {
      defaultAddress.addressLine1 &&
        fullAddress.push(defaultAddress.addressLine1);
      defaultAddress.addressLine2 &&
        fullAddress.push(defaultAddress.addressLine2);
      defaultAddress.addressLine3 &&
        fullAddress.push(defaultAddress.addressLine3);
      defaultAddress.addressLine4 &&
        fullAddress.push(defaultAddress.addressLine4);
    }
    return defaultAddress ? (
      <StyledShippingCard>
        <div className='title'>
          {type === 'M' ? 'Shipping' : 'Billing'} Address
        </div>
        {defaultAddress && (
          <div className='content'>
            <div className='row'>
              <span className='label'>Name: </span>
              <span className='value'>{addressCustomer.name}</span>
            </div>
            <div className='row'>
              <span className='label'>Address: </span>
              <span className='value'>
                {fullAddress.join(', ')} {defaultAddress.city}.{' '}
                {defaultAddress.state}, {defaultAddress.zipCode}{' '}
                {defaultAddress.country}
              </span>
            </div>
          </div>
        )}
      </StyledShippingCard>
    ) : null;
  };

  const renderTags = () => {
    return (
      <div className='chips-values-container'>
        {userTags.map(tag => renderChip(tag.name))}
      </div>
    );
  };

  const renderChip = chip => {
    return <button className='btn-chip'>{chip}</button>;
  };

  const TagsCard = () => {
    return (
      <StyledTagsCard>
        <StyledExpandableCard heading='Tags' width='100%' collapse={true}>
          {tagsLoading ? (
            <div>
              <CardLoader rowCount={1} theme='light' />
            </div>
          ) : (
            <div>
              {canCreateTag ? (
                <InputChips
                  data-testid='tag-selector'
                  label=''
                  uniqueChips
                  minimumChips={3}
                  errorMessage={tagFieldError}
                  value={userTags.map(tag => tag.name)}
                  createChipKeys={[13, 44]}
                  onChange={changeTags}
                />
              ) : (
                renderTags()
              )}
            </div>
          )}
        </StyledExpandableCard>
      </StyledTagsCard>
    );
  };

  const validateTag = tag => {
    const message = [];
    if (tag.indexOf('-') >= 0) {
      message.push(TAG_FIELD_VALIDATION.ERROR_HYPHEN);
    }
    setTagFieldError(message.join(', '));
    return message.length > 0;
  };

  const deleteTrait = async (diffTags) => {
    try {
      await CSRController.deletePartyTrait({
        partyId: customerId,
        traitId: diffTags[0]._id,
      });
      const filterTags = userTags.filter(x => x._id !== diffTags[0]._id);
      setUserTags(filterTags);
      setTagsLoading(false);
    } catch (e) {
      setTagError('Failed to remove tag please try again');
      const tags = [...userTags];
      setUserTags(tags);
    }
  }

  const createTrait = async (currentTags) => {
    const addedTag = currentTags.filter(
      x => !userTags.find(tag => tag.name === x)
    );
    if (addedTag.length > 0 && validateTag(addedTag[0])) {
      setTagsLoading(false);
      return;
    }
    let newTag;
    if (addedTag[0]) {
      try {
        const { data: tagsFound } = await CSRController.listTraits({
          offset: 0,
          limit: 10000,
          filter: addedTag[0].toLowerCase(),
        });

        if (
          tagsFound.length <= 0 ||
          !tagsFound.find(trait => trait.name === addedTag[0])
        ) {
          newTag = await CSRController.createTrait({
            name: addedTag[0],
            traitId: addedTag[0],
          });
        }
        await CSRController.createPartyTrait({
          partyId: customerId,
          traitId: tagsFound.length >= 1 ? tagsFound[0]._id : newTag._id,
        });

        setUserTags([
          ...userTags,
          tagsFound.length >= 1 ? tagsFound[0] : newTag,
        ]);
        setTagsLoading(false);
      } catch (e) {
        setTagError('Failed to add tag please try again');
        setUserTags([...userTags]);
      }
    }
  }

  const changeTags = async currentTags => {
    setTagsLoading(true);
    const diffTags = userTags.filter(x => !currentTags.includes(x.name));
    if (diffTags.length) {
      deleteTrait(diffTags)
    } else {
      //Tag is being added
      createTrait(currentTags);
    }
  };

  const formatDate = date => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
    });
  };

  const formatDateTime = dateTime => {
    if (!dateTime) return '';
    return new Date(dateTime).toLocaleTimeString('en-US', {
      day: 'numeric',
      month: 'short',
    });
  };

  const clearTabError = () => {
    setTagError(null);
  };

  return (
    <>
      {customerLoading ? (
        <StyledLoader>
          <Loading size={50} />
        </StyledLoader>
      ) : 
        <>
          {tagError && (
            <Snackbar
              dismissable
              height='60px'
              kind='alert'
              label={tagError}
              onDismiss={clearTabError}
              show
              isFloating
              isHighEmphasis
              position='top-center'
              width='600px'
              backgroundColor='#ffcccb'
              withIcon
            />
          )}
          <PageHeading backRoute='/customer/customers'>
            <p className='page-title uppercase'>
              back to customers / {customer.name}
            </p>
          </PageHeading>
          <StyledCustomerDetailsPage>
            <CustomerDetailsHeader />
            <StyledCustomerDetailsBody>
              <div className='left'>
                <CustomerInfoCard />
                {customer.additionalAttributes && (
                  <AdditionalCustomerInfoCard />
                )}
              </div>
              <div className='right'>
                <ShippingAddressCard addressCustomer={customer} type='M' />
                <ShippingAddressCard addressCustomer={customer} type='P' />
                {canReadTags && <TagsCard />}
              </div>
            </StyledCustomerDetailsBody>
          </StyledCustomerDetailsPage>
        </>}
        </>
  );
};

export default CustomerDetailsPageCDP;
