import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';

const initialState = {
  createdCustomer: false,
};

const createdCustomerSuccess = (state, action) => {
  return {
    ...state,
    createdCustomer: action.payload,
  };
};

export default createReducer(initialState, {
  [ACTIONS.CREATED_CUSTOMER_SUCCESS]: createdCustomerSuccess,
  [ACTIONS.CREATED_CUSTOMER_ERROR]: createdCustomerSuccess,
});
