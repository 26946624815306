import React from 'react';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { default as DS4App } from 'src/ds4pages/app';
import { default as DS3App } from 'src/pages/_app';

function DS4Switch() {
  const { flagsReady } = useFlagsStatus();
  const ds4 = useFlag('ds4');
  if (!flagsReady) {
    return null;
  }
  return ds4 ? <DS4App /> : <DS3App />;
}

export default DS4Switch;
