import React from 'react'
import { navigate } from '@reach/router'
import { Button } from '@teamfabric/copilot-ui'
import { StyledError } from './styles'

function ErrorComponent ({ backRoute }) {
  const navigateBack = () => {
    navigate(backRoute)
  }

  return (
    <StyledError>
      <div className='container'>
        <div className='code'>error 500</div>
        <div className='message'>
          Seems like we can’t find the page you are looking for. You can try
          going back.
        </div>
        <div className='navigate'>
          <Button onClick={() => navigateBack()} text='Go Back' />
        </div>
      </div>
    </StyledError>
  )
}

export default ErrorComponent
