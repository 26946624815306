import styled from 'styled-components'

export const StyledError = styled.div`
  width: 100%;
  height: 100%;
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-top: 100px;
    .code {
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.8999999761581421px;
      text-transform: uppercase;
    }
    .message {
      font-family: Gilroy;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      margin: 20px 0;
      width: 450px;
      text-align: center;
    }
    .navigate {
      margin-top: 50px;
    }
  }
`
