import { CONTRACT_PERMISSIONS, CUSTOMER_PERMISSIONS, ORG_PERMISSIONS } from 'src/lib/data/constants';

export const GITLAB_PROJECT_ID = 31570270;

const initConfig = {
  links: [
    {
      id: 1,
      label: 'Customers',
      url: '/customer/customers',
      active: true,
      visible: true,
      permission: CUSTOMER_PERMISSIONS.CUST_READ,
    },
    {
      id: 2,
      label: 'Segments',
      url: '/customer/segments',
      active: false,
      visible: false,
    },
    {
      id: 3,
      label: 'Organizations',
      url: '/customer/organizations',
      active: false,
      visible: false,
      permission: ORG_PERMISSIONS.ORG_READ,
    },
    {
      id: 4,
      label: 'Contracts',
      url: '/customer/contracts',
      active: false,
      visible: false,
      permission: CONTRACT_PERMISSIONS.CONT_READ,
    },
  ],
};

export default initConfig;
