"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editOrganizationGroupDetails = exports.createOrganizationGroup = exports.viewOrganizationGroupData = exports.setDetailsFormMode = exports.setToast = void 0;
var mfa_communication_1 = require("@copilot/mfa-communication");
var _types_1 = require("src/@types");
var router_1 = require("@reach/router");
var setToast = function (dispatch, toastKind, message) {
    dispatch({
        type: _types_1.OrganizationGroupActionTypes.SET_GROUP_TOAST,
        payload: {
            toastKind: toastKind,
            toastMsg: message,
        },
    });
};
exports.setToast = setToast;
var setDetailsFormMode = function (dispatch, formMode) {
    dispatch({
        type: _types_1.OrganizationGroupActionTypes.SET_GROUP_DETAILS_FORM_MODE,
        payload: formMode,
    });
};
exports.setDetailsFormMode = setDetailsFormMode;
var handleOrganizationGroupViewResponse = function (dispatch, response) {
    if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        (0, exports.setToast)(dispatch, 'alert', 'Error loading organization group');
    }
    else {
        dispatch({
            type: _types_1.OrganizationGroupActionTypes.VIEW_ORGANIZATION_GROUP,
            payload: { data: response },
        });
    }
};
var viewOrganizationGroupData = function (dispatch, props) {
    dispatch({
        type: _types_1.OrganizationGroupActionTypes.SET_GROUP_LOADING_STATUS,
        payload: true,
    });
    mfa_communication_1.CSRController.viewOrganizationGroup(props)
        .then(function (response) {
        handleOrganizationGroupViewResponse(dispatch, response);
    })
        .catch(function (e) {
        console.error(e);
        handleOrganizationGroupViewResponse(dispatch, {
            error: { message: '', statusCode: 400 },
        });
    });
};
exports.viewOrganizationGroupData = viewOrganizationGroupData;
var createOrganizationGroup = function (dispatch, payload) {
    dispatch({
        type: _types_1.OrganizationGroupActionTypes.SET_GROUP_LOADING_STATUS,
        payload: true,
    });
    var organizationId = payload.organizationId, groupData = payload.groupData;
    mfa_communication_1.CSRController.createOrganizationGroup(groupData, organizationId)
        .then(function (response) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
            throw new Error('Organization group save was unsuccessful');
        }
        else {
            (0, exports.setDetailsFormMode)(dispatch, "VIEW" /* VIEW */);
            dispatch({
                type: _types_1.OrganizationGroupActionTypes.VIEW_ORGANIZATION_GROUP,
                payload: { data: response },
            });
            (0, exports.setToast)(dispatch, 'success', 'Organization group added successfully');
            (0, router_1.navigate)("/customer/organizations/".concat(organizationId, "/group/").concat(response._id));
        }
    })
        .catch(function (err) {
        console.error(err);
        (0, exports.setToast)(dispatch, 'alert', 'Organization group save was unsuccessful');
    });
};
exports.createOrganizationGroup = createOrganizationGroup;
var editOrganizationGroupDetails = function (dispatch, props) {
    dispatch({
        type: _types_1.OrganizationGroupActionTypes.SET_GROUP_LOADING_STATUS,
        payload: true,
    });
    mfa_communication_1.CSRController.updateOrganizationGroup(props.groupData, props.organizationId)
        .then(function (response) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
            throw new Error('Organization group edit was unsuccessful');
        }
        else {
            (0, exports.setToast)(dispatch, 'success', 'Organization group saved successfully');
            (0, exports.setDetailsFormMode)(dispatch, "VIEW" /* VIEW */);
            dispatch({
                type: _types_1.OrganizationGroupActionTypes.VIEW_ORGANIZATION_GROUP,
                payload: { data: response },
            });
        }
    })
        .catch(function (e) {
        console.error(e);
        (0, exports.setToast)(dispatch, 'alert', 'Organization group edit was unsuccessful');
    });
};
exports.editOrganizationGroupDetails = editOrganizationGroupDetails;
