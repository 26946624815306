"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var copilot_ui_1 = require("@teamfabric/copilot-ui");
var reducer_1 = require("../Context/reducer");
var style_1 = require("./style");
var constants_1 = require("../constants");
var actions_1 = require("modules/Contracts/Context/actions");
var PriceListTable = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var hooks = _a.hooks, isLoading = _a.isLoading;
    var _l = react_1.default.useContext(reducer_1.ContractsContext), state = _l.state, dispatch = _l.dispatch;
    var _m = __read((0, react_1.useState)(null), 2), caption = _m[0], setCaption = _m[1];
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        var getCount = (_b = (_a = state === null || state === void 0 ? void 0 : state.priceListData) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.count;
        var getOffset = (_d = (_c = state === null || state === void 0 ? void 0 : state.priceListData) === null || _c === void 0 ? void 0 : _c.query) === null || _d === void 0 ? void 0 : _d.offset;
        var createdCaption = customCaption(getCount, getOffset, constants_1.LIMIT);
        setCaption(createdCaption);
    }, [(_c = (_b = state === null || state === void 0 ? void 0 : state.priceListData) === null || _b === void 0 ? void 0 : _b.query) === null || _c === void 0 ? void 0 : _c.count, (_e = (_d = state === null || state === void 0 ? void 0 : state.priceListData) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.offset]);
    var handlePagination = function (page) {
        var _a;
        var priceListId = (_a = hooks.watch('priceListId')) === null || _a === void 0 ? void 0 : _a.id;
        var offset;
        if (page === 1) {
            offset = 0;
        }
        else {
            offset = (page - 1) * constants_1.LIMIT;
        }
        (0, actions_1.fetchPriceListSku)(dispatch, priceListId, offset);
    };
    var customCaption = function (count, offset, limit) {
        var startIndex = offset + 1;
        var endIndex = offset + limit;
        var range = "".concat(startIndex, " - ").concat(endIndex);
        if (count === 0) {
            return 'Showing 0 Products';
        }
        return "Showing ".concat(range, " of ").concat(count, " Products");
    };
    return (react_1.default.createElement(style_1.StyledTableContainer, null,
        react_1.default.createElement(style_1.StyledSeparator, null, !((_f = state === null || state === void 0 ? void 0 : state.priceListData) === null || _f === void 0 ? void 0 : _f.loading) && (react_1.default.createElement(style_1.StyledCaption, null, caption))),
        react_1.default.createElement(style_1.TableWrapper, null,
            react_1.default.createElement(copilot_ui_1.Table, { columns: constants_1.SKU_COLUMNS, data: (_g = state === null || state === void 0 ? void 0 : state.priceListData) === null || _g === void 0 ? void 0 : _g.results, showPagination: true, hideCaption: true, perPage: constants_1.LIMIT, loading: ((_h = state === null || state === void 0 ? void 0 : state.priceListData) === null || _h === void 0 ? void 0 : _h.loading) || isLoading, showIndex: false, totalRecords: (_k = (_j = state === null || state === void 0 ? void 0 : state.priceListData) === null || _j === void 0 ? void 0 : _j.query) === null || _k === void 0 ? void 0 : _k.count, activePageNumber: state === null || state === void 0 ? void 0 : state.priceListDataActivePage, handlePagination: handlePagination, render: function () {
                    var _a, _b, _c;
                    return ((_b = (_a = state === null || state === void 0 ? void 0 : state.priceListData) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.length) === 0 &&
                        !((_c = state === null || state === void 0 ? void 0 : state.priceListData) === null || _c === void 0 ? void 0 : _c.loading) ? (react_1.default.createElement("tbody", null,
                        react_1.default.createElement("tr", null,
                            react_1.default.createElement("td", { colSpan: (constants_1.SKU_COLUMNS === null || constants_1.SKU_COLUMNS === void 0 ? void 0 : constants_1.SKU_COLUMNS.length) + 1 },
                                react_1.default.createElement(style_1.StyledEmptyTable, null,
                                    react_1.default.createElement("p", null, "No Products Added")))))) : null;
                } }))));
};
exports.default = PriceListTable;
