import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { CopilotUIProvider } from 'ds4-beta';
import Root from './root.component.ds4';
import { ConfigContextProvider } from './hooks/header';
import { RootContextProvider } from './hooks/root';

export const RootApp = () => {
  return (
    <ConfigContextProvider>
      <RootContextProvider>
        <CopilotUIProvider>
          <Root />
        </CopilotUIProvider>
      </RootContextProvider>
    </ConfigContextProvider>
  );
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootApp,
  // errorBoundary(err, info, props) {
  //   // Customize the root error boundary for your microfrontend here.
  //   return null;
  // },
});

export const { bootstrap, mount, unmount } = lifecycles;
