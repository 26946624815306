"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionBuilderContext = exports.useConditionBuilderContext = void 0;
var react_1 = require("react");
var ConditionBuilderContext = (0, react_1.createContext)({});
exports.ConditionBuilderContext = ConditionBuilderContext;
function useConditionBuilderContext() {
    var context = (0, react_1.useContext)(ConditionBuilderContext);
    return context;
}
exports.useConditionBuilderContext = useConditionBuilderContext;
