import React, { useState } from 'react'
import { AutoComplete, Button, SearchLoader } from '@teamfabric/copilot-ui'
import { Key } from 'ts-keycode-enum';
import { StyledAutoComplete } from './style'
import cx from 'classnames'

const SearchAutoComplete = ({ onSearchChange, initialSearchTerm, placeholder,...rest }) => {
  const [searchTerm, setSearchTerm] = React.useState(initialSearchTerm)
  const [show, setShow] = useState(false)
 
  let value = rest.value || searchTerm

  const searchSuggestion = event => {
    if (event.charCode === Key.Enter) {
      onSearchChange(searchTerm)
    }
  }
  const fetchSuggestions = e => {
    setSearchTerm(e.target.value)
  }

  return (
    <StyledAutoComplete className='d-flex justify-start align-center'>
      <div data-testid="search-loader-autocomplete" className={cx({hidden: !rest.isTableLoading, searchLoader: true })}>
        <SearchLoader theme="dark" />
      </div>
      <div data-testid="search-container-autocomplete" className={cx({ hidden: rest.isTableLoading, searhContainer: true})} >
        <AutoComplete
          autoCompleteProps={{
            onClearSearch: () => {
              setSearchTerm('')
              onSearchChange('')
              setShow(false)
            },
            onEscPress: () => setShow(false),
            onBodyClick: () => setShow(false),
            onSearchAll: function noRefCheck () {},
            onSelect: item => {},
            data: rest.data,
            isLoading: rest.isLoading,
            show: show,
          }}
          inputProps={{
            isFloatedLabel: false,
            className: 'search-autocomplete',
            icon: 'Search',
            inputProps: {
              placeholder,
              value: `${value}`
            },
            onKeyPress: e => rest.onKeyPress? rest.onKeyPress(e): searchSuggestion(e),
            onChange: e => rest.onChange? rest.onChange(e): fetchSuggestions(e)
          }}
          style={{ zIndex: '99', fontFamily: 'Gilroy-Regular' }}
        />
        <Button
          onClick={() => {
            if(value.trim().length>0)
              onSearchChange(value)
          }}
          text='Search'
          data-testid="search-btn-autocomplete"
          className='ml-20 search-btn'
        />
      </div>
    </StyledAutoComplete>
  )
}

export default SearchAutoComplete
