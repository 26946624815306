import React, { useEffect, useState, useCallback } from 'react';
import { navigate } from '@reach/router';
import {
  ButtonWithIcon,
  Table,
  ButtonLoader,
  SearchLoader,
  TableLoader
} from '@teamfabric/copilot-ui';
import SearchAutoComplete from 'components/searchAutoComplete';
import ErrorComponent from 'components/error';
import FilterBar from 'components/filter';
import {
  CUSTOMERS_PAGE_TABLE_HEADERS_CDP,
  CUSTOMER_PERMISSIONS,
} from 'lib/data/constants';
import StyledColumnComponents from './styledColumnsCDP';
import {
  StyledCustomerPage,
  StyledWrapper,
  StyledTitle,
  StyledMessage,
  StyledLink,
  StyledCaption,
} from './styles';
import { transformCustomerFilters } from './utils/utilCDP';
import { CSRController } from '@copilot/mfa-communication';
import { hasPermission } from 'src/hooks/hasPermission';

const DEFAULT_FILTERS = {
  tags: [],
  statuses: [],
  dateFilter: 'all',
  dateGte: null,
  dateLte: null,
  totalAmountSpentMin: '',
  totalAmountSpentMax: '',
  numberOfOrdersMin: '',
  numberOfOrdersMax: '',
};

const CustomerPageCDP = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    sortBy: 'updatedAt',
    sortOrder: 'desc',
  });
  const [tagsArray, setTagsArray] = useState([]);
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [customerFilters, setCustomerFilters] = useState(DEFAULT_FILTERS);
  const [customersData, setCustomersData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [columnHeaders] = useState(
    CUSTOMERS_PAGE_TABLE_HEADERS_CDP(StyledColumnComponents)
  );
  const PAGINATION = 10;
  const canAddCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_CREATE]);

  /**
   * @description Underlying function which is executed anytime useEffect hook to fetch the Customers data is executed
   */
  const getCustomersData = useCallback(
    async page => {
      try {
        if (page === 0) setRefreshTable(true);

        //prepare API call payload
        const offset = page > 0 ? (page - 1) * PAGINATION : 0;

        const filters = transformCustomerFilters(customerFilters);
        if (customerSearchTerm?.length) {
          filters['name'] = customerSearchTerm;
        }
        //execute network call
        const { data: customers, query } = await CSRController.listCustomers({
          offset,
          limit: PAGINATION,
          ...filters,
          sortBy: pagination.sortBy,
          sortOrder: pagination.sortOrder,
        });

        setTotalDataCount(query.count);
        setCustomersData(
          customers.map(customer => {
            return {
              _isCheck: false,
              _id: customer._id,
              name: customer.name,
              email: customer.email,
              defaultShippingAddress: customer.defaultAddress
                ? customer.defaultAddress[0]
                : false,
              status: customer.isActive,
              tags: customer.traits,
            };
          })
        );

        setLoading(false);
        setRefreshTable(false);
      } catch (err) {
        setError(err.message);
        setCustomersData([]);
        setLoading(false);
      }
    },
    [customerFilters, customerSearchTerm, pagination]
  );

  /**
   * Underlying function gets lastest tags
   */
  let syncTagsData = () => {
    CSRController.listTraits({ offset: 0, limit: 1000 }).then(response => {
      const { data: tags } = response;
      setTagsArray(tags.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
  };

  /**
   * UseEffect hook to fetch Customers data and tags
   */
  useEffect(async () => {
    const isValid =
      checkIfAmountFilterIsValid(
        customerFilters.totalAmountSpentMin,
        customerFilters.totalAmountSpentMax
      ) &&
      checkIfAmountFilterIsValid(
        customerFilters.numberOfOrdersMin,
        customerFilters.numberOfOrdersMax
      );
    if (isValid) {
      syncTagsData();
      getCustomersData(pagination.page);
    }
  }, [getCustomersData, pagination, customerFilters]);

  const checkIfAmountFilterIsValid = (minValue, maxValue) => {
    if (maxValue !== '' && minValue !== '') {
      return minValue < maxValue;
    }
    return true; // Since there wouldn't be any values in them.
  };

  const submitFilters = result => {
    setPagination({
      ...pagination,
      page: 0,
    });
    setCustomerFilters(result);
  };

  const resetFilters = () => {
    setPagination({
      ...pagination,
      page: 0,
    });
    setCustomerFilters(DEFAULT_FILTERS);
  };

  const CustomPagination = () => {
    const pageNo = pagination.page > 0 ? pagination.page : 1;
    const firstRecord =
      pagination.page > 1 ? (pagination.page - 1) * PAGINATION : 1;
    const lastRecord = pageNo * PAGINATION > totalDataCount
        ? totalDataCount
        : pageNo * PAGINATION;
    return `Showing ${firstRecord} -
      ${lastRecord}
    of ${totalDataCount}`;
  };

  return (
    <StyledCustomerPage>
      {error ? (
        <ErrorComponent backRoute='/customer/customers' />
      ) : (
        <>
          <div className='left-section'>
            {loading ? (
              <>
                <div className='top-buttons' style={{ display: 'flex' }}>
                  <ButtonLoader theme='dark' width='150px' />
                  <ButtonLoader theme='dark' width='150px' />
                  <ButtonLoader theme='dark' width='150px' />
                </div>
                <br />
                <div className='search-component'>
                  <SearchLoader theme='dark' />
                </div>
                <br />
                <div className='table-component'>
                  <TableLoader columns={[]} theme='dark' />
                </div>
              </>
            ) : (
              <>
                {canAddCustomer && (
                  <div className='top-buttons'>
                    <ButtonWithIcon
                      className='button-with-icons'
                      emphasis='low'
                      icon='Add'
                      iconPosition='left'
                      isPrimary={false}
                      onClick={() => navigate('/customer/customers/new')}
                      text='Add customer'
                      theme='light'
                      loading={true}
                    />
                  </div>
                )}
                <div className='search-component'>
                  <SearchAutoComplete
                    onSearchChange={value => {
                      setPagination({
                        ...pagination,
                        page: 0,
                      });
                      setCustomerSearchTerm(value);
                    }}
                    placeholder='Search Customers'
                    initialSearchTerm={customerSearchTerm}
                  />
                </div>
                <div className='filters-bar'></div>
                <div className='table-component'>
                  <StyledCaption>
                    <FilterBar
                      currentFilterValue={customerFilters}
                      submitFilters={submitFilters}
                      resetFilters={resetFilters}
                      tagsData={tagsArray}
                      isCDP={true}
                    />
                    <span className='pagination'>
                      <CustomPagination />
                    </span>
                  </StyledCaption>
                  <Table
                    cellClassName='table-cell'
                    rowClassName='table-row'
                    columns={columnHeaders}
                    data={customersData}
                    showPagination
                    enableSort
                    hideCaption
                    loading={refreshTable}
                    perPage={PAGINATION}
                    totalRecords={totalDataCount}
                    handleAscendingSort={fieldName => {
                      setPagination({
                        ...pagination,
                        sortBy: fieldName,
                        sortOrder: 'asc',
                      });
                    }}
                    handleDescendingSort={fieldName => {
                      setPagination({
                        ...pagination,
                        sortBy: fieldName,
                        sortOrder: 'desc',
                      });
                    }}
                    handleResetSort={() => {
                      setPagination({
                        ...pagination,
                        sortBy: 'updatedAt',
                        sortOrder: 'desc',
                      });
                    }}
                    handlePagination={newPage =>
                      setPagination({ ...pagination, page: newPage })
                    }
                    onRowClick={(e, data) =>
                      navigate(`/customer/customers/${data._id}`)
                    }
                    render={({ data }) => {
                      return !customersData.length ? (
                        <tbody>
                          <tr>
                            <td colSpan={customersData.length + 6}>
                              <StyledWrapper>
                                <StyledTitle>NO RESULTS FOUND</StyledTitle>
                                <StyledMessage>
                                  Try searching for something more general,
                                </StyledMessage>
                                <StyledMessage>
                                  change filters or check for
                                </StyledMessage>
                                <StyledMessage>spelling mistakes</StyledMessage>
                                <StyledLink>
                                  <p
                                    onClick={() => {
                                      resetFilters();
                                    }}
                                  >
                                    Refresh
                                  </p>
                                </StyledLink>
                              </StyledWrapper>
                            </td>
                          </tr>
                        </tbody>
                      ) : null;
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </StyledCustomerPage>
  );
};

export default CustomerPageCDP;
