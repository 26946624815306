import React from 'react';
import { Box, GlobalStyle } from 'ds4-beta';

const Layout = ({ children }) => {
  return (
    <Box padding={[6, 6, 6, 6]}>
      <GlobalStyle />
      <Box>{children}</Box>
    </Box>
  );
};

export default Layout;
