import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledName = styled.div`
  width: 200px;

  ${theme.typography.h6};
  color: ${theme.palette.brand.primary.charcoal};

  .name {
  }

  .email {
    ${theme.typography.caption};
    color: ${theme.palette.brand.primary.gray};
    margin-top: 8px;
  }
`;

export const StyledLocation = styled.div`
  width: 210px;

  ${theme.typography.h6};
  color: ${theme.palette.brand.primary.charcoal};

  .location {
  }
`;

export const StyledLastActivity = styled.div`
  width: 150px;

  ${theme.typography.h6};
  color: ${theme.palette.ui.neutral.grey7};

  .isActive {
    color: ${theme.palette.brand.primary.charcoal};
  }
`;

export const StyledTags = styled.div`
  ${theme.typography.h6};
  color: ${theme.palette.brand.primary.charcoal};

  .tags {
    color: ${theme.palette.brand.primary.gray};
    margin-right: 8px;
  }

  .tags-view-more {
    cursor: pointer;
  }

  .tooltip {
    width: 323px;
    max-height: 193px;

    .tooltip-container {
      width: 310px;
      max-height: 173px;
      margin: 0 0 5px 15px;

      .tooltip-heading {
        ${theme.typography.kicker};
        color: ${theme.palette.brand.primary.white};
      }

      .tooltip-body {
        max-height: 148px;
        overflow: hidden;
        overflow-y: scroll;

        .tooltip-content {
          margin: 0 0 10px 0;
          font-family: Gilroy;
          font-size: 13px;
          line-height: 10px;
          letter-spacing: 0.25999999046325684px;
          text-align: left;
          color: ${theme.palette.brand.primary.white};
        }
      }

      .tooltip-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0);
      }

      .tooltip-body::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(0, 0, 0, 0);
      }

      .tooltip-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${theme.palette.brand.primary.gray};
      }
    }
  }
`;

export const StyledMenu = styled.div`
  width: 50px;
  .menu {
    cursor: pointer;
  }
`;
