// Generated from /Users/vishnu.d/WebstormProjects/copilot-mfa-customer/src/modules/Segmentation/conditionBuilder/grammar/ConditionalGrammar.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete listener for a parse tree produced by ConditionalGrammarParser.
export default class ConditionalGrammarListener extends antlr4.tree.ParseTreeListener {

	// Enter a parse tree produced by ConditionalGrammarParser#start.
	enterStart(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#start.
	exitStart(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#expression.
	enterExpression(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#expression.
	exitExpression(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#InnerExpression.
	enterInnerExpression(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#InnerExpression.
	exitInnerExpression(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#NumberComparison.
	enterNumberComparison(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#NumberComparison.
	exitNumberComparison(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#StringOperation.
	enterStringOperation(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#StringOperation.
	exitStringOperation(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#NullBooleanOperation.
	enterNullBooleanOperation(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#NullBooleanOperation.
	exitNullBooleanOperation(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#logicalOp.
	enterLogicalOp(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#logicalOp.
	exitLogicalOp(ctx) {
	}


	// Enter a parse tree produced by ConditionalGrammarParser#comparisonOp.
	enterComparisonOp(ctx) {
	}

	// Exit a parse tree produced by ConditionalGrammarParser#comparisonOp.
	exitComparisonOp(ctx) {
	}



}