"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddAddressForm = void 0;
var styles_1 = require("modules/CustomerAddresses/styles");
var react_1 = __importStar(require("react"));
var copilot_ui_1 = require("@teamfabric/copilot-ui");
var constants_1 = require("lib/data/constants");
var AddAddressForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var onUpdateForm = _a.onUpdateForm, customerAddress = _a.customerAddress, type = _a.type, validatedFields = _a.validatedFields;
    var crypto = window.crypto;
    var array = new Uint32Array(1);
    var ids = crypto.getRandomValues(array);
    var _j = __read((0, react_1.useState)(customerAddress
        ? customerAddress
        : {
            _id: ids[0].toString(),
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            addressLine4: '',
            zipCode: null,
            country: '',
            state: '',
            city: '',
            type: type === 'shipping' ? 'M' : 'P',
            isNew: true,
        }), 2), customerAddressData = _j[0], setCustomerAddressData = _j[1];
    (0, react_1.useEffect)(function () {
        onUpdateForm(customerAddressData);
    }, [customerAddressData]);
    return (react_1.default.createElement(styles_1.StyledAddForm, null,
        react_1.default.createElement("div", { className: 'item' },
            react_1.default.createElement(copilot_ui_1.Input, { id: 'addressLine1', label: 'Address line 1', className: 'primary', error: validatedFields.addressLine1.error, errorMessage: validatedFields.addressLine1.message, inputProps: {
                    name: 'addressLine1',
                    value: customerAddressData.addressLine1,
                    autoComplete: 'off',
                    onChange: function (e) {
                        setCustomerAddressData(__assign(__assign({}, customerAddressData), { addressLine1: e.target.value }));
                    },
                }, isFloatedLabel: true })),
        react_1.default.createElement("div", { className: 'item' },
            react_1.default.createElement(copilot_ui_1.Input, { id: 'addressLine2', label: 'Address line 2', className: 'primary', error: validatedFields.addressLine2.error, errorMessage: validatedFields.addressLine2.message, inputProps: {
                    name: 'addressLine2',
                    value: customerAddressData.addressLine2,
                    autoComplete: 'off',
                    onChange: function (e) {
                        setCustomerAddressData(__assign(__assign({}, customerAddressData), { addressLine2: e.target.value }));
                    },
                }, isFloatedLabel: true })),
        react_1.default.createElement("div", { className: 'item' },
            react_1.default.createElement(copilot_ui_1.Input, { id: 'addressLine3', label: 'Address line 3', className: 'primary', error: validatedFields.addressLine3.error, errorMessage: validatedFields.addressLine3.message, inputProps: {
                    name: 'addressLine3',
                    value: customerAddressData.addressLine3,
                    autoComplete: 'off',
                    onChange: function (e) {
                        setCustomerAddressData(__assign(__assign({}, customerAddressData), { addressLine3: e.target.value }));
                    },
                }, isFloatedLabel: true })),
        react_1.default.createElement("div", { className: 'item' },
            react_1.default.createElement(copilot_ui_1.Input, { id: 'addressLine4', label: 'Address line 4', className: 'primary', error: validatedFields.addressLine4.error, errorMessage: validatedFields.addressLine4.message, inputProps: {
                    name: 'addressLine4',
                    value: customerAddressData.addressLine4,
                    autoComplete: 'off',
                    onChange: function (e) {
                        setCustomerAddressData(__assign(__assign({}, customerAddressData), { addressLine4: e.target.value }));
                    },
                }, isFloatedLabel: true })),
        react_1.default.createElement("div", { className: 'item' },
            react_1.default.createElement(copilot_ui_1.Dropdown, { onSelect: function (e) {
                    setCustomerAddressData(__assign(__assign({}, customerAddressData), { country: e.id }));
                }, options: constants_1.COUNTRIES, value: {
                    id: customerAddressData.country,
                    name: ((_b = constants_1.COUNTRIES.find(function (country) {
                        return country.id === customerAddressData.country ||
                            country.name === customerAddressData.country;
                    })) === null || _b === void 0 ? void 0 : _b.name) || customerAddressData.country,
                }, titleLabel: 'Country', width: '100%' })),
        react_1.default.createElement("div", { className: 'item fixer' },
            react_1.default.createElement(copilot_ui_1.Input, { className: 'primary', error: validatedFields.city.error, errorMessage: validatedFields.city.message, inputProps: {
                    name: 'city',
                    disabled: false,
                    value: customerAddressData.city,
                }, label: 'City', onChange: function (e) {
                    setCustomerAddressData(__assign(__assign({}, customerAddressData), { city: e.target.value }));
                } })),
        react_1.default.createElement("div", { className: 'item fixer' },
            react_1.default.createElement(copilot_ui_1.Input, { className: 'primary', error: (_c = validatedFields.county) === null || _c === void 0 ? void 0 : _c.error, errorMessage: (_d = validatedFields.county) === null || _d === void 0 ? void 0 : _d.message, inputProps: {
                    name: 'county',
                    disabled: false,
                    value: customerAddressData.county,
                }, label: 'County', onChange: function (e) {
                    setCustomerAddressData(__assign(__assign({}, customerAddressData), { county: e.target.value }));
                } })),
        react_1.default.createElement("div", { className: 'item fixer' },
            react_1.default.createElement(copilot_ui_1.Input, { className: 'primary', inputProps: {
                    name: 'state',
                    disabled: false,
                    value: customerAddressData.state,
                }, label: 'State', onChange: function (e) {
                    setCustomerAddressData(__assign(__assign({}, customerAddressData), { state: e.target.value }));
                } })),
        react_1.default.createElement("div", { className: 'item fixer' },
            react_1.default.createElement(copilot_ui_1.Input, { className: 'primary', error: (_e = validatedFields.zipCode) === null || _e === void 0 ? void 0 : _e.error, errorMessage: (_f = validatedFields.zipCode) === null || _f === void 0 ? void 0 : _f.message, inputProps: {
                    name: 'zipCode',
                    disabled: false,
                    value: customerAddressData.zipCode,
                }, label: 'Zip Code', onChange: function (e) {
                    setCustomerAddressData(__assign(__assign({}, customerAddressData), { zipCode: e.target.value }));
                } })),
        react_1.default.createElement("div", { className: 'item fixer' },
            react_1.default.createElement(copilot_ui_1.Input, { className: 'primary', error: (_g = validatedFields.postalCode) === null || _g === void 0 ? void 0 : _g.error, errorMessage: (_h = validatedFields.postalCode) === null || _h === void 0 ? void 0 : _h.message, inputProps: {
                    name: 'postalCode',
                    disabled: false,
                    value: customerAddressData.postalCode,
                }, label: 'Postal Code', onChange: function (e) {
                    setCustomerAddressData(__assign(__assign({}, customerAddressData), { postalCode: e.target.value }));
                } }))));
};
exports.AddAddressForm = AddAddressForm;
