"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var copilot_ui_1 = require("@teamfabric/copilot-ui");
var style_1 = require("./style");
var reducer_1 = require("../Context/reducer");
var constants_1 = require("../constants");
var actions_1 = require("src/modules/Contracts/Context/actions");
var useTooltipLabel_1 = require("src/hooks/useTooltipLabel");
var LoadingScreen = function () {
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(style_1.StyledPriceList, null,
            react_1.default.createElement(copilot_ui_1.ButtonLoader, { width: '280px', theme: 'light' })),
        react_1.default.createElement(style_1.StyledAdditionalAttrs, null,
            react_1.default.createElement(copilot_ui_1.ButtonLoader, { width: '280px', theme: 'light' }),
            react_1.default.createElement(copilot_ui_1.ButtonLoader, { width: '280px', theme: 'light' }))));
};
var priceListChangeModal = function (modalHandler, fetchPriceList) {
    var content = "\n  There is a price list currently applied to the buyer\u2019s storefront. Are you sure you wish to change the price list?\n  ";
    return (react_1.default.createElement(copilot_ui_1.Modal, { headerText: 'Change price list', render: function () { return react_1.default.createElement("p", null, content); }, headerButtons: [], footerButtons: [
            {
                onClick: modalHandler,
                text: 'Cancel',
                isPrimary: false,
            },
            {
                onClick: function () { return fetchPriceList(true); },
                text: 'Yes, change price list',
                isPrimary: true,
            },
        ], padding: '32px', showCloseButton: true, size: 'small' }));
};
var ViewCatalogItems = function (_a) {
    var _b, _c;
    var hooks = _a.hooks, formProps = _a.formProps, FormGroupProps = _a.FormGroupProps, isLoading = _a.isLoading;
    var _d = __read((0, react_1.useState)(false), 2), isConfirmationModalShown = _d[0], setIsConfirmationModalShown = _d[1];
    var _e = __read((0, react_1.useState)(false), 2), showPriceListChangeModal = _e[0], setShowPriceListChangeModal = _e[1];
    var contractLabel = (0, useTooltipLabel_1.useTooltipLabel)({
        name: 'On Contract',
        toolTipText: 'For non-contracted catalog, the base price will be applied.',
        iconSize: 19,
        dataTestId: 'field-info-icon',
    });
    var promotionLabel = (0, useTooltipLabel_1.useTooltipLabel)({
        name: 'Promotion Stacking',
        toolTipText: 'Indicates whether promotions can be applied on contracted prices.',
        iconSize: 19,
        dataTestId: 'field-info-icon',
    });
    var _f = __read((0, react_1.useState)(false), 2), initPriceListChange = _f[0], setInitPriceListChange = _f[1];
    var _g = react_1.default.useContext(reducer_1.ContractsContext), state = _g.state, dispatch = _g.dispatch;
    (0, react_1.useEffect)(function () {
        var _a;
        if (initPriceListChange) {
            setIsConfirmationModalShown(true);
            setShowPriceListChangeModal(false);
            (0, actions_1.fetchPriceListSku)(dispatch, (_a = hooks.watch('priceListId')) === null || _a === void 0 ? void 0 : _a.id, 0);
        }
    }, [initPriceListChange]);
    var handleModalCancel = function () {
        hooks.resetField('priceListId');
        setShowPriceListChangeModal(false);
    };
    if (isLoading)
        return react_1.default.createElement(LoadingScreen, null);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        showPriceListChangeModal &&
            priceListChangeModal(handleModalCancel, setInitPriceListChange),
        react_1.default.createElement(style_1.StyledPriceList, null,
            react_1.default.createElement(copilot_ui_1.FormDropdown, { titleLabel: 'Price List', hooks: hooks, formProps: formProps, field: 'priceListId', options: state === null || state === void 0 ? void 0 : state.priceList, onSelect: function (option) {
                    var _a, _b;
                    if (!isConfirmationModalShown &&
                        ((_b = (_a = formProps === null || formProps === void 0 ? void 0 : formProps.formValues) === null || _a === void 0 ? void 0 : _a.priceListId) === null || _b === void 0 ? void 0 : _b.id)) {
                        setShowPriceListChangeModal(true);
                    }
                    else {
                        (0, actions_1.fetchPriceListSku)(dispatch, option === null || option === void 0 ? void 0 : option.id, 0);
                    }
                }, value: formProps.formValues.priceListId
                    ? formProps.formValues.priceListId
                    : { id: 0, name: 'Select' }, width: '280px' })),
        react_1.default.createElement(style_1.StyledAdditionalAttrs, null,
            react_1.default.createElement(copilot_ui_1.FormDropdown, { titleLabel: contractLabel, hooks: hooks, formProps: formProps, field: 'onContract', options: constants_1.YES_NO_OPTIONS, value: ((_b = formProps === null || formProps === void 0 ? void 0 : formProps.formValues) === null || _b === void 0 ? void 0 : _b.onContract) || constants_1.YES_NO_OPTIONS[1], width: '280px' }),
            react_1.default.createElement(copilot_ui_1.FormDropdown, { titleLabel: promotionLabel, hooks: hooks, formProps: formProps, field: 'promotionStacking', options: constants_1.YES_NO_OPTIONS, value: ((_c = formProps === null || formProps === void 0 ? void 0 : formProps.formValues) === null || _c === void 0 ? void 0 : _c.promotionStacking) || constants_1.YES_NO_OPTIONS[1], groupName: FormGroupProps === null || FormGroupProps === void 0 ? void 0 : FormGroupProps.groupName, width: '280px' }))));
};
exports.default = ViewCatalogItems;
