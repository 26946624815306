"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var embedString = function (val) { return "\"".concat(val, "\""); };
var DataTypes = {
    string: {
        validate: function (value) {
            return value !== '';
        },
        convertToStr: function (value) {
            if (!this.validate(value)) {
                return;
            }
            return embedString(value);
        },
    },
    number: {
        validate: function (value) {
            try {
                var val = parseFloat(value);
                return !isNaN(val);
            }
            catch (_) {
                return false;
            }
        },
        convertToStr: function (value) {
            if (!this.validate(value)) {
                return;
            }
            return value;
        },
    },
    boolean: {
        validate: function (value) {
            return value.toUpperCase() === 'TRUE' || value.toUpperCase() === 'FALSE';
        },
        convertToStr: function (value) {
            if (!this.validate(value)) {
                return;
            }
            return value.toUpperCase();
        },
    },
    null: {
        validate: function (value) {
            return value.toUpperCase() === 'NULL';
        },
        convertToStr: function (value) {
            if (!this.validate(value)) {
                return;
            }
            return 'NULL';
        },
    },
};
var Operators = {
    EQ: {
        symbol: '==',
        types: ['number', 'string'],
        displayName: 'EQUALS',
    },
    NEQ: {
        symbol: '!=',
        types: ['number', 'string'],
        displayName: 'NOT EQUALS',
    },
    EXISTS: {
        symbol: 'EXISTS',
        displayName: 'EXISTS',
        types: ['null'],
    },
    NOT_EXISTS: {
        symbol: 'NOT_EXISTS',
        displayName: 'NOT EXISTS',
        types: ['null'],
    },
    GT: {
        symbol: '>',
        types: ['number'],
        displayName: 'GREATER THAN',
    },
    GTE: {
        symbol: '>=',
        types: ['number'],
        displayName: 'GREATER THAN OR EQUALS',
    },
    LT: {
        symbol: '<',
        types: ['number'],
        displayName: 'LESSER THAN',
    },
    LTE: {
        symbol: '<=',
        types: ['number'],
        displayName: 'LESSER THAN OR EQUALS',
    },
    MATCHES: {
        symbol: 'MATCHES',
        types: ['string'],
        displayName: 'MATCHES',
    },
    IS_TRUE: {
        symbol: 'IS_TRUE',
        displayName: 'IS TRUE',
        types: ['boolean'],
    },
    IS_FALSE: {
        symbol: 'IS_FALSE',
        displayName: 'IS FALSE',
        types: ['boolean'],
    },
};
var DataTypeOperators = Object.keys(DataTypes).reduce(function (clone, dataType) {
    var _a;
    return (__assign((_a = {}, _a[dataType] = Object.keys(Operators)
        .filter(function (opCode) { return Operators[opCode].types.includes(DataTypes[dataType]); })
        .map(function (op) { return Operators[op]; }), _a), clone));
}, {});
exports.default = {
    DataTypes: DataTypes,
    Operators: Operators,
    DataTypeOperators: DataTypeOperators,
};
