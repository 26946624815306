import React, { useState, useEffect } from 'react';
import ButtonWithDropdown from 'components/buttonWithDropdown';

const NumberOfOrders = ({
  numberOfOrdersMin,
  numberOfOrdersMax,
  updateOtherFilters,
}) => {
  const [error, setError] = useState();

  useEffect(() => {
    setError(
      numberOfOrdersMin &&
        numberOfOrdersMax &&
        numberOfOrdersMin >= numberOfOrdersMax
        ? 'Minimum amount should be lesser than Maximum amount'
        : ''
    );
  }, [numberOfOrdersMin, numberOfOrdersMax]);
  const minOrder = numberOfOrdersMin > 0 ? numberOfOrdersMin : '';
  const orderSeparator = numberOfOrdersMin > 0 && numberOfOrdersMax > 0 ? ' - ' : ''
  const maxOrder = numberOfOrdersMax > 0 ? numberOfOrdersMax : ''
  return (
    <ButtonWithDropdown
      data-testid='no-of-orders'
      label={`
        Number of Orders: 
        ${
          numberOfOrdersMin > 0 || numberOfOrdersMax > 0
            ? `
              ${minOrder}
              ${orderSeparator}
              ${maxOrder}
            `
            : 'All'
        }
      `}
      data={[
        {
          type: 'input',
          data: [
            {
              label: 'Minimum',
              isFloatedLabel: false,
              errorMessage: error,
              error: Boolean(error) || false,
              inputProps: {
                type: 'number',
                name: 'numberOfOrdersMin',
                min: 0,
                value: numberOfOrdersMin,
              },
            },
            {
              label: 'Maximum',
              isFloatedLabel: false,
              inputProps: {
                type: 'number',
                name: 'numberOfOrdersMax',
                min: 0,
                value: numberOfOrdersMax,
              },
            },
          ],
          onClickHandler: updateOtherFilters,
        },
      ]}
      btnIcon={'DownArrow'}
      iconPosition='right'
    />
  );
};

export default NumberOfOrders;
