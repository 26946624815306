import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const StyledToast = styled.div`
  z-index: 100;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 250px;
  min-height: 50px;
  border-radius: 4px;
  background-color: ${theme.palette.brand.primary.charcoal};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.palette.brand.primary.white};
  padding: 0 20px;
  border-radius: 10px;
  .message-content {
    max-width: 374px;
    min-width: 250px;
    font-weight: 500;
    font-size: 14px;
  }
  .close-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 35px;
    margin-top: 7px;
    background-color: ${theme.palette.brand.primary.charcoal};
    transition: transform 0.2s;
    cursor: pointer;
    svg > g > path:nth-of-type(2) {
      fill: ${theme.palette.brand.primary.white};
    }
  }
`
