import React, { useState, useEffect } from 'react'
import { Calendar } from '@teamfabric/copilot-ui'
import { startOfDay, endOfDay, subDays, subMonths } from 'date-fns'
import { StyledFilterBar } from './style'
import AddMore from './addmore'
import TotalAmountSpent from './totalAmountSpent'
import NumberOfOrders from './numberOfOrders'
import Tags from './tags'
import DateFilter from './date'
import Status from 'components/filter/status';	
import { DEFAULT_STATUSES } from 'lib/data/constants';
const dateArray = [
  { id: 1, name: 'All', value: 'all' },
  { id: 2, name: 'Today', value: 'today' },
  { id: 3, name: 'Last 7 Days', value: 'last7Days' },
  { id: 4, name: 'Last Month', value: 'lastMonth' }
  // { id: 5, name: 'Specific Dates', value: 'specificDates' }
]
const yearArray = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]
const filterOption = [
  { name: 'Total Amount Spent', id: 1, value: 1, selected: false },
  { name: 'Number of Orders', id: 2, value: 2, selected: false }
]

const FilterBar = ({
  currentFilterValue,
  submitFilters,
  resetFilters,
  tagsData,
  isCDP,
}) => {
  const {
    tags,
    dateFilter,
    dateGte,
    dateLte,
    totalAmountSpentMin,
    totalAmountSpentMax,
    numberOfOrdersMin,
    numberOfOrdersMax
  } = currentFilterValue
  const [dateType, setDateType] = useState('All')
  const [displayDateType, setDisplayDateType] = useState('')
  const [showReset, setShowReset] = useState(false)
  const [selectedTags, setSelectedTags] = useState([]);	
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [tagsArray, setTagsArray] = useState([])
  const [optionsArr, setOptionsArr] = useState([...filterOption])

  useEffect(() => {
    setTagsArray(
      tagsData.map(tag => {
        return { id: tag._id, name: tag.name, value: tag.name }
      })
    )
  }, [tagsData])

  useEffect(() => {
    let activeTagsArray = tagsArray.filter(item => {
      if (tags.includes(item.value)) {
        return item.name
      }
    })
    activeTagsArray = activeTagsArray.map(item => {
      return item.name
    })

    setSelectedTags(activeTagsArray)
  }, [tags])

  useEffect(() => {
    let selectedOption = dateArray.filter(item => item.value === dateFilter)
    selectedOption = selectedOption[0]
    setDateType(selectedOption.value)
    setDisplayDateType(selectedOption.name)
  }, [dateFilter])

  const SpecificDate = () => (
    <div className='option-other'>
      <div className='inline-cal'>From</div>{' '}
      <Calendar
        onDateChange={date => specificDateHandler('dateGte', date)}
        yearsArray={yearArray}
        value={dateGte}
      />{' '}
      <div className='inline-cal'>To</div>{' '}
      <Calendar
        onDateChange={date => specificDateHandler('dateLte', date)}
        yearsArray={yearArray}
        value={dateLte}
      />
    </div>
  )

  const dateHandler = e => {
    let gte = dateGte,
      lte = dateLte

    if (e.target.value === 'today') {
      gte = startOfDay(new Date())
      lte = endOfDay(new Date())
    } else if (e.target.value === 'last7Days') {
      gte = startOfDay(subDays(new Date(), 7))
      lte = endOfDay(new Date())
    } else if (e.target.value === 'lastMonth') {
      gte = startOfDay(subMonths(new Date(), 1))
      lte = endOfDay(new Date())
    } else if (e.target.value === 'specificDates') {
      gte = dateGte
      lte = dateLte
    } else if (e.target.value === 'all') {
      gte = null
      lte = null
    }

    let updatedFilters = {
      ...currentFilterValue,
      dateFilter: e.target.value,
      dateGte: gte,
      dateLte: lte
    }
    submitFilters(updatedFilters)
    setShowReset(true)
  }

  const specificDateHandler = (type, date) => {
    let updatedFilters = {
      ...currentFilterValue,
      dateFilter: 'specificDates',
      [type]: date
    }

    submitFilters(updatedFilters)
    setShowReset(true)
  }

  const handleTagsChangeCSR = e => {
    let currentArr = [...tags]
    let clickedTags = e.target.value
    let finalArr = []
    if (currentArr.includes(clickedTags)) {
      finalArr = currentArr.filter(tags => tags !== clickedTags)
    } else {
      finalArr = [...currentArr, clickedTags]
    }
    let updatedFilters = {
      ...currentFilterValue,
      tags: finalArr
    }
    submitFilters(updatedFilters)
    setShowReset(true)
  }

  const handleTagsChange = e => {
    let updatedFilters = {
      ...currentFilterValue,
      tags: [
        ...tags.filter(tag => tag !== e.target.value),
        e.target.checked && e.target.value,
      ].filter(tag => tag !== false),
    };
    submitFilters(updatedFilters);
    setShowReset(true);
  };


  const updateOtherFilters = e => {
    let value

    if (e.target.name === 'shipToCity' || e.target.name === 'shipToState') {
      value = e.target.value
    } else {
      value = e.target.value !== '' ? Number(e.target.value) : ''
    }
    let updatedFilters = {
      ...currentFilterValue,
      [e.target.name]: value
    }
    submitFilters(updatedFilters)
    setShowReset(true)
  }

  const resetFilterBar = () => {
    const filterOptionArr = filterOption.map(option => {
      return {
        ...option,
        selected: false
      }
    })

    setOptionsArr(filterOptionArr)
    setShowReset(false)
    resetFilters()
  }

  const clickHandler = e => {
    let options = [...optionsArr]
    let selectedId = e.target.value

    const updatedOptions = options.map(option => {
      if (Number(option.id) === Number(selectedId)) {
        option.selected = !option.selected
        return option
      }
      return option
    })
    setShowReset(true)

    setOptionsArr(updatedOptions)
  }

  const handleStatusChange = e => {
    const selectedStatus = e.target.value;
    let updatedStatuses = [];
    if (selectedStatuses.includes(selectedStatus)) {
      updatedStatuses = selectedStatuses.filter(
        status => status !== selectedStatus
      );
    } else {
      updatedStatuses = [...selectedStatuses, selectedStatus];
    }
    setSelectedStatuses(updatedStatuses);
    submitFilters({
      ...currentFilterValue,
      statuses: updatedStatuses,
    });
    setShowReset(true);
  };

  return (
    <StyledFilterBar csr={!isCDP}>
      {!isCDP && <DateFilter
        dateArray={dateArray}
        dateHandler={dateHandler}
        SpecificDate={SpecificDate}
        dateType={dateType}
        displayDateType={displayDateType}
      />}
      <Tags
        tagsArray={tagsArray}
        handleTagsChange={isCDP ? handleTagsChange: handleTagsChangeCSR}
        selectedTags={selectedTags}
        tags={tags}
      />
      {isCDP && <Status	
        statusArray={DEFAULT_STATUSES}	
        handleStatusChange={handleStatusChange}	
        selectedStatuses={[]}	
        statuses={[]}	
      />}
      {optionsArr[0]?.selected && (
        <TotalAmountSpent
          totalAmountSpentMin={totalAmountSpentMin}
          totalAmountSpentMax={totalAmountSpentMax}
          updateOtherFilters={updateOtherFilters}
        />
      )}
      {optionsArr[1]?.selected && (
        <NumberOfOrders
          numberOfOrdersMin={numberOfOrdersMin}
          numberOfOrdersMax={numberOfOrdersMax}
          updateOtherFilters={updateOtherFilters}
        />
      )}
      {!isCDP && <AddMore optionsArr={optionsArr} clickHandler={clickHandler} />}
      {showReset && (
        <div className='reset-filters' onClick={() => resetFilterBar()}>
          Reset all
        </div>
      )}
    </StyledFilterBar>
  )
}

export default FilterBar
