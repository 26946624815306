"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var styles_1 = require("src/modules/OrganizationDetails/BreadCrumb/styles");
var copilot_ui_1 = require("@teamfabric/copilot-ui");
var mfa_communication_1 = require("@copilot/mfa-communication");
var router_1 = require("@reach/router");
var OrganizationBreadcrumb = function (_a) {
    var endLabel = _a.endLabel, organizationId = _a.organizationId, groupId = _a.groupId, _b = _a.groupTab, groupTab = _b === void 0 ? '' : _b, _c = _a.orgTab, orgTab = _c === void 0 ? '' : _c, groupName = _a.groupName;
    var _d = __read(react_1.default.useState('-'), 2), orgName = _d[0], setOrgName = _d[1];
    var _e = __read(react_1.default.useState('-'), 2), internalGroupName = _e[0], setInternalGroupName = _e[1];
    react_1.default.useEffect(function () {
        mfa_communication_1.CSRController.viewOrganization({
            organizationId: organizationId,
        }).then(function (response) {
            var name = response['name'] || '-';
            setOrgName(name);
        });
        if (groupId) {
            mfa_communication_1.CSRController.viewOrganizationGroup({
                organizationId: organizationId,
                groupId: groupId,
            }).then(function (response) {
                var name = response['name'] || '-';
                setInternalGroupName(name);
            });
        }
    }, [organizationId, groupId]);
    var naviGateToBack = function () {
        if (groupId) {
            (0, router_1.navigate)("/customer/organizations/".concat(organizationId, "/").concat(orgTab));
        }
        else {
            (0, router_1.navigate)("/customer/organizations/".concat(organizationId, "/group/").concat(groupId, "/").concat(groupTab));
        }
    };
    var breadCrumbData = (0, react_1.useMemo)(function () {
        var data = [
            {
                label: 'All organizations',
                onLabelClick: function () { return (0, router_1.navigate)('/customer/organizations/'); },
            },
            {
                label: orgName,
                onLabelClick: function () {
                    return (0, router_1.navigate)("/customer/organizations/".concat(organizationId, "/").concat(orgTab));
                },
            },
        ];
        if (groupId) {
            data.push({
                label: groupName || internalGroupName,
                onLabelClick: function () {
                    return (0, router_1.navigate)("/customer/organizations/".concat(organizationId, "/group/").concat(groupId, "/").concat(groupTab));
                },
            });
        }
        if (endLabel) {
            data.push({
                label: endLabel,
                onLabelClick: function () { return new Promise(function (resolve) { return resolve(); }); },
            });
        }
        return data;
    }, [
        endLabel,
        groupId,
        groupName,
        internalGroupName,
        groupTab,
        orgName,
        orgTab,
        organizationId,
    ]);
    return (react_1.default.createElement(styles_1.BreadCrumbDiv, null,
        react_1.default.createElement(copilot_ui_1.Breadcrumb, { backCaretHandler: function () { return naviGateToBack(); }, data: breadCrumbData })));
};
exports.default = OrganizationBreadcrumb;
