import { theme } from 'ds4-beta';
import styled from 'styled-components';

export const StyledH5 = styled.h5`
  ${theme.typography.h5}
  margin: 0;
`;

export const StyledEdit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${theme.space[4]};
  gap: ${theme.space[2]};
`;

export const Caption = styled.div`
  ${theme.typography.body2.regular}
  color: ${theme.color.grey[600]};
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${theme.space[4]};
`;

export const EllipsisContainer = styled.div`
  * {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const LastColumn = styled.div`
  ${theme.typography.body1.regular}
  display: flex;
  justify-content: space-between;
`;
