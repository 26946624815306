import get from 'lodash/get';
// eslint-disable-next-line import/no-unresolved
import { CSRController } from '@copilot/mfa-communication';
import { getCustomerByIdApi } from 'src/api/customer';

export const ACTIONS = {
  GET_CUSTOMER_DETAILS_BY_ID: 'get_customer_details_by_id',
  CLEAR_CUSTOMER_DETAILS: 'clear_customer_details',
  GET_CUSTOMER_TAGS: 'get_customer_tags',
  UPDATED_CUSTOMER_SUCCESS: 'updated_customer_success',
  CUSTOMER_LOADING: 'customer_loading',
};

export const getCustomerDetailsById =
  (customerId, useV3 = false) =>
  async dispatch => {
    dispatch({ type: ACTIONS.CUSTOMER_LOADING, payload: true });
    try {
      const customer = await CSRController[
        useV3 ? 'viewCustomerV3' : 'viewCustomer'
      ](customerId);

      dispatch({ type: ACTIONS.GET_CUSTOMER_DETAILS_BY_ID, payload: customer });
      dispatch({
        type: ACTIONS.GET_CUSTOMER_TAGS,
        payload: customer.tags ? customer.tags : [],
      });
      dispatch({ type: ACTIONS.CUSTOMER_LOADING, payload: false });
      return customer;
    } catch (err) {
      dispatch({ type: ACTIONS.CUSTOMER_LOADING, payload: false });
      throw err;
    }
  };

export const getCustomerDetailsByIdCSR = customerId => async dispatch => {
  dispatch({ type: ACTIONS.CUSTOMER_LOADING, payload: true });
  try {
    const { data: customer } = await getCustomerByIdApi(customerId);
    dispatch({ type: ACTIONS.GET_CUSTOMER_DETAILS_BY_ID, payload: customer });
    dispatch({
      type: ACTIONS.GET_CUSTOMER_TAGS,
      payload: customer.tags ? customer.tags : [],
    });
    dispatch({ type: ACTIONS.CUSTOMER_LOADING, payload: false });
    return customer;
  } catch (err) {
    const code = get(err, 'request.response.code', undefined);
    //Map validation errors to 404 not found
    if (code === 'REQUEST_VALIDATION') {
      err.response.status = 404;
      err.response.statusText = 'NOT FOUND';
    }
    dispatch({ type: ACTIONS.CUSTOMER_LOADING, payload: false });
    throw err;
  }
};

export const editCustomer = (customer, customerId) => async dispatch => {
  const updatedCustomer = await CSRController.updateCustomer(
    customer,
    customerId
  );
  dispatch({
    type: ACTIONS.UPDATED_CUSTOMER_SUCCESS,
    payload: updatedCustomer,
  });
};

export const clearCustomerDetails = () => async dispatch => {
  dispatch({ type: ACTIONS.CLEAR_CUSTOMER_DETAILS, payload: null });
};
