import React from 'react';
import ButtonWithDropdown from '../buttonWithDropdown';

const Status = ({
  statusArray,
  handleStatusChange,
  statuses,
}) => {
  return (
    <ButtonWithDropdown
      data={[
        {
          type: 'checkbox',
          data: statusArray,
          filteredData: statuses,
          onClickHandler: handleStatusChange,
        },
      ]}
      label={`Status`}
      btnIcon='DownArrow'
      iconPosition='right'
    />
  );
};

export default Status;
