import React, { useState } from 'react';
import {
  ButtonWithIcon,
  Checkbox,
  Input,
  Radio,
} from '@teamfabric/copilot-ui';
import {
  StyledButtonWithDropdown,
  StyledCheckbox,
  StyledInput,
  StyledRadio,
  StyledSimpleDropdown,
} from './style';
import ClickOutside from 'components/utils/ClickOutside';

const ButtonWithDropdown = ({
  data = [],
  label = '',
  btnTransparent = false,
  btnIcon = '',
  iconPosition = 'left',
  iconSize = 16,
  isPrimary = false,
  buttonClass,
  type = 'button',
  disabled = false,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  let icon = btnIcon;
  if (!icon) icon = showDropDown ? 'UpArrow' : 'DownArrow';

  const renderChildren = data => {
    return data.length
      ? data.map((item, index) => {
          switch (item.type.toLowerCase()) {
            case 'checkbox':
              return renderCheckbox(item, index);
            case 'input':
              return renderInput(item, index);
            case 'radio':
              return renderRadio(item, index);
            case 'simpledropdown':
              return renderSimpleDropdown(item, index);
          }
        })
      : '';
  };

  const renderCheckbox = (item, index) => {
    return (
      <StyledCheckbox key={index}>
        {item.data.length
          ? item.data.map(field => {
              return (
                <div key={field.id} className='option'>
                  <Checkbox
                    className='checkbox-field'
                    label={field.name}
                    onChange={e => item.onClickHandler(e)}
                    value={field.value}
                    checked={
                      field.selected || item.filteredData?.includes(field.value)
                    }
                  />
                </div>
              );
            })
          : ''}
      </StyledCheckbox>
    );
  };

  const renderInput = (item, index) => {
    return (
      <StyledInput key={index}>
        {item.data.map(field => {
          return (
            <div key={field.name} className='option'>
              <Input
                className='primary input-field'
                inputProps={field.inputProps}
                onChange={item.onClickHandler}
                isFloatedLabel={field.isFloatedLabel}
                label={field.label}
                width='350px'
                error={field?.error}
                errorMessage={field?.errorMessage}
              />
            </div>
          );
        })}
      </StyledInput>
    );
  };

  const renderSimpleDropdown = (item, index) => {
    return (
      <StyledSimpleDropdown key={index}>
        {item.data.map(field => {
          return (
            <div
              key={field.id}
              className='option'
              onClick={() => {
                setShowDropDown(false);
                item.onClickHandler(field);
              }}
            >
              {field.label}
            </div>
          );
        })}
      </StyledSimpleDropdown>
    );
  };

  const renderRadio = (item, index) => {
    return (
      <StyledRadio key={index}>
        {item.data.map(field => {
          return (
            <div key={field.id} className='option'>
              <Radio
                key={`radio-${field.id}`}
                className='radio-field'
                label={!field?.element ? field.name : null}
                data-testid={field.id}
                name={field?.uniqKey ? field?.uniqKey : 'date'}
                checked={item.dateType === field.value}
                onChange={e => item.onClickHandler(e)}
                value={field.value}
                withFocus
                withHover
              />
              {field?.element && field.element()}
            </div>
          );
        })}
      </StyledRadio>
    );
  };

  return (
    <StyledButtonWithDropdown
      show={showDropDown}
      btnTransparent={btnTransparent}
    >
      <ClickOutside
        propStyle={{ width: '100%', display: 'flex' }}
        clickEvent={() => setShowDropDown(false)}
      >
        <div className='dropdown'>
          <ButtonWithIcon
            className={`button-with-icons ${buttonClass} ${
              showDropDown && 'button-show'
            }`}
            emphasis={`${btnTransparent ? 'high' : 'low'}`}
            icon={disabled ? null : icon}
            iconSize={iconSize}
            iconPosition={iconPosition}
            isPrimary={isPrimary}
            onClick={() => setShowDropDown(!showDropDown)}
            text={label}
            theme='light'
            type={type}
            disabled={disabled}
          />
          <div className={`dropdown-content`}>{renderChildren(data)}</div>
        </div>
      </ClickOutside>
    </StyledButtonWithDropdown>
  );
};

export default ButtonWithDropdown;
