const transformCustomerFilters = rawFilters => {
  const filters = {};
  if (rawFilters.statuses.length === 1) {
    filters['isActive'] = rawFilters.statuses.includes('active') ? true : false;
  }
  if (rawFilters.tags.length > 0 && rawFilters.tags[0]) {
    filters['traitName'] = rawFilters.tags.join(',');
  }
  return filters;
};

export {
  transformCustomerFilters,
};
