"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigContextProvider = exports.useConfig = void 0;
var mfa_communication_1 = require("@copilot/mfa-communication");
var react_1 = __importStar(require("react"));
var general_config_1 = __importStar(require("../../general-config"));
var hasPermission_1 = require("src/hooks/hasPermission");
var ConfigContext = (0, react_1.createContext)({});
var ConfigContextProvider = function (_a) {
    var children = _a.children;
    var getPermitLinks = function () {
        var permitLinks = [];
        general_config_1.default.links.forEach(function (link) {
            if (link.permission) {
                if ((0, hasPermission_1.hasPermission)([link.permission])) {
                    permitLinks.push(link);
                }
            }
            else {
                permitLinks.push(link);
            }
        });
        return { links: permitLinks };
    };
    var _b = __read((0, react_1.useState)(getPermitLinks()), 2), config = _b[0], setConfig = _b[1];
    var setLinkVisibilityByLabel = function (label) {
        var drafts = __spreadArray([], __read(config.links), false);
        var draftIndex = drafts.findIndex(function (obj) { return obj.label == label; });
        drafts[draftIndex].visible = true;
        setConfig({ links: drafts });
    };
    (0, react_1.useEffect)(function () {
        mfa_communication_1.FeatureFlagController.getFeatureFlag({
            projectId: general_config_1.GITLAB_PROJECT_ID,
            featureFlagName: 'contract',
        }).then(function (flag) {
            if ((0, hasPermission_1.isEnabledEnvironment)(flag) && (0, hasPermission_1.isEnabledAccount)(flag)) {
                setLinkVisibilityByLabel('Contracts');
            }
        });
        mfa_communication_1.FeatureFlagController.getFeatureFlag({
            projectId: general_config_1.GITLAB_PROJECT_ID,
            featureFlagName: 'organization',
        }).then(function (flag) {
            if ((0, hasPermission_1.isEnabledEnvironment)(flag) && (0, hasPermission_1.isEnabledAccount)(flag)) {
                setLinkVisibilityByLabel('Organizations');
            }
        });
        mfa_communication_1.FeatureFlagController.getFeatureFlag({
            projectId: general_config_1.GITLAB_PROJECT_ID,
            featureFlagName: 'customer_segmentation',
        }).then(function (flag) {
            if ((0, hasPermission_1.isEnabledEnvironment)(flag)) {
                setLinkVisibilityByLabel('Segments');
            }
        });
    }, []);
    return (react_1.default.createElement(ConfigContext.Provider, { value: { config: config, setConfig: setConfig } }, children));
};
exports.ConfigContextProvider = ConfigContextProvider;
function useConfig() {
    var context = (0, react_1.useContext)(ConfigContext);
    return context;
}
exports.useConfig = useConfig;
