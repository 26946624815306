import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledButtonWithDropdown = styled.div`
  ${props =>
    props.btnTransparent
      ? `
        .button-with-icons:focus {
          background: rgb(0,0,0,0);
          border: 1px solid rgb(0,0,0,0);
          color: ${theme.palette.brand.primary.charcoal};
          svg {
            fill: ${theme.palette.brand.primary.charcoal};
          }
        }
        .button-with-icons:hover {
          background: rgb(0,0,0,0);
          border: 1px solid rgb(0,0,0,0);
          color: ${theme.palette.brand.primary.charcoal};
          svg {
            fill: ${theme.palette.brand.primary.charcoal};
          }
        }
        .button-with-icons:active {
          background: ${theme.palette.ui.neutral.grey1};
        }
      `
      : `
      .button-with-icons:focus {
        border: 1px solid rgb(0,0,0,0);
      }
    `}

  .dropdown {
    position: relative;
    display: inline-block;
    .active-cx {
      background: ${theme.palette.ui.cta.green};
      color: #ffffff;
      text-transform: uppercase;
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
    .inactive-cx {
      background: ${theme.palette.ui.neutral.grey6};
      color: #ffffff;
      text-transform: uppercase;
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }

  .dropdown-content {
    display: ${prop => (prop.show ? 'block' : 'none')};
    z-index: 1000;
    position: absolute;
    min-width: 200px;
    max-height: 200px;
    overflow-y: auto;
    left: 0;
    margin-top: 8px;
    background: ${theme.palette.brand.primary.white};
    border: 1px solid ${theme.palette.ui.neutral.grey2};
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const StyledCheckbox = styled.div`
  .checkbox-field {
    ${theme.typography.h6};
    color: ${theme.palette.brand.primary.charcoal};
    cursor: pointer;
    width: 100%;
  }
`;
export const StyledInput = styled.div`
  .input-field:first-child {
    padding-top: 15px;
  }
  .input-field {
    padding: 0 20px 20px 20px;
  }
`;
export const StyledRadio = styled.div`
  .radio-field,
  .radio-label {
    cursor: pointer;
    width: 100%;
  }
  .specificDate {
  }
`;

export const StyledSimpleDropdown = styled.div`
  ${theme.typography.h5};
  color: ${theme.palette.brand.primary.charcoal};
  cursor: pointer;

  .option {
    padding: 14px 24px;
    font-family: Gilroy-Medium;
  }

  .option:hover {
    background: ${theme.palette.ui.neutral.grey5};
  }
`;
