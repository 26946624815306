// Generated from /Users/vishnu.d/WebstormProjects/copilot-mfa-customer/src/modules/Segmentation/conditionBuilder/grammar/ConditionalGrammar.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';
import ConditionalGrammarListener from './ConditionalGrammarListener.js';
import ConditionalGrammarVisitor from './ConditionalGrammarVisitor.js';

const serializedATN = [4,1,18,43,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,
1,0,1,0,1,0,1,1,1,1,1,1,1,1,5,1,18,8,1,10,1,12,1,21,9,1,1,2,1,2,1,2,1,2,
1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,3,2,37,8,2,1,3,1,3,1,4,1,4,1,4,0,
0,5,0,2,4,6,8,0,5,2,0,5,5,10,11,1,0,10,11,2,0,12,12,15,15,1,0,3,4,1,0,6,
11,41,0,10,1,0,0,0,2,13,1,0,0,0,4,36,1,0,0,0,6,38,1,0,0,0,8,40,1,0,0,0,10,
11,3,2,1,0,11,12,5,0,0,1,12,1,1,0,0,0,13,19,3,4,2,0,14,15,3,6,3,0,15,16,
3,4,2,0,16,18,1,0,0,0,17,14,1,0,0,0,18,21,1,0,0,0,19,17,1,0,0,0,19,20,1,
0,0,0,20,3,1,0,0,0,21,19,1,0,0,0,22,23,5,1,0,0,23,24,3,2,1,0,24,25,5,2,0,
0,25,37,1,0,0,0,26,27,5,17,0,0,27,28,3,8,4,0,28,29,5,14,0,0,29,37,1,0,0,
0,30,31,5,17,0,0,31,32,7,0,0,0,32,37,5,16,0,0,33,34,5,17,0,0,34,35,7,1,0,
0,35,37,7,2,0,0,36,22,1,0,0,0,36,26,1,0,0,0,36,30,1,0,0,0,36,33,1,0,0,0,
37,5,1,0,0,0,38,39,7,3,0,0,39,7,1,0,0,0,40,41,7,4,0,0,41,9,1,0,0,0,2,19,
36];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.PredictionContextCache();

export default class ConditionalGrammarParser extends antlr4.Parser {

    static grammarFileName = "ConditionalGrammar.g4";
    static literalNames = [ null, "'('", "')'", "'AND'", "'OR'", "'MATCHES'", 
                            "'>'", "'>='", "'<'", "'<='", "'=='", "'!='", 
                            "'NULL'", "'-'" ];
    static symbolicNames = [ null, null, null, "AND", "OR", "MATCHES", "GT", 
                             "GTE", "LT", "LTE", "EQ", "NEQ", "NULL", "SUB", 
                             "NUMBER", "BOOLEAN", "STRING", "ID", "WHITESPACE" ];
    static ruleNames = [ "start", "expression", "cond", "logicalOp", "comparisonOp" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = ConditionalGrammarParser.ruleNames;
        this.literalNames = ConditionalGrammarParser.literalNames;
        this.symbolicNames = ConditionalGrammarParser.symbolicNames;
    }

    get atn() {
        return atn;
    }



	start() {
	    let localctx = new StartContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, ConditionalGrammarParser.RULE_start);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 10;
	        this.expression();
	        this.state = 11;
	        this.match(ConditionalGrammarParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	expression() {
	    let localctx = new ExpressionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, ConditionalGrammarParser.RULE_expression);
	    var _la = 0; // Token type
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 13;
	        this.cond();
	        this.state = 19;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===ConditionalGrammarParser.AND || _la===ConditionalGrammarParser.OR) {
	            this.state = 14;
	            this.logicalOp();
	            this.state = 15;
	            this.cond();
	            this.state = 21;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	cond() {
	    let localctx = new CondContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, ConditionalGrammarParser.RULE_cond);
	    var _la = 0; // Token type
	    try {
	        this.state = 36;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,1,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new InnerExpressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 22;
	            this.match(ConditionalGrammarParser.T__0);
	            this.state = 23;
	            localctx.inner = this.expression();
	            this.state = 24;
	            this.match(ConditionalGrammarParser.T__1);
	            break;

	        case 2:
	            localctx = new NumberComparisonContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 26;
	            localctx.left = this.match(ConditionalGrammarParser.ID);
	            this.state = 27;
	            localctx.operator = this.comparisonOp();
	            this.state = 28;
	            localctx.right = this.match(ConditionalGrammarParser.NUMBER);
	            break;

	        case 3:
	            localctx = new StringOperationContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 30;
	            localctx.left = this.match(ConditionalGrammarParser.ID);
	            this.state = 31;
	            localctx.operator = this._input.LT(1);
	            _la = this._input.LA(1);
	            if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << ConditionalGrammarParser.MATCHES) | (1 << ConditionalGrammarParser.EQ) | (1 << ConditionalGrammarParser.NEQ))) !== 0))) {
	                localctx.operator = this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 32;
	            localctx.right = this.match(ConditionalGrammarParser.STRING);
	            break;

	        case 4:
	            localctx = new NullBooleanOperationContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 33;
	            localctx.left = this.match(ConditionalGrammarParser.ID);
	            this.state = 34;
	            localctx.operator = this._input.LT(1);
	            _la = this._input.LA(1);
	            if(!(_la===ConditionalGrammarParser.EQ || _la===ConditionalGrammarParser.NEQ)) {
	                localctx.operator = this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 35;
	            localctx.right = this._input.LT(1);
	            _la = this._input.LA(1);
	            if(!(_la===ConditionalGrammarParser.NULL || _la===ConditionalGrammarParser.BOOLEAN)) {
	                localctx.right = this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	logicalOp() {
	    let localctx = new LogicalOpContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, ConditionalGrammarParser.RULE_logicalOp);
	    var _la = 0; // Token type
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 38;
	        _la = this._input.LA(1);
	        if(!(_la===ConditionalGrammarParser.AND || _la===ConditionalGrammarParser.OR)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	comparisonOp() {
	    let localctx = new ComparisonOpContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, ConditionalGrammarParser.RULE_comparisonOp);
	    var _la = 0; // Token type
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 40;
	        _la = this._input.LA(1);
	        if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << ConditionalGrammarParser.GT) | (1 << ConditionalGrammarParser.GTE) | (1 << ConditionalGrammarParser.LT) | (1 << ConditionalGrammarParser.LTE) | (1 << ConditionalGrammarParser.EQ) | (1 << ConditionalGrammarParser.NEQ))) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

ConditionalGrammarParser.EOF = antlr4.Token.EOF;
ConditionalGrammarParser.T__0 = 1;
ConditionalGrammarParser.T__1 = 2;
ConditionalGrammarParser.AND = 3;
ConditionalGrammarParser.OR = 4;
ConditionalGrammarParser.MATCHES = 5;
ConditionalGrammarParser.GT = 6;
ConditionalGrammarParser.GTE = 7;
ConditionalGrammarParser.LT = 8;
ConditionalGrammarParser.LTE = 9;
ConditionalGrammarParser.EQ = 10;
ConditionalGrammarParser.NEQ = 11;
ConditionalGrammarParser.NULL = 12;
ConditionalGrammarParser.SUB = 13;
ConditionalGrammarParser.NUMBER = 14;
ConditionalGrammarParser.BOOLEAN = 15;
ConditionalGrammarParser.STRING = 16;
ConditionalGrammarParser.ID = 17;
ConditionalGrammarParser.WHITESPACE = 18;

ConditionalGrammarParser.RULE_start = 0;
ConditionalGrammarParser.RULE_expression = 1;
ConditionalGrammarParser.RULE_cond = 2;
ConditionalGrammarParser.RULE_logicalOp = 3;
ConditionalGrammarParser.RULE_comparisonOp = 4;

class StartContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ConditionalGrammarParser.RULE_start;
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EOF() {
	    return this.getToken(ConditionalGrammarParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterStart(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitStart(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitStart(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExpressionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ConditionalGrammarParser.RULE_expression;
    }

	cond = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(CondContext);
	    } else {
	        return this.getTypedRuleContext(CondContext,i);
	    }
	};

	logicalOp = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(LogicalOpContext);
	    } else {
	        return this.getTypedRuleContext(LogicalOpContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class CondContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ConditionalGrammarParser.RULE_cond;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class StringOperationContext extends CondContext {

    constructor(parser, ctx) {
        super(parser);
        this.left = null; // Token;
        this.operator = null; // Token;
        this.right = null; // Token;
        super.copyFrom(ctx);
    }

	ID() {
	    return this.getToken(ConditionalGrammarParser.ID, 0);
	};

	STRING() {
	    return this.getToken(ConditionalGrammarParser.STRING, 0);
	};

	EQ() {
	    return this.getToken(ConditionalGrammarParser.EQ, 0);
	};

	NEQ() {
	    return this.getToken(ConditionalGrammarParser.NEQ, 0);
	};

	MATCHES() {
	    return this.getToken(ConditionalGrammarParser.MATCHES, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterStringOperation(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitStringOperation(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitStringOperation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

ConditionalGrammarParser.StringOperationContext = StringOperationContext;

class InnerExpressionContext extends CondContext {

    constructor(parser, ctx) {
        super(parser);
        this.inner = null; // ExpressionContext;
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterInnerExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitInnerExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitInnerExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

ConditionalGrammarParser.InnerExpressionContext = InnerExpressionContext;

class NumberComparisonContext extends CondContext {

    constructor(parser, ctx) {
        super(parser);
        this.left = null; // Token;
        this.operator = null; // ComparisonOpContext;
        this.right = null; // Token;
        super.copyFrom(ctx);
    }

	ID() {
	    return this.getToken(ConditionalGrammarParser.ID, 0);
	};

	comparisonOp() {
	    return this.getTypedRuleContext(ComparisonOpContext,0);
	};

	NUMBER() {
	    return this.getToken(ConditionalGrammarParser.NUMBER, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterNumberComparison(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitNumberComparison(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitNumberComparison(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

ConditionalGrammarParser.NumberComparisonContext = NumberComparisonContext;

class NullBooleanOperationContext extends CondContext {

    constructor(parser, ctx) {
        super(parser);
        this.left = null; // Token;
        this.operator = null; // Token;
        this.right = null; // Token;
        super.copyFrom(ctx);
    }

	ID() {
	    return this.getToken(ConditionalGrammarParser.ID, 0);
	};

	EQ() {
	    return this.getToken(ConditionalGrammarParser.EQ, 0);
	};

	NEQ() {
	    return this.getToken(ConditionalGrammarParser.NEQ, 0);
	};

	NULL() {
	    return this.getToken(ConditionalGrammarParser.NULL, 0);
	};

	BOOLEAN() {
	    return this.getToken(ConditionalGrammarParser.BOOLEAN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterNullBooleanOperation(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitNullBooleanOperation(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitNullBooleanOperation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

ConditionalGrammarParser.NullBooleanOperationContext = NullBooleanOperationContext;

class LogicalOpContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ConditionalGrammarParser.RULE_logicalOp;
    }

	AND() {
	    return this.getToken(ConditionalGrammarParser.AND, 0);
	};

	OR() {
	    return this.getToken(ConditionalGrammarParser.OR, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterLogicalOp(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitLogicalOp(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitLogicalOp(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ComparisonOpContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ConditionalGrammarParser.RULE_comparisonOp;
    }

	GT() {
	    return this.getToken(ConditionalGrammarParser.GT, 0);
	};

	GTE() {
	    return this.getToken(ConditionalGrammarParser.GTE, 0);
	};

	LT() {
	    return this.getToken(ConditionalGrammarParser.LT, 0);
	};

	LTE() {
	    return this.getToken(ConditionalGrammarParser.LTE, 0);
	};

	EQ() {
	    return this.getToken(ConditionalGrammarParser.EQ, 0);
	};

	NEQ() {
	    return this.getToken(ConditionalGrammarParser.NEQ, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.enterComparisonOp(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ConditionalGrammarListener ) {
	        listener.exitComparisonOp(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof ConditionalGrammarVisitor ) {
	        return visitor.visitComparisonOp(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




ConditionalGrammarParser.StartContext = StartContext; 
ConditionalGrammarParser.ExpressionContext = ExpressionContext; 
ConditionalGrammarParser.CondContext = CondContext; 
ConditionalGrammarParser.LogicalOpContext = LogicalOpContext; 
ConditionalGrammarParser.ComparisonOpContext = ComparisonOpContext; 
