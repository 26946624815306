// Generated from /Users/vishnu.d/WebstormProjects/copilot-mfa-customer/src/modules/Segmentation/conditionBuilder/grammar/ConditionalGrammar.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,18,159,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,
4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,
12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,
1,0,1,0,1,1,1,1,1,2,1,2,1,2,1,2,1,3,1,3,1,3,1,4,1,4,1,4,1,4,1,4,1,4,1,4,
1,4,1,5,1,5,1,6,1,6,1,6,1,7,1,7,1,8,1,8,1,8,1,9,1,9,1,9,1,10,1,10,1,10,1,
11,1,11,1,11,1,11,1,11,1,12,1,12,1,13,3,13,85,8,13,1,13,4,13,88,8,13,11,
13,12,13,89,1,13,1,13,4,13,94,8,13,11,13,12,13,95,3,13,98,8,13,1,13,3,13,
101,8,13,1,13,1,13,4,13,105,8,13,11,13,12,13,106,3,13,109,8,13,1,14,1,14,
1,14,1,14,1,14,1,14,1,14,1,14,1,14,3,14,120,8,14,1,15,1,15,1,15,5,15,125,
8,15,10,15,12,15,128,9,15,1,15,1,15,1,16,1,16,1,16,1,16,3,16,136,8,16,1,
17,1,17,1,17,5,17,141,8,17,10,17,12,17,144,9,17,1,18,1,18,5,18,148,8,18,
10,18,12,18,151,9,18,1,19,4,19,154,8,19,11,19,12,19,155,1,19,1,19,1,126,
0,20,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,17,9,19,10,21,11,23,12,25,13,27,
14,29,15,31,16,33,0,35,17,37,0,39,18,1,0,4,1,0,48,57,2,0,65,90,97,122,4,
0,48,57,65,90,95,95,97,122,3,0,9,10,13,13,32,32,170,0,1,1,0,0,0,0,3,1,0,
0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,0,0,15,1,
0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,0,0,0,25,1,0,0,0,0,
27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,35,1,0,0,0,0,39,1,0,0,0,1,41,1,0,
0,0,3,43,1,0,0,0,5,45,1,0,0,0,7,49,1,0,0,0,9,52,1,0,0,0,11,60,1,0,0,0,13,
62,1,0,0,0,15,65,1,0,0,0,17,67,1,0,0,0,19,70,1,0,0,0,21,73,1,0,0,0,23,76,
1,0,0,0,25,81,1,0,0,0,27,108,1,0,0,0,29,119,1,0,0,0,31,121,1,0,0,0,33,135,
1,0,0,0,35,137,1,0,0,0,37,145,1,0,0,0,39,153,1,0,0,0,41,42,5,40,0,0,42,2,
1,0,0,0,43,44,5,41,0,0,44,4,1,0,0,0,45,46,5,65,0,0,46,47,5,78,0,0,47,48,
5,68,0,0,48,6,1,0,0,0,49,50,5,79,0,0,50,51,5,82,0,0,51,8,1,0,0,0,52,53,5,
77,0,0,53,54,5,65,0,0,54,55,5,84,0,0,55,56,5,67,0,0,56,57,5,72,0,0,57,58,
5,69,0,0,58,59,5,83,0,0,59,10,1,0,0,0,60,61,5,62,0,0,61,12,1,0,0,0,62,63,
5,62,0,0,63,64,5,61,0,0,64,14,1,0,0,0,65,66,5,60,0,0,66,16,1,0,0,0,67,68,
5,60,0,0,68,69,5,61,0,0,69,18,1,0,0,0,70,71,5,61,0,0,71,72,5,61,0,0,72,20,
1,0,0,0,73,74,5,33,0,0,74,75,5,61,0,0,75,22,1,0,0,0,76,77,5,78,0,0,77,78,
5,85,0,0,78,79,5,76,0,0,79,80,5,76,0,0,80,24,1,0,0,0,81,82,5,45,0,0,82,26,
1,0,0,0,83,85,5,45,0,0,84,83,1,0,0,0,84,85,1,0,0,0,85,87,1,0,0,0,86,88,7,
0,0,0,87,86,1,0,0,0,88,89,1,0,0,0,89,87,1,0,0,0,89,90,1,0,0,0,90,97,1,0,
0,0,91,93,5,46,0,0,92,94,7,0,0,0,93,92,1,0,0,0,94,95,1,0,0,0,95,93,1,0,0,
0,95,96,1,0,0,0,96,98,1,0,0,0,97,91,1,0,0,0,97,98,1,0,0,0,98,109,1,0,0,0,
99,101,5,45,0,0,100,99,1,0,0,0,100,101,1,0,0,0,101,102,1,0,0,0,102,104,5,
46,0,0,103,105,7,0,0,0,104,103,1,0,0,0,105,106,1,0,0,0,106,104,1,0,0,0,106,
107,1,0,0,0,107,109,1,0,0,0,108,84,1,0,0,0,108,100,1,0,0,0,109,28,1,0,0,
0,110,111,5,84,0,0,111,112,5,82,0,0,112,113,5,85,0,0,113,120,5,69,0,0,114,
115,5,70,0,0,115,116,5,65,0,0,116,117,5,76,0,0,117,118,5,83,0,0,118,120,
5,69,0,0,119,110,1,0,0,0,119,114,1,0,0,0,120,30,1,0,0,0,121,126,5,34,0,0,
122,125,3,33,16,0,123,125,9,0,0,0,124,122,1,0,0,0,124,123,1,0,0,0,125,128,
1,0,0,0,126,127,1,0,0,0,126,124,1,0,0,0,127,129,1,0,0,0,128,126,1,0,0,0,
129,130,5,34,0,0,130,32,1,0,0,0,131,132,5,92,0,0,132,136,5,34,0,0,133,134,
5,92,0,0,134,136,5,92,0,0,135,131,1,0,0,0,135,133,1,0,0,0,136,34,1,0,0,0,
137,142,3,37,18,0,138,139,5,46,0,0,139,141,3,37,18,0,140,138,1,0,0,0,141,
144,1,0,0,0,142,140,1,0,0,0,142,143,1,0,0,0,143,36,1,0,0,0,144,142,1,0,0,
0,145,149,7,1,0,0,146,148,7,2,0,0,147,146,1,0,0,0,148,151,1,0,0,0,149,147,
1,0,0,0,149,150,1,0,0,0,150,38,1,0,0,0,151,149,1,0,0,0,152,154,7,3,0,0,153,
152,1,0,0,0,154,155,1,0,0,0,155,153,1,0,0,0,155,156,1,0,0,0,156,157,1,0,
0,0,157,158,6,19,0,0,158,40,1,0,0,0,15,0,84,89,95,97,100,106,108,119,124,
126,135,142,149,155,1,6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class ConditionalGrammarLexer extends antlr4.Lexer {

    static grammarFileName = "ConditionalGrammar.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "'('", "')'", "'AND'", "'OR'", "'MATCHES'", 
                         "'>'", "'>='", "'<'", "'<='", "'=='", "'!='", "'NULL'", 
                         "'-'" ];
	static symbolicNames = [ null, null, null, "AND", "OR", "MATCHES", "GT", 
                          "GTE", "LT", "LTE", "EQ", "NEQ", "NULL", "SUB", 
                          "NUMBER", "BOOLEAN", "STRING", "ID", "WHITESPACE" ];
	static ruleNames = [ "T__0", "T__1", "AND", "OR", "MATCHES", "GT", "GTE", 
                      "LT", "LTE", "EQ", "NEQ", "NULL", "SUB", "NUMBER", 
                      "BOOLEAN", "STRING", "ESC", "ID", "ID_NAME", "WHITESPACE" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    }

    get atn() {
        return atn;
    }
}

ConditionalGrammarLexer.EOF = antlr4.Token.EOF;
ConditionalGrammarLexer.T__0 = 1;
ConditionalGrammarLexer.T__1 = 2;
ConditionalGrammarLexer.AND = 3;
ConditionalGrammarLexer.OR = 4;
ConditionalGrammarLexer.MATCHES = 5;
ConditionalGrammarLexer.GT = 6;
ConditionalGrammarLexer.GTE = 7;
ConditionalGrammarLexer.LT = 8;
ConditionalGrammarLexer.LTE = 9;
ConditionalGrammarLexer.EQ = 10;
ConditionalGrammarLexer.NEQ = 11;
ConditionalGrammarLexer.NULL = 12;
ConditionalGrammarLexer.SUB = 13;
ConditionalGrammarLexer.NUMBER = 14;
ConditionalGrammarLexer.BOOLEAN = 15;
ConditionalGrammarLexer.STRING = 16;
ConditionalGrammarLexer.ID = 17;
ConditionalGrammarLexer.WHITESPACE = 18;



