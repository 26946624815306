import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledNavigation = styled.div`
  .side-navigation {
    padding: 20px;

    ul {
      width: 150px;
    }

    .secondary {
      background-color: ${theme.palette.ui.neutral.grey5};
    }
  }
`;
