"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchTraits = exports.fetchSegments = exports.ACTIONS = void 0;
var segmentation_1 = require("src/api/segmentation");
var mfa_communication_1 = require("@copilot/mfa-communication");
exports.ACTIONS = {
    SET_SEGMENTS: 'SET_SEGMENTS',
    SET_FILTER_ATTRS: 'SET_FILTER_ATTRS',
};
var fetchSegments = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var segments;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, segmentation_1.getAllSegments)()];
            case 1:
                segments = (_a.sent()).data;
                dispatch({
                    type: exports.ACTIONS.SET_SEGMENTS,
                    payload: segments,
                });
                return [2 /*return*/];
        }
    });
}); }; };
exports.fetchSegments = fetchSegments;
var fetchTraits = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var offset, limit, hasMore, allTraits, traitsResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                offset = 0;
                limit = 1000;
                allTraits = [];
                _a.label = 1;
            case 1: return [4 /*yield*/, mfa_communication_1.CSRController.listTraits({
                    offset: offset,
                    limit: limit,
                })];
            case 2:
                traitsResponse = (_a.sent());
                traitsResponse.data
                    .filter(function (trait) { return !trait.isDeleted; })
                    .map(function (trait) { return trait.name; })
                    .forEach(function (traitName) { return allTraits.push(traitName); });
                hasMore = traitsResponse.query.count > allTraits.length;
                if (hasMore)
                    offset = traitsResponse.query.limit;
                _a.label = 3;
            case 3:
                if (hasMore) return [3 /*break*/, 1];
                _a.label = 4;
            case 4:
                dispatch({
                    type: exports.ACTIONS.SET_FILTER_ATTRS,
                    payload: allTraits,
                });
                return [2 /*return*/];
        }
    });
}); }; };
exports.fetchTraits = fetchTraits;
