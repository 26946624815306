import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, theme } from '@teamfabric/copilot-ui'
import { StyledToast } from './styles'
import getToasts from './selectors'
import { removeToast } from './actions'

function Toast ({ toast }) {
  const dispatch = useDispatch()

  const { type, duration, message, id } = toast

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    setTimeout(() => {
      dispatch(removeToast(id))
    }, duration)

    return () => {
      document.body.style.overflow = 'auto'
      dispatch(removeToast(id))
    }
  }, [dispatch, duration, id])

  const closeToast = id => {
    dispatch(removeToast(id))
  }

  return (
    <StyledToast type={type} style={{ marginTop: 16 }}>
      <div className='message-content'>{message}</div>
      <div className='close-container' onClick={() => closeToast(toast.id)}>
        <Icon
          iconName='Close'
          size={16}
          fill={theme.palette.brand.primary.white}
          viewBox='0 0 16 16'
        />
      </div>
    </StyledToast>
  )
}

function ToastList () {
  const toasts = useSelector(getToasts.getToasts)

  return toasts.length ? <Toast toast={toasts[0]} /> : null
}

export default ToastList
