import { TOAST_ACTIONS } from 'modules/Toasts/actions';
import {
  addTagsToCustomersBulkApi,
  removeTagsToCustomersBulkApi,
  addCustomer,
} from 'src/api/customer';
import { CSRController } from '@copilot/mfa-communication';
// eslint-disable-next-line import/namespace
import { USER_STATUSES } from 'lib/data/constants';

export const ACTIONS = {
  CREATED_USER_SUCCESS: 'created_user_success',
  CREATED_CUSTOMER_SUCCESS: 'created_customer_success',
  CREATED_USER_ERROR: 'created_user_error',
  CREATED_CUSTOMER_ERROR: 'created_customer_error',
};

export const addOrRemoveTagsToCustomer =
  ({ addTags, params, tagsCount, customersCount }) =>
  async dispatch => {
    try {
      let toastMessage = `${tagsCount} tags have been ${
        addTags ? 'added to' : 'removed from'
      } ${customersCount} customers`;

      const { data } = addTags
        ? await addTagsToCustomersBulkApi(params)
        : await removeTagsToCustomersBulkApi(params);

      const { success, message } = checkForSuccess(data);

      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: success ? 'SUCCESS' : 'FAIL',
          message: success ? toastMessage : message,
          duration: 3000,
        },
      });
    } catch (err) {
      console.log({ err });
      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'FAIL',
          message: addTags ? 'Error adding tags' : 'Error removing tags',
          duration: 3000,
        },
      });
    }
  };

const checkForSuccess = data => {
  const error = { success: false, message: '' };

  if (
    data.message?.toLowerCase().includes('timeout') ||
    data.code?.includes('USER_UPDATE_ERROR')
  ) {
    error.message = data.message;
  } else if (data.message?.includes('Customer(s) Updated')) {
    error.success = true;
    error.message = data.message;
  }

  return error;
};

export const addCustomerApi = params => async dispatch => {
  let { data } = await addCustomer({ user: { ...params } });

  if (data._id && data.userId) {
    dispatch({
      type: ACTIONS.CREATED_USER_SUCCESS,
      payload: data,
    });
  }
};

export const createCustomerV3 = customer => async dispatch => {
  const newCustomer = await CSRController.createCustomerV3(customer);
  dispatch(
    newCustomer.error
      ? {
          type: ACTIONS.CREATED_CUSTOMER_ERROR,
          payload: newCustomer,
        }
      : {
          type: ACTIONS.CREATED_CUSTOMER_SUCCESS,
          payload: newCustomer,
        }
  );
};

export const createCustomer = customer => async dispatch => {
  const newUser = await CSRController.createUser({
    username: customer.name,
    userId: customer.email,
    firstName: customer.name.split(' ')[0],
    lastName: customer.name.split(' ')[1],
    email: customer.email,
    status: USER_STATUSES.ACTIVE,
    role: 'reporter',
    additionalAttributes: customer.additionalAttributes,
  });
  const newCustomer = await CSRController.createCustomer(customer);
  if (newCustomer.error) {
    dispatch({
      type: ACTIONS.CREATED_CUSTOMER_ERROR,
      payload: newCustomer,
    });
  } else {
    await CSRController.createUserParty({
      userId: newUser._id,
      partyId: newCustomer._id,
    });
    dispatch({
      type: ACTIONS.CREATED_CUSTOMER_SUCCESS,
      payload: newCustomer,
    });
  }
};
