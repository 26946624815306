import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledFilterBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;

  .dropdown {
     margin-left: ${prop => (prop.csr ? '10px' : '0px')};
    .dropbtn:focus {
      outline: none;
      border: none;
    }
  }

  .dropdown {
    .option-other {
      select {
        border: none;
        margin-top: 10px;
        margin-left: -5px;
        border-bottom: 1px solid #000;
        padding-bottom: 2px;
      }

      select:focus {
        outline: none;
        border: none;
      }
    }
  }
  /* Reset Button */

  .reset-filters {
    cursor: pointer;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #121213;
    padding-right: 10px;
  }

  /*WRAPPER FOR BAR*/

  .filter-wrapper {
    display: flex;
    flex-direction: row-reverse;

    .dropbtn {
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 20px;
      min-width: 110px;
      max-width: 150px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #fff;
      border: 1px solid #fff;
      font-family: 'Gilroy-Medium';
      color: #121213;
    }

    .dropdown {
      position: relative;
      display: inline-block;
      margin-left: 10px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      min-width: 200px;
      max-height: 200px;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: 0px 6px 16px rgb(115 127 143 / 16%);
      z-index: 1;
      right: 0;
    }

    .date-dropdown {
      overflow-y: inherit;
      .option {
        .react-datepicker-wrapper {
          input {
            outline: none;
            border: none;
            border-bottom: 1px solid #000;
            width: 90px;
            font-family: 'Gilroy-Medium';
            padding: 2px 4px;
          }
        }
      }
    }
    .dropdown-content .option {
      color: black;
      padding: 15px 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .option .option-name {
      margin-left: 10px;
      display: inherit;

      .inline-cal {
        margin-right: 5px;
      }
    }

    .dropdown-content .option:hover {
      background-color: rgb(115 127 143 / 10%);
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    .dropdown:hover .dropbtn {
      background-color: #737f8f;
      color: #fff;
    }
  }
`;

export const StyledAddMore = styled.div`
  ${props =>
    props.btnTransparent
      ? `
    .button-with-icons:focus {
      background: rgb(0,0,0,0);
      border: 1px solid rgb(0,0,0,0);
      color: ${theme.palette.brand.primary.charcoal};
      svg {
        fill: ${theme.palette.brand.primary.charcoal};
      }
    }
    .button-with-icons:hover {
      background: rgb(0,0,0,0);
      border: 1px solid rgb(0,0,0,0);
      color: ${theme.palette.brand.primary.charcoal};
      svg {
        fill: ${theme.palette.brand.primary.charcoal};
      }
    }
    .button-with-icons:active {
      background: ${theme.palette.ui.neutral.grey1};
    }
  `
      : ``}

  .dropdown {
    position: relative;
    display: inline-block;
    margin-left: 10px;
  }

  .dropdown-content {
    display: ${prop => (prop.show ? 'block' : 'none')};
    z-index: 1000;
    position: absolute;
    min-width: 200px;
    right: 0;
    margin-top: 10px;
    background: ${theme.palette.brand.primary.white};
    border: 1px solid ${theme.palette.ui.neutral.grey2};
    box-sizing: border-box;
    border-radius: 4px;
  }

  .dropdown-content .option {
    color: ${theme.palette.brand.primary.charcoal};
    padding: 15px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .option .option-name {
    margin-left: 10px;
    display: inherit;

    .inline-cal {
      margin-right: 5px;
    }
  }

  .dropdown-content .option:hover {
    background-color: rgb(115 127 143 / 10%);
    cursor: pointer;
  }
`;

export const StyledOtherFilters = styled.div`
  .dropbtn {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 20px;
    min-width: 110px;
    width: auto;
    font-family: 'Gilroy-Medium';
  }

  .dropdown {
    position: relative;
    display: inline-block;
    margin-left: 10px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 200px;
    background-color: #fff;
    box-shadow: 0px 6px 16px rgb(115 127 143 / 16%);
    z-index: 1;
    right: 0;
  }

  .dropdown-content .option-other {
    color: black;
    padding: 15px 20px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    select {
      width: 100%;
    }

    input:focus {
      outline: none;
    }

    input[type='number'] {
      width: 100%;
      border: none;
      border-bottom: 1px solid #000;
    }

    input[type='text'] {
      width: 100%;
      border: none;
      border-bottom: 1px solid #000;
    }
  }
  .option .option-name {
    margin-left: 10px;
    display: inherit;

    .inline-cal {
      margin-right: 5px;
    }
  }

  .dropdown-content .option:hover {
    background-color: rgb(115 127 143 / 10%);
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #737f8f;
    color: #fff;
  }
`;
