import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { FlagProvider } from '@unleash/proxy-client-react';
import DS4Switch from './DS4Switch';

const unleashConfig = {
  url: process.env.UNLEASH_PROXY_URL,
  clientKey: process.env.UNLEASH_CLIENT_KEY,
  appName: 'mfa-customer',
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem('accountId'),
  },
};

const Root = () => {
  return (
    <FlagProvider config={unleashConfig}>
      <DS4Switch />
    </FlagProvider>
  );
};

datadogRum.init({
  clientToken: `${process.env.DATADOG_CLIENT_TOKEN_MFA_CUSTOMER}`,
  applicationId: `${process.env.DATADOG_APPLICATION_ID_MFA_CUSTOMER}`,
  site: 'datadoghq.com',
  service: 'copilot-mfa-customer',
  env: JSON.stringify(process.env.STAGE),
  version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

export default Root;
