import React from 'react'
import { StyledPerformancePage } from './styles'

const PerformancePage = () => {
  return (
    <StyledPerformancePage>
      <h1>Performance Page</h1>
    </StyledPerformancePage>
  )
}

export default PerformancePage
