import React, { useEffect, useState } from 'react';
import {
  Box,
  GridCol,
  GridRow,
  Input,
  PageHeader,
  SkeletonH3,
  SkeletonButton,
  SkeletonH1,
  theme,
  useToast,
  SkeletonFieldLabel,
  SkeletonH5,
  InteractiveIcon,
} from 'ds4-beta';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetailsById } from 'src/modules/CustomerDetails/actions';
import { hasPermission } from 'src/hooks/hasPermission';
import {
  COMMON_BREADCRUMB,
  CUSTOMER_PERMISSIONS,
  OTHER_ERROR,
} from 'src/lib/data/constants';
// eslint-disable-next-line import/no-unresolved
import { CSRController } from '@copilot/mfa-communication';
import { dateFormatter } from 'src/lib/utils/date';
import {
  getNameString,
  isEmailValid,
  isNameValid,
} from 'src/lib/utils/helpers';
import { StyledH5 } from '../styles';

const CustomerDetail = ({ customerId, isV3API }) => {
  const showToast = useToast();
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customerDetails.customerDetails);
  const customerLoading = useSelector(
    state => state.customerDetails.customerDetailsLoading
  );
  const canEditCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_UPDATE]);
  const canReadAddress = hasPermission([
    CUSTOMER_PERMISSIONS.CUST_ADDRESS_READ,
  ]);

  const [customerName, setCustomerName] = useState('');
  const [nameError, setNameError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [title, setTitle] = useState('');
  const [suffix, setSuffix] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [customerStatus, setCustomerStatus] = useState(false);
  const [unsavedState, setUnsavedState] = useState(false);
  const [savingCustomer, setSavingCustomer] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const updateBreadcrumbs = nameToUse => {
    window.updateBreadcrumb([
      ...COMMON_BREADCRUMB,
      {
        label: nameToUse,
        url: `/customers/customers/${customer._id || customer.id}`,
      },
    ]);
  };

  const getCustomerAttributes = customer => {
    return isV3API
      ? {
          name: getNameString(customer),
          email: customer.emailAddress,
          isActive: customer.status !== 'INACTIVE',
          defaultAddress: customer.defaultAddress,
          updatedAt: customer.updatedAt,
        }
      : customer;
  };

  useEffect(() => {
    dispatch(getCustomerDetailsById(customerId, isV3API));
  }, [dispatch, customerId]);

  useEffect(() => {
    const _customer = getCustomerAttributes(customer);
    setCustomerName(_customer.name);
    setCustomerEmail(_customer.email);
    setCustomerStatus(_customer.isActive);
    setUpdatedAt(dateFormatter(_customer.updatedAt, 'MMM dd, h:mm a'));
    updateBreadcrumbs(_customer.name);
    setAddresses(_customer.defaultAddress || []);
    if (isV3API && customer.name) {
      const _name = customer.name;
      setTitle(_name.title);
      setFirstName(_name.firstName);
      setMiddleName(_name.middleName);
      setLastName(_name.lastName);
      setSuffix(_name.suffix);
    }
  }, [customer]);

  const onSave = (name, email) => {
    let hasError = false;
    if (!isV3API) {
      if (!isNameValid(name)) {
        hasError = true;
        setNameError('Name should be at least 3 characters');
      }
    } else {
      if (!isNameValid(firstName)) {
        hasError = true;
        setFirstNameError('First name should be at least 3 characters');
      }
      if (!isNameValid(lastName)) {
        hasError = true;
        setLastNameError('Last name should be at least 3 characters');
      }
    }
    if (!isEmailValid(email)) {
      hasError = true;
      setEmailError('Email format incorrect');
    }
    if (!hasError) {
      setSavingCustomer(true);
      updateCustomer(name, email, customer.status);
    }
  };

  const updateCustomer = (name, email, status) => {
    let _customer = {
      name,
      email,
      partyType: customer.partyType,
      isActive: status,
    };
    if (isV3API) {
      _customer = {
        name: {
          title,
          firstName,
          middleName,
          lastName,
          suffix,
        },
        emailAddress: email,
      };
    }
    CSRController[isV3API ? 'updateCustomerV3' : 'updateCustomer'](
      _customer,
      customerId
    )
      .then(async () => {
        updateBreadcrumbs(customerName);
        setUnsavedState(false);
        setSavingCustomer(false);
        showToast({
          id: 'customer-update-success',
          label: OTHER_ERROR.CUSTOMERS_UPDATE_SUCCESS,
          variant: 'default',
        });
      })
      .catch(err => {
        setCustomerStatus(customer.status);
        setSavingCustomer(false);
        // show error banner?
        showToast({
          id: 'customer-update-error',
          label: err.message || OTHER_ERROR.CUSTOMERS_UPDATE_ERROR,
          variant: 'error',
        });
      });
  };

  const updateStatus = status => {
    if (isV3API) {
      // updateCustomerStatus
      CSRController.updateCustomerStatus(
        status ? 'ACTIVE' : 'INACTIVE',
        customer.id
      )
        .then(resp => {
          if (resp.error) {
            showToast({
              id: 'customer-status-update-failure',
              label: resp.error.message || OTHER_ERROR.CUSTOMERS_UPDATE_SUCCESS,
              variant: 'error',
            });
          } else {
            showToast({
              id: 'customer-status-update-success',
              label: resp.message || OTHER_ERROR.CUSTOMERS_UPDATE_SUCCESS,
              variant: 'default',
            });
          }
        })
        .catch(() => {
          showToast({
            id: 'customer-status-update-error',
            label: OTHER_ERROR.CUSTOMERS_UPDATE_ERROR,
            variant: 'error',
          });
        });
    } else {
      updateCustomer(customer.name, customer.email, status);
    }
  };

  const primaryButtonProps = [];
  if (canReadAddress) {
    primaryButtonProps.push({
      text: 'Manage Addresses',
      variant: 'tertiary',
      onClick: () => navigate(`/customers/customers/${customerId}/addresses`),
    });
  }
  primaryButtonProps.push({
    text: 'Cancel',
    variant: 'secondary',
    onClick: () => navigate(`/customers/customers/`),
  });
  if (canEditCustomer) {
    primaryButtonProps.push({
      text: 'Save',
      dataTestid: 'save-customer',
      isDisabled: customerLoading || (!savingCustomer && !unsavedState),
      isLoading: savingCustomer,
      onClick: () => onSave(customerName, customerEmail),
    });
  }

  const fieldSkeleton = () => (
    <>
      <SkeletonFieldLabel />
      <Box margin={[2, 0, 0, 0]} />
      <SkeletonH3 />
    </>
  );

  const v2CustomerForm = customerLoading ? (
    fieldSkeleton()
  ) : (
    <Input
      inputProps={{
        value: customerName,
        onChange: e => {
          setNameError('');
          setUnsavedState(true);
          setCustomerName(e.target.value);
        },
        dataTestid: 'customer-name',
        disabled: !canEditCustomer,
      }}
      message={nameError}
      messageType={nameError ? 'error' : 'normal'}
      required
      label='Customer name'
    />
  );

  const v3CustomerForm = customerLoading ? (
    <>
      {fieldSkeleton()}
      {fieldSkeleton()}
      {fieldSkeleton()}
      {fieldSkeleton()}
      {fieldSkeleton()}
    </>
  ) : (
    <>
      <Input
        inputProps={{
          value: title,
          onChange: e => {
            setUnsavedState(true);
            setTitle(e.target.value);
          },
          id: 'v3-customer-tite',
          dataTestid: 'v3-customer-title',
          disabled: savingCustomer,
        }}
        label='Title'
      />
      <Box margin={{ top: 4 }} />
      <Input
        inputProps={{
          value: firstName,
          onChange: e => {
            setFirstNameError('');
            setUnsavedState(true);
            setFirstName(e.target.value);
          },
          id: 'v3-customer-tite',
          dataTestid: 'v3-customer-title',
          disabled: savingCustomer,
        }}
        required
        message={firstNameError}
        messageType={firstNameError ? 'error' : 'normal'}
        label='First name'
      />
      <Box margin={{ top: 4 }} />
      <Input
        inputProps={{
          value: middleName,
          onChange: e => {
            setUnsavedState(true);
            setMiddleName(e.target.value);
          },
          id: 'v3-customer-middle-name',
          dataTestid: 'v3-customer-middle-name',
          disabled: savingCustomer,
        }}
        label='Middle name'
      />
      <Box margin={{ top: 4 }} />
      <Input
        inputProps={{
          value: lastName,
          onChange: e => {
            setLastNameError('');
            setUnsavedState(true);
            setLastName(e.target.value);
          },
          id: 'v3-customer-last-name',
          dataTestid: 'v3-customer-last-name',
          disabled: savingCustomer,
        }}
        required
        message={lastNameError}
        messageType={lastNameError ? 'error' : 'normal'}
        label='Last name'
      />
      <Box margin={{ top: 4 }} />
      <Input
        inputProps={{
          value: suffix,
          onChange: e => {
            setUnsavedState(true);
            setSuffix(e.target.value);
          },
          id: 'v3-customer-suffix',
          dataTestid: 'v3-customer-suffix',
          disabled: savingCustomer,
        }}
        label='Suffix'
      />
    </>
  );

  const customerDetailsJSX = (
    <Box
      padding={[4, 4, 4, 4]}
      margin={{ top: 4 }}
      border={{ radius: 2, width: '1px', color: theme.color.grey[200] }}
    >
      {customerLoading ? (
        <>
          <SkeletonH5 />
          <Box margin={[2, 0, 0, 0]} />
          <SkeletonH3 />
        </>
      ) : (
        <StyledH5>Basic information</StyledH5>
      )}
      <Box margin={{ top: 4 }} />
      {isV3API ? v3CustomerForm : v2CustomerForm}
      <Box margin={{ top: 4 }} />
      {customerLoading ? (
        <>
          <SkeletonFieldLabel />
          <Box margin={[2, 0, 0, 0]} />
          <SkeletonH3 />
          <Box margin={[3, 0, 0, 0]} />
          <SkeletonFieldLabel />
          <Box margin={[2, 0, 0, 0]} />
          <SkeletonButton />
        </>
      ) : (
        <>
          <Input
            inputProps={{
              value: customerEmail,
              onChange: e => {
                setEmailError('');
                setUnsavedState(true);
                setCustomerEmail(e.target.value);
              },
              dataTestid: 'customer-email',
              disabled: !canEditCustomer,
            }}
            message={emailError}
            messageType={emailError ? 'error' : 'normal'}
            required
            label='Customer email'
          />
          {/* {isV3API && customer.externalId && (
            <>
              <Box margin={{ top: 4 }} />
              <Box typography={theme.typography.fieldLabel}>External Id</Box>
              <Box margin={[1, 0, 0, 0]} />
              <Box
                typography={theme.typography.body1.regular}
                color={theme.color.grey[1000]}
              >
                {customer.externalId}
              </Box>
            </>
          )} */}
          <Box margin={{ top: 4 }} />
          <Box typography={theme.typography.fieldLabel}>Created on</Box>
          <Box margin={[1, 0, 0, 0]} />
          <Box
            typography={theme.typography.body1.regular}
            color={theme.color.grey[1000]}
          >
            {dateFormatter(customer.createdAt, 'MMM dd, h:mm a')}
          </Box>
        </>
      )}
    </Box>
  );

  const addressJSX = addresses
    .filter(a => !a.isDeleted)
    .map(address => (
      <AddressPreview
        key={address._id || address.id}
        {...(isV3API ? address.address : address)}
      />
    ));

  const loadingHeader = () => (
    <Box flex={{ justifyContent: 'space-between' }}>
      <Box>
        <SkeletonH1 />
      </Box>
      <Box flex gap={2}>
        <SkeletonButton />
        <SkeletonButton />
      </Box>
    </Box>
  );

  return (
    <>
      {customerLoading ? (
        loadingHeader()
      ) : (
        <PageHeader
          h1Text={customerName}
          h2Text={
            customer.externalId && (
              <Box
                flex={{ alignItems: 'centre' }}
                typography={theme.typography.body1.regular}
              >
                {customer.externalId}
                &nbsp;
                <InteractiveIcon
                  color='black'
                  iconName='Copy'
                  size={12}
                  onClick={() =>
                    navigator.clipboard.writeText(customer.externalId)
                  }
                />
              </Box>
            )
          }
          primaryButtons={primaryButtonProps}
          switchProps={{
            label: '',
            dataTestid: 'customer-active-switch',
            stateLabels: {
              ON: 'Active',
              OFF: 'Inactive',
            },
            disabled: !canEditCustomer,
            variant: 'small',
            checked: customerStatus,
            onChange: () => {
              setCustomerStatus(!customerStatus);
              updateStatus(!customerStatus);
            },
          }}
          ternaryDescription={`Last updated ${updatedAt}`}
        />
      )}
      <GridRow padding={0}>
        {!customerLoading && addresses.filter(a => !a.isDeleted).length > 0 ? (
          <>
            <GridCol sm={9}>{customerDetailsJSX}</GridCol>
            <GridCol sm={3}>{addressJSX}</GridCol>
          </>
        ) : (
          <GridCol>{customerDetailsJSX}</GridCol>
        )}
      </GridRow>
    </>
  );
};

export default CustomerDetail;

const AddressPreview = ({
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  city,
  state,
  country,
  zipCode,
  type,
}) => {
  return (
    <Box
      padding={[4, 4, 4, 4]}
      margin={{ top: 4 }}
      border={{ radius: 2, width: '1px', color: theme.color.grey[200] }}
    >
      <StyledH5>
        {type === 'M' || type === 'SHIPPING' ? 'Shipping' : 'Billing'} address
      </StyledH5>
      <Box
        margin={{ top: 4, bottom: 4 }}
        border={{ sides: ['top'], width: '1px', color: theme.color.grey[200] }}
      />
      <Box
        typography={theme.typography.body1.regular}
        color={theme.color.grey[1000]}
      >
        {[addressLine1, addressLine2, addressLine3, addressLine4]
          .filter(text => !!text)
          .join(', ')}
      </Box>
      <Box
        typography={theme.typography.body2.regular}
        color={theme.color.grey[700]}
      >
        {[city, state, country, zipCode].filter(text => !!text).join(', ')}
      </Box>
    </Box>
  );
};
