import React, { useState, useEffect } from 'react';
import SideNavigation from 'components/navigation';
import GlobalStyles from 'lib/theme/globals';
import { isEnabledEnvironment } from 'src/hooks/hasPermission';
import { GITLAB_PROJECT_ID } from '../../../general-config';
import { FeatureFlagController } from '@copilot/mfa-communication';
const Layout = ({ children }) => {
  const [ds4, setDs4] = useState(false);

  useEffect(() => {
    FeatureFlagController.getFeatureFlag({
      projectId: GITLAB_PROJECT_ID,
      featureFlagName: 'ds4-customers',
    }).then(flag => {
      if (isEnabledEnvironment(flag)) {
        setDs4(true);
      }
    });
  }, []);

  return (
    <div className='app_root'>
      <GlobalStyles />
      <main className='d-flex w-100'>
        {!ds4 && <SideNavigation />}
        <div className='app_children'>{children}</div>
      </main>
    </div>
  );
};

export default Layout;
