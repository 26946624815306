import React from 'react'
import { StyledSettingsPage } from './styles'

const SettingsPage = () => {
  return (
    <StyledSettingsPage>
      <h1>Settings Page</h1>
    </StyledSettingsPage>
  )
}

export default SettingsPage
