import React from 'react';
import { EmptyPage } from 'ds4-beta';
import { hasPermission } from 'src/hooks/hasPermission';
import { CUSTOMER_PERMISSIONS, PERMISSION_ERROR } from 'src/lib/data/constants';
import CustomerAddresses from './customerAddresses';

const CustomerAddressPage = props => {
  const canReadAddress = hasPermission([
    CUSTOMER_PERMISSIONS.CUST_ADDRESS_READ,
  ]);

  if (!canReadAddress) {
    return (
      <EmptyPage
        headerText={PERMISSION_ERROR.MAIN_ERROR}
        description={PERMISSION_ERROR.MAIN_ERROR_SECONDARY}
      />
    );
  }

  return <CustomerAddresses {...props} />;
};

export default CustomerAddressPage;
