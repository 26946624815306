import React from 'react';

import { ThemeProvider } from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';
import { Router } from '@reach/router';
import initiateStore from 'store';
import { Provider } from 'react-redux';
import Layout from 'components/layout';
import CustomersPage from 'modules/Customers';
import CustomerDetailsPage from 'modules/CustomerDetails';
import SegmentationPage from 'modules/Segmentation';
import PerformancePage from 'modules/Performance';
import SettingsPage from 'modules/Settings';
import ContractsPage from 'modules/Contracts';
import OrganizationsPage from 'src/modules/Organizations';
import OrganizationDetailsPage from 'src/modules/OrganizationDetails';
import OrganizationDetailsTab from 'src/modules/OrganizationDetails/Details';
import OrganizationDetailsUsersTab from 'src/modules/OrganizationDetails/Users';
import OrganizationDetailsCreateUser from 'modules/OrganizationDetails/Users/CreateUser';
import OrganizationDetailsEditUser from 'modules/OrganizationDetails/Users/EditUser';
import OrganizationUserDetails from 'src/modules/OrganizationDetails/Users/UserDetails';
import OrganizationGroupUserDetails from 'src/modules/OrganizationDetails/Groups/UsersDetails';
import OrganizationDetailsGroupsTab from 'src/modules/OrganizationDetails/Groups';
import Toasts from 'modules/Toasts';
import 'lib/theme/fonts.css';
import PropTypes from 'prop-types';
import Address from 'src/modules/Organizations/Address';
import Group from 'src/modules/Organizations/Group';
import { FormMode } from 'src/@types';
import { GROUP_VIEW } from 'src/modules/Organizations/constants';
import CustomerAddresses from 'modules/CustomerAddresses';
import AddCustomer from 'modules/Customers/addCustomer/index';
import EditSegment from 'modules/Segmentation/editSegment';
import EditCustomer from 'modules/CustomerDetails/editCustomer';

const getInitialState = (CLIENT, STAGE, NODE_ENV) => {
  return {
    client: CLIENT,
    stage: STAGE,
    node_env: NODE_ENV,
    flags: {
      customers: true,
      segmentation: true,
      performance: true,
      settings: true,
      version: '1.0',
    },
  };
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  render() {
    const envParams = getInitialState(this.props.pageProps);
    const store = initiateStore(envParams);
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router className='reach-router'>
            <Layout path='/'>
              {envParams.flags.customers && (
                <CustomersPage path='/customer/customers' />
              )}
              {envParams.flags.customers && (
                <CustomerDetailsPage path='/customer/customers/:customerId' />
              )}
              {envParams.flags.customers && (
                <AddCustomer path='/customer/customers/new' />
              )}
              {envParams.flags.customers && (
                <EditCustomer path='/customer/customers/edit' />
              )}
              {envParams.flags.customers && (
                <CustomerAddresses path='/customer/customers/:customerId/addresses' />
              )}
              {envParams.flags.segmentation && (
                <SegmentationPage path='/customer/segments' />
              )}
              {envParams.flags.segmentation && (
                <EditSegment path='/customer/segments/:segmentId' />
              )}
              {envParams.flags.performance && (
                <PerformancePage path='/customer/performance' />
              )}
              {envParams.flags.settings && (
                <SettingsPage path='/customer/settings' />
              )}
              <ContractsPage path='/customer/contracts/*' />
              <OrganizationsPage path='/customer/organizations' />

              <OrganizationDetailsPage path='/customer/organizations/:organizationId'>
                <Address path='/addresses' />
                <OrganizationDetailsUsersTab path='/users' />
                <OrganizationDetailsGroupsTab path='/groups' />
                <OrganizationDetailsTab path='/' />
              </OrganizationDetailsPage>
              <OrganizationGroupUserDetails path='/customer/organizations/:organizationId/group/:groupId/users/:userId' />
              <OrganizationUserDetails path='/customer/organizations/:organizationId/users/user-detail/:userId' />
              <OrganizationDetailsCreateUser path='/customer/organizations/:organizationId/users/add' />
              <OrganizationDetailsEditUser path='/customer/organizations/:organizationId/users/:userId/edit' />
              <Group path={GROUP_VIEW} />
              <Address
                path='/customer/organizations/:organizationId/addresses/add'
                formType={FormMode.ADD}
              />
              <Address
                path='/customer/organizations/:organizationId/addresses/:addressid/edit'
                formType={FormMode.EDIT}
              />
              <Address
                path='/customer/organizations/:organizationId/group/:groupId/addresses/:addressid/view'
                formType={FormMode.VIEW}
              />
            </Layout>
          </Router>
          <Toasts />
        </ThemeProvider>
      </Provider>
    );
  }
}

const { NODE_ENV, STAGE, CLIENT } = process.env;

App.defaultProps = {
  pageProps: { NODE_ENV, STAGE, CLIENT },
};

App.propTypes = {
  pageProps: PropTypes.object,
};
export default App;
