// eslint-disable-next-line import/no-unresolved
import { CSRController } from '@copilot/mfa-communication';

export const ACTIONS = {
  GET_CUSTOMER_ADDRESSES: 'get_customer_addresses',
  RESET_CUSTOMER_ADDRESSES: 'reset_customer_addresses',
  UPDATE_CUSTOMER_ADDRESS: 'update_customer_address',
  SET_DEFAULT_CUSTOMER_ADDRESS: 'set_default_customer_address',
  ADD_TO_CUSTOMER_ADDRESSES: 'add_to_customer_addresses',
  LOADING_CUSTOMER_ADDRESSES: 'loading_customer_addresses',
  SUBMIT_CUSTOMER_ADDRESSES: 'submit_customer_addresses',
  SAVE_CUSTOMER_ADDRESSES: 'save_customer_addresses',
  SAVING_CUSTOMER_ADDRESSES: 'saving_customer_address',
  CUSTOMER_ADDRESSES_SAVED: 'customer_address_saved',
  CLOSE_NOTIFICATION: 'close_notification',
};

export const resetCustomerAddresses = () => async dispatch => {
  dispatch({ type: ACTIONS.RESET_CUSTOMER_ADDRESSES });
};

export const getCustomerAddresses =
  (customerId, useV3 = false) =>
  async dispatch => {
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: true });
    try {
      const { data: addresses = [] } =
        await CSRController.listCustomerAddresses(
          customerId,
          {
            offset: 0,
            limit: 1000,
          },
          useV3
        );
      dispatch({ type: ACTIONS.GET_CUSTOMER_ADDRESSES, payload: addresses });
      dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
      return addresses;
    } catch (err) {
      dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
      throw err;
    }
  };

export const addToCustomerAddresses = customerAddress => async dispatch => {
  dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: true });
  try {
    dispatch({
      type: ACTIONS.ADD_TO_CUSTOMER_ADDRESSES,
      payload: customerAddress,
    });
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
  } catch (err) {
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
    throw err;
  }
};

export const updateCustomerAddress = customerAddress => async dispatch => {
  dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: true });
  try {
    dispatch({
      type: ACTIONS.UPDATE_CUSTOMER_ADDRESS,
      payload: customerAddress,
    });
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
  } catch (err) {
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
    throw err;
  }
};

export const setDefaultCustomerAddress = customerAddress => async dispatch => {
  dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: true });
  try {
    dispatch({
      type: ACTIONS.SET_DEFAULT_CUSTOMER_ADDRESS,
      payload: { ...customerAddress, isDefault: true },
    });
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
  } catch (err) {
    dispatch({ type: ACTIONS.LOADING_CUSTOMER_ADDRESSES, payload: false });
    throw err;
  }
};

export const submitCustomerAddresses =
  (customerId, addresses, useV3 = false) =>
  async dispatch => {
    try {
      dispatch({ type: ACTIONS.SAVING_CUSTOMER_ADDRESSES });
      await Promise.all(
        addresses.map(async ({ errors, ...customerAddress }) => {
          // dispose error object
          if (useV3 && !customerAddress.address.type) {
            customerAddress.address.type =
              customerAddress.type === 'M' ? 'SHIPPING' : 'BILLING';
          }
          if (customerAddress.isNew) {
            if (!customerAddress.isDeleted) {
              useV3
                ? await CSRController.createCustomerAddressV3(customerId, {
                    ...customerAddress,
                    _id: undefined,
                    id: undefined,
                  })
                : await CSRController.createCustomerAddress(customerId, {
                    ...customerAddress,
                    _id: undefined,
                  });
            }
          } else {
            useV3
              ? await CSRController.updateCustomerAddressV3(
                  customerId,
                  customerAddress
                )
              : await CSRController.updateCustomerAddress(
                  customerId,
                  customerAddress
                );
          }
        })
      );
      dispatch({ type: ACTIONS.CUSTOMER_ADDRESSES_SAVED });
      // refetching all list after save/update
      // TODO: can do better!!!
      dispatch(getCustomerAddresses(customerId, useV3));
      setTimeout(() => {
        dispatch({ type: ACTIONS.CLOSE_NOTIFICATION });
      }, 3000);
    } catch (err) {
      dispatch({ type: ACTIONS.CUSTOMER_ADDRESSES_SAVED });
      throw err;
    }
  };
