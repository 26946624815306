"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOrganization = exports.editOrganizationData = exports.viewOrganizationData = exports.setOrganizationFormStatus = exports.setDetailsFormMode = exports.setToast = void 0;
// eslint-disable-next-line import/no-unresolved
var mfa_communication_1 = require("@copilot/mfa-communication");
var _types_1 = require("src/@types");
var router_1 = require("@reach/router");
var setToast = function (dispatch, toastKind, message) {
    dispatch({
        type: _types_1.OrganizationDetailsActionTypes.SET_ORGANIZATION_TOAST,
        payload: {
            toastKind: toastKind,
            toastMsg: message,
        },
    });
};
exports.setToast = setToast;
var setDetailsFormMode = function (dispatch, formMode) {
    dispatch({
        type: _types_1.OrganizationDetailsActionTypes.SET_VIEW_ORGANIZATION_DETAILS_FORM_MODE,
        payload: formMode,
    });
};
exports.setDetailsFormMode = setDetailsFormMode;
var setOrganizationFormStatus = function (dispatch, formStatus) {
    dispatch({
        type: _types_1.OrganizationDetailsActionTypes.SET_ORGANIZATION_FORM_STATUS,
        payload: formStatus,
    });
};
exports.setOrganizationFormStatus = setOrganizationFormStatus;
var handleOrganizationViewResponse = function (dispatch, response) {
    if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        (0, exports.setToast)(dispatch, 'alert', 'Error loading organization details.');
    }
    else {
        dispatch({
            type: _types_1.OrganizationDetailsActionTypes.VIEW_ORGANIZATION,
            payload: { data: response },
        });
    }
};
var viewOrganizationData = function (dispatch, props) {
    dispatch({
        type: _types_1.OrganizationDetailsActionTypes.SET_VIEW_ORGANIZATION_LOADING_STATUS,
        payload: true,
    });
    mfa_communication_1.CSRController.viewOrganization(props)
        .then(function (response) {
        handleOrganizationViewResponse(dispatch, response);
    })
        .catch(function (e) {
        console.error(e);
        handleOrganizationViewResponse(dispatch, {
            error: { message: '', statusCode: 400 },
        });
    });
};
exports.viewOrganizationData = viewOrganizationData;
var editOrganizationData = function (dispatch, props) {
    dispatch({
        type: _types_1.OrganizationDetailsActionTypes.SET_VIEW_ORGANIZATION_LOADING_STATUS,
        payload: true,
    });
    mfa_communication_1.CSRController.updateOrganization(props.data, props.organizationId)
        .then(function (response) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
            throw new Error('Organization details edit was unsuccessful');
        }
        else {
            (0, exports.setToast)(dispatch, 'success', 'Organization details saved successfully');
            (0, exports.setDetailsFormMode)(dispatch, "VIEW" /* VIEW */);
            dispatch({
                type: _types_1.OrganizationDetailsActionTypes.VIEW_ORGANIZATION,
                payload: { data: response },
            });
        }
    })
        .catch(function (e) {
        console.error(e);
        (0, exports.setToast)(dispatch, 'alert', 'Organization details edit was unsuccessful');
    });
};
exports.editOrganizationData = editOrganizationData;
var createOrganization = function (dispatch, formPayLoad) {
    dispatch({
        type: _types_1.OrganizationDetailsActionTypes.SET_VIEW_ORGANIZATION_LOADING_STATUS,
        payload: true,
    });
    mfa_communication_1.CSRController.createOrganization(formPayLoad)
        .then(function (response) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
            throw new Error('Organization save was unsuccessful');
        }
        else {
            (0, exports.setToast)(dispatch, 'success', 'Organization added successfully');
            (0, exports.setDetailsFormMode)(dispatch, "VIEW" /* VIEW */);
            dispatch({
                type: _types_1.OrganizationDetailsActionTypes.VIEW_ORGANIZATION,
                payload: { data: response },
            });
            (0, router_1.navigate)("/customer/organizations/".concat(response._id));
        }
    })
        .catch(function (err) {
        console.error(err);
        (0, exports.setToast)(dispatch, 'alert', 'Organization save was unsuccessful');
    });
};
exports.createOrganization = createOrganization;
