import React, { useEffect, useState, useCallback } from 'react';
import { navigate } from '@reach/router';
import {
  Badge,
  Box,
  Filter,
  FilterSideBarVariant,
  FilterSidebar,
  InteractiveIcon,
  Link,
  PageHeader,
  Search,
  Table,
  Tag,
  Tooltip,
  theme,
} from 'ds4-beta';
import { debounce } from 'lodash';
import { hasPermission } from 'src/hooks/hasPermission';
import {
  COMMON_BREADCRUMB,
  CUSTOMER_PERMISSIONS,
  DEFAULT_STATUSES,
} from 'src/lib/data/constants';
import { PAGINATION } from 'src/modules/CustomerDetails/constants';
// eslint-disable-next-line import/no-unresolved
import { CSRController } from '@copilot/mfa-communication';
import { Caption, StyledEdit, EllipsisContainer, LastColumn } from './styles';
import { dateFormatter } from 'src/lib/utils/date';
import { getNameString } from 'src/lib/utils/helpers';

const CustomerPageCDP = ({ isV3API }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    sortBy: 'updatedAt',
    sortOrder: 'desc',
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [allStatuses, setAllStatuses] = useState(
    DEFAULT_STATUSES.map(({ id, name: label, value }) => {
      return { id, label, value, selected: false };
    })
  );
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tableCaption, setTableCaption] = useState('');
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const canAddCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_CREATE]);

  const getId = ({ id, _id }) => (isV3API ? id : _id);
  const getName = ({ name }) => {
    if (!isV3API) return name;
    return getNameString({ name });
  };

  const getEditAction = customerId => (
    <StyledEdit className='show-on-row-hover'>
      <Tooltip
        id='tooltip-edit'
        placement='top'
        text='Edit'
        theme='dark'
        showArrow
      >
        <InteractiveIcon
          iconName='Pencil'
          onClick={() => navigate(`/customers/customers/${customerId}`)}
        />
      </Tooltip>
    </StyledEdit>
  );

  const customerNameRenderer = props => (
    <EllipsisContainer>
      <Link
        label={getName(props.value)}
        onClick={() => navigate(`/customers/customers/${getId(props.value)}`)}
        size='medium'
        mode='inline'
      />
      {/* <Box
        typography={theme.typography.fieldLabel}
        color={theme.color.grey[500]}
        margin={{ left: 1 }}
      >
        {props.value[isV3API ? 'emailAddress' : 'email']}
      </Box> */}
    </EllipsisContainer>
  );

  const emailRenderer = props =>
    props.value ? (
      <EllipsisContainer>
        {/* <Link label={props.value} href={`mailto: ${props.value}`} /> */}
        <Box
          typography={theme.typography.body1.regular}
          color={theme.color.grey[1000]}
        >
          {props.value}
        </Box>
      </EllipsisContainer>
    ) : null;

  const addressRenderer = ({ value: addresses = [] }) => {
    const addressToUse = addresses.find(a => !a.isDeleted && a.type === 'M');
    if (!addressToUse) {
      return null;
    }
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      city,
      state,
      country,
      zipCode,
    } = addressToUse;
    let line1 = [];
    [addressLine1, addressLine2, addressLine3, addressLine4].forEach(
      addressLine => {
        if (addressLine) {
          line1.push(addressLine);
        }
      }
    );
    line1 = line1.join(', ');
    let line2 = [];
    [city, state, country, zipCode].forEach(addressLine => {
      if (addressLine) {
        line2.push(addressLine);
      }
    });
    line2 = line2.join(', ');
    return (
      <EllipsisContainer>
        <Box
          typography={theme.typography.body1.regular}
          color={theme.color.grey[1000]}
        >
          {line1}
        </Box>
        <Box
          typography={theme.typography.body2.regular}
          color={theme.color.grey[700]}
        >
          {line2}
        </Box>
      </EllipsisContainer>
    );
  };

  const externalIdRendered = props => (
    <EllipsisContainer>
      <Box
        typography={theme.typography.body1.regular}
        color={theme.color.grey[1000]}
      >
        {props.value}
      </Box>
    </EllipsisContainer>
  );

  const statusRenderer = props => (
    <Badge
      label={props.value ? 'Active' : 'Inactive'}
      variant='primary'
      status={props.value ? 'success' : 'default'}
      asset='none'
    />
  );

  const tagRenderer = ({ value }) => {
    const traits =
      value.traits.length > 4 ? value.traits.slice(0, 3) : value.traits;
    const tags = (
      <Box flex={'flex'} gap={1}>
        {traits.map(trait => (
          <Tag key={trait._id} id={trait._id} primaryLabel={trait.name} />
        ))}
        {value.traits.length > 4 && (
          <Tag id='extratag' primaryLabel={`+${value.traits.length - 3}`} />
        )}
      </Box>
    );
    const tagsJSX =
      value.traits.length > 4 ? (
        <Tooltip
          placement='rightStart'
          text={value.traits.map(t => t.name).join(', ')}
          theme='dark'
          showArrow
        >
          {tags}
        </Tooltip>
      ) : (
        tags
      );
    return (
      <Box flex={{ justifyContent: 'space-between' }}>
        {tagsJSX}
        {getEditAction(value.id)}
      </Box>
    );
  };

  const lastColumnRenderer = ({ value, id }) => (
    <LastColumn flex={{ justifyContent: 'space-between' }}>
      {dateFormatter(value, 'MMM dd, h:mm a')}
      {getEditAction(id)}
    </LastColumn>
  );

  const getCustomersData = useCallback(
    async page => {
      const offset = page > 0 ? (page - 1) * PAGINATION : 0;
      const filters = {};
      const filtersV3 = {
        isDeleted: false,
      };
      if (selectedStatuses.length === 1) {
        filters['isActive'] = selectedStatuses.indexOf('Active') !== -1;
        filtersV3.status = {
          op: 'IN',
          value: filters.isActive ? 'ACTIVE' : 'INACTIVE',
        };
      }
      if (selectedTags.length) {
        filters['traitName'] = selectedTags.join(',');
      }
      if (customerSearchTerm.length) {
        filters['name'] = customerSearchTerm;
        const _value = {
          op: 'CONTAINS',
          value: customerSearchTerm,
        };
        filtersV3.text = _value;
      }

      const apiParams = isV3API
        ? {
            sort: '-updatedAt',
            match: filtersV3,
            offset,
            limit: PAGINATION,
          }
        : {
            offset,
            limit: PAGINATION,
            ...filters,
            sortBy: pagination.sortBy,
            sortOrder: pagination.sortOrder,
          };

      try {
        setLoading(true);
        // setCustomerData([]);
        //execute network call
        const { data: customers, query } = await CSRController[
          isV3API ? 'searchCustomer' : 'listCustomers'
        ](apiParams);

        setTotalRecords(query.count);
        setPagination({
          ...pagination,
          page: parseInt(query.offset / PAGINATION) + 1,
        });
        setTableCaption(
          `Showing ${query.offset + 1}-${query.offset + customers.length} of ${
            query.count
          }`
        );

        setCustomerData(
          customers.map(customer => {
            return {
              id: getId(customer),
              data: {
                'Customer Name': {
                  value: customer,
                  onRender: customerNameRenderer,
                },
                'Email': {
                  value: customer[isV3API ? 'emailAddress' : 'email'],
                  onRender: emailRenderer,
                },
                'Default Shipping Address': {
                  value: customer.defaultAddress,
                  onRender: addressRenderer,
                },
                'Status': {
                  value: isV3API
                    ? customer.status === 'ACTIVE'
                    : customer.isActive,
                  onRender: statusRenderer,
                },
                'Tags': {
                  value: { traits: customer.traits || [], id: customer._id },
                  onRender: tagRenderer,
                },
                'External Id': {
                  value: customer.externalId,
                  onRender: externalIdRendered,
                },
                'Created At': {
                  value: customer.createdAt,
                  onRender: date => dateFormatter(date.value, 'MMM dd, h:mm a'),
                },
                'Updated At': {
                  value: customer.updatedAt,
                  id: customer.id,
                  onRender: lastColumnRenderer,
                },
              },
            };
          })
        );
      } catch (err) {
        setCustomerData([]);
      }

      setLoading(false);
    },
    [selectedStatuses, selectedTags, customerSearchTerm, pagination]
  );

  /**
   * Underlying function gets lastest tags
   */
  let getTags = async () => {
    const { data } = await CSRController.listTraits({ offset: 0, limit: 1000 });
    setAllTags(
      data
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ _id: id, name: label }) => {
          return {
            id,
            label,
            value: label,
          };
        })
    );
  };

  const debouncedSearch = useCallback(
    debounce(setCustomerSearchTerm, 1000, {
      maxWait: 60000,
      leading: false,
      trailing: true,
    }),
    [setCustomerSearchTerm]
  );

  useEffect(() => {
    getCustomersData(pagination.page);
  }, [customerSearchTerm, selectedStatuses, selectedTags]);

  useEffect(() => {
    const _statuses = [];
    allStatuses.forEach(_status => {
      if (_status.selected) {
        _statuses.push(_status.label);
      }
    });
    const _tags = [];
    allTags.forEach(_tag => {
      if (_tag.selected) {
        _tags.push(_tag.label);
      }
    });
    // avoid unnecessary state update
    if (_tags.length || selectedTags.length) {
      setSelectedTags(_tags);
    }
    if (_statuses.length || selectedStatuses.length) {
      setSelectedStatuses(_statuses);
    }
  }, [allStatuses, allTags]);

  useEffect(() => {
    if (!isV3API) {
      getTags();
    }
    window.updateBreadcrumb(COMMON_BREADCRUMB);
  }, []);

  const updateTags = id => {
    const newTags = allTags.map(tag => {
      if (tag.id === id) {
        tag.selected = !tag.selected;
      }
      return tag;
    });
    setAllTags(newTags);
  };

  const updateStatus = id => {
    const newStatuses = allStatuses.map(status => {
      if (status.id === id) {
        status.selected = !status.selected;
      }
      return status;
    });
    setAllStatuses(newStatuses);
  };

  const removeTag = (type, id) => {
    type === 'status' ? updateStatus(id) : updateTags(id);
  };

  const resetAllFilters = () => {
    const _statuses = allStatuses.map(_status => {
      _status.selected = false;
      return _status;
    });
    const _tags = allTags.map(_tag => {
      _tag.selected = false;
      return _tag;
    });
    setAllTags(_tags);
    setAllStatuses(_statuses);
    // setCustomerSearchTerm('');
  };

  return (
    <>
      <PageHeader
        h1Text='Manage Customers'
        primaryButtons={
          canAddCustomer
            ? [
                {
                  text: 'Add Customer',
                  icon: 'Add',
                  onClick: () => {
                    navigate('/customers/customers/new');
                  },
                },
              ]
            : null
        }
      />
      <Box margin={[2, 0, 0, 0]} />
      <Search
        placeholder='Search by customer name'
        onSearch={value => {
          setPagination({
            ...pagination,
            page: 1,
          });
          setCustomerSearchTerm(value);
        }}
        onChange={t => debouncedSearch(t)}
        onClear={() => setCustomerSearchTerm('')}
        showResult={false}
        variant='page'
        dataTestid='search-customer'
      />
      <Box flex={{ justifyContent: 'space-between' }}>
        <Box>
          <Box
            flex={'flex'}
            gap={1}
            margin={{
              top: 3,
              bottom: selectedStatuses.length + selectedTags.length > 0 ? 2 : 4,
            }}
          >
            <Filter
              label='Status'
              variant='primary'
              dataTestid='status-filter'
              filterCount={
                selectedStatuses.length
                  ? selectedStatuses.length + ''
                  : undefined
              }
              renderResults={
                <Box margin={[2, 3, 3, 3]}>
                  <FilterSidebar
                    variant={FilterSideBarVariant.SECONDARY}
                    checkboxTree={allStatuses}
                    onChange={updateStatus}
                    padding={false}
                  />
                </Box>
              }
            />
            {!isV3API && (
              <Filter
                label='Tags'
                variant='primary'
                dataTestid='tag-filter'
                filterCount={
                  selectedTags.length ? selectedTags.length + '' : undefined
                }
                renderResults={
                  <Box margin={[2, 3, 3, 3]}>
                    <FilterSidebar
                      header={
                        loading
                          ? 'loading...'
                          : allTags.length == 0
                          ? 'No tags available'
                          : ''
                      }
                      variant={FilterSideBarVariant.SECONDARY}
                      checkboxTree={allTags}
                      onChange={updateTags}
                      padding={false}
                    />
                  </Box>
                }
              />
            )}
            {selectedStatuses.length + selectedTags.length > 0 && (
              <Link
                dataTestid='reset-all-filters'
                label='Reset filters'
                onClick={resetAllFilters}
              />
            )}
          </Box>
          <Box
            flex
            gap={1}
            margin={{
              bottom: selectedStatuses.length + selectedTags.length > 0 ? 4 : 0,
            }}
          >
            {allStatuses
              .filter(s => s.selected)
              .map(status => (
                <Tag
                  id={`status-${status.id}`}
                  key={`status-${status.id}`}
                  isRemovable
                  onRemove={() => removeTag('status', status.id)}
                  primaryLabel={`Status: ${status.label}`}
                />
              ))}
            {allTags
              .filter(t => t.selected)
              .map(tag => (
                <Tag
                  id={`tag-${tag.id}`}
                  key={`tag-${tag.id}`}
                  isRemovable
                  onRemove={() => removeTag('tag', tag.id)}
                  primaryLabel={`Tag: ${tag.label}`}
                />
              ))}
          </Box>
        </Box>
        <Box flex={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Caption>{tableCaption}</Caption>
        </Box>
      </Box>
      <Table
        loading={loading}
        columns={[
          { title: 'Customer Name', width: 250 },
          ...(isV3API
            ? []
            : [{ title: 'Default Shipping Address', width: 300 }]),
          ...(isV3API ? [{ title: 'Email', width: 200 }] : []),
          { title: 'Status', width: 100 },
          ...(isV3API ? [] : [{ title: 'Tags', width: 200 }]),
          ...(isV3API ? [{ title: 'External Id', width: 200 }] : []),
          // ...(isV3API ? [{ title: 'Created At', width: 200 }] : []),
          ...(isV3API ? [{ title: 'Updated At', width: 300 }] : []),
        ]}
        data={customerData}
        showPagination
        customPaginationProps={{
          totalRecords,
          perPage: PAGINATION,
          activePageNumber: pagination.page,
          handlePagination: getCustomersData,
        }}
        dataTestidPrefix='customer'
      />
    </>
  );
};

export default CustomerPageCDP;
