import React from 'react'
import ButtonWithDropdown from '../buttonWithDropdown'

const Date = ({
  dateArray,
  dateHandler,
  SpecificDate,
  dateType,
  displayDateType
}) => {
  return (
    <ButtonWithDropdown
      data={[
        {
          type: 'radio',
          data: dateArray,
          onClickHandler: e => dateHandler(e),
          dateType,
          SpecificDate
        }
      ]}
      label={`Date: ${displayDateType}`}
      btnIcon={'DownArrow'}
      iconPosition='right'
    />
  )
}

export default Date
