import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledCustomerDetailsPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
  background: ${theme.palette.brand.primary.white};
  border-radius: 4px;
`;

export const StyledCustomerDetailsBody = styled.div`
  display: flex;
  gap: 16px;
  .right {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledCustomerSnapshotCard = styled.div`
  padding: 30px;
  margin: 15px;
  border: 1px solid #e3e5e9;
  border-radius: 4px;

  .title {
    ${theme.typography.body}
    padding-bottom: 31px;
  }

  .body {
    display: flex;
    flex-direction: row;
  }

  .item {
    flex-grow: 0;
    padding-right: 16px;
  }

  .item-image {
    max-width: 40px;
    padding-bottom: 15px;
  }

  .item-info {
    ${theme.typography.h5}
    color: ${theme.palette.brand.primary.charcoal};
    font-family: Gilroy-Medium;
    font-size: 16px;
    .item-id,
    .item-name {
      font-family: Gilroy-Medium;
    }
  }

  .stats {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;

    .value {
      font-family: Gilroy-Medium;
      font-size: 16px;
      word-wrap: break-word;
      padding: 3px;
    }
  }

  .subtitle {
    ${theme.typography.h6}
    color: ${theme.palette.brand.primary.gray};
    padding-bottom: 8px;
  }

  .value {
    ${theme.typography.subtitle3}
    color: ${theme.palette.brand.primary.charcoal};
  }
`;

export const StyledCustomerInfoCard = styled.div`
  padding: 32px;
  background: ${theme.palette.brand.primary.white};
  border: 1px solid ${theme.palette.ui.neutral.grey3};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    ${theme.typography.body}
  }

  .attribute-count {
    ${theme.typography.h6}
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card-col {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .card-col-title {
    ${theme.typography.h6}
    color: ${theme.palette.brand.primary.gray};
  }

  .card-col-body {
    ${theme.typography.h6}
    color: ${theme.palette.brand.primary.charcoal};
    font-family: Gilroy-Medium;
    line-height: 16px;
    word-wrap: break-word;
  }
`;

export const StyleCustomerOrdersTableCard = styled.div`
  background: ${theme.palette.brand.primary.white};
  border: 1px solid ${theme.palette.ui.neutral.grey3};
  border-radius: 4px;
  margin: 15px;
  table:before {
    margin-left: 30px;
  }
  .title {
    ${theme.typography.body}
    padding: 30px 30px;
  }
`;

export const StyledCustomerDetailsHeader = styled.div`
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 60px;

  .header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .header-buttons {
      display: flex;
      gap: 16px;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    ${theme.typography.subtitle3}
  }

  .status {
    margin: 16px 0px;
  }

  .last-updated {
    font-family: Gilroy-Medium;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.26px;
    color: ${theme.palette.brand.primary.gray};
  }
`;

export const StyledShippingCard = styled.div`
  background: ${theme.palette.brand.primary.white};
  border: 1px solid ${theme.palette.ui.neutral.grey3};
  border-radius: 4px;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 40px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    ${theme.typography.body}
  }

  .row {
    ${theme.typography.h6}
  }

  .label {
    color: ${theme.palette.brand.primary.gray};
  }
  .value {
    color: ${theme.palette.brand.primary.charcoal};
  }
`;
export const StyledTagsCard = styled.div`
  div:hover {
    background: ${theme.palette.brand.primary.white};
  }
  min-width: 300px;
  max-width: 465px;
  .btn-chip {
    font-size: 13px;
    line-height: 15px;
    background: #64707d10;
    color: #121213;
    padding: 8px 16px;
    border-radius: 20px;
    white-space: nowrap;
    border: 1px solid transparent;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
  }
  .delete-icon {
    margin-left: 10px;
  }
`;

export const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
