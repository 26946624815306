import React from 'react';
import { Redirect, Router } from '@reach/router';
import initiateStore from 'store';
import { Provider } from 'react-redux';
import Layout from 'src/ds4pages/components/layout';
import CustomerPage from 'src/ds4pages/modules/Customers';
import AddCustomer from 'src/ds4pages/modules/Customers/add';
import { default as CustomerDetailsPage } from 'src/ds4pages/modules/Customers/detail';
import CustomerAddressPage from 'src/ds4pages/modules/Customers/address';
import { V3_API_FEATURE_FLAG } from 'src/lib/data/constants';
import { useFlag } from '@unleash/proxy-client-react';

const getInitialState = (CLIENT, STAGE, NODE_ENV) => {
  return {
    client: CLIENT,
    stage: STAGE,
    node_env: NODE_ENV,
    flags: {
      customers: true,
      version: '1.0',
    },
  };
};

const App = ({ pageProps = [] }) => {
  // const [error, setError] = useState('');
  const envParams = getInitialState(pageProps);
  const store = initiateStore(envParams);
  const isV3API = useFlag(V3_API_FEATURE_FLAG);

  return (
    <Provider store={store}>
      <Router className='reach-router'>
        <Layout path='/'>
          <Redirect from='/customer' to='/customers/customers' noThrow />
          <Redirect
            from='/customer/customers'
            to='/customers/customers'
            noThrow
          />
          {envParams.flags.customers && (
            <CustomerPage isV3API={isV3API} path='/customers/customers' />
          )}
          {envParams.flags.customers && (
            <CustomerDetailsPage
              isV3API={isV3API}
              path='/customers/customers/:customerId'
            />
          )}
          {envParams.flags.customers && (
            <AddCustomer isV3API={isV3API} path='/customers/customers/new' />
          )}
          {envParams.flags.customers && (
            <CustomerAddressPage
              isV3API={isV3API}
              path='/customers/customers/:customerId/addresses'
            />
          )}
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
