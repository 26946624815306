import React, { memo } from 'react';
import { Icon } from '@teamfabric/copilot-ui';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import { StyledPageHeading } from './styles';

function PageHeading({ separator, backRoute, children }) {
  return (
    <Link to={backRoute}>
      <StyledPageHeading separator={separator}>
        <Icon iconName='LeftArrow' size={16} />
        <div className='children'>{children}</div>
      </StyledPageHeading>
    </Link>
  );
}

PageHeading.propTypes = {
  separator: PropTypes.bool,
  backRoute: PropTypes.string,
  children: PropTypes.element,
};

PageHeading.defaultProps = {
  separator: true,
  children: null,
  backRoute: '',
};

export default memo(PageHeading);
