import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledPageHeading = styled.div`
  ${theme.typography.kicker};
  color: ${theme.palette.brand.primary.charcoal};
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 32px;

  .children {
    margin-left: 12px;
  }
`;
