import React from 'react';
import CustomerPageCDP from 'modules/Customers/customerCDP';
import { hasPermission } from 'src/hooks/hasPermission';
import { CUSTOMER_PERMISSIONS, PERMISSION_ERROR } from 'lib/data/constants';
import { Jumbotron } from '@teamfabric/copilot-ui';

const CustomerPage = props => {
  const readCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_READ]);
  if (!readCustomer) {
    return (
      <Jumbotron
        primaryText={PERMISSION_ERROR.MAIN_ERROR}
        secondaryText={PERMISSION_ERROR.MAIN_ERROR_SECONDARY}
      />
    );
  }
  return (
    <div>
      <CustomerPageCDP {...props} />
    </div>
  );
};

export default CustomerPage;
