import React from 'react';
import { Pill, theme, Tooltip } from '@teamfabric/copilot-ui';

import {
  StyledName,
  StyledLocation,
  StyledLastActivity,
  StyledTags,
} from '../styledColumns/styles';
import {
  CUSTOMER_ACTIVE_STATUS,
  CUSTOMER_INACTIVE_STATUS,
} from 'lib/data/constants';

const customerNameStyledComponent = ({ name, email }) => {
  return (
    <StyledName>
      <div className='name'>{name}</div>
      <div className='email'>{email}</div>
    </StyledName>
  );
};
const defaultShippingAddressStyledComponent = ({ defaultShippingAddress }) => {
  if (!defaultShippingAddress) {
    return null;
  }
  const renderedAddress = [];
  if (defaultShippingAddress) {
    defaultShippingAddress.addressLine1 &&
      renderedAddress.push(defaultShippingAddress.addressLine1);
    defaultShippingAddress.addressLine2 &&
      renderedAddress.push(defaultShippingAddress.addressLine2);
    defaultShippingAddress.addressLine3 &&
      renderedAddress.push(defaultShippingAddress.addressLine3);
    defaultShippingAddress.addressLine4 &&
      renderedAddress.push(defaultShippingAddress.addressLine4);
  }
  const { city, state, country } = defaultShippingAddress;
  const locationArray = [];
  city && locationArray.push(city);
  state && locationArray.push(state);
  const location = locationArray.join(', ');
  return (
    <StyledLocation>
      <div className='location'>
        {renderedAddress.length > 0 ? renderedAddress.join(', ') + ', ' : ''}
        {location && `${location}`}
      </div>
      <div>{country}</div>
    </StyledLocation>
  );
};
const statusStyledComponent = ({ status }) => {
  return (
    <StyledLastActivity>
      <div className={`${status ? 'isActive' : ''}`}>
        {status ? CUSTOMER_ACTIVE_STATUS : CUSTOMER_INACTIVE_STATUS}
      </div>
    </StyledLastActivity>
  );
};
const tagsStyledComponent = ({ tags }) => {
  return (
    <StyledTags>
      {tags && tags.length ? (
        <>
          {tags.map((tag, index) => {
            if (index < 3)
              return (
                <Pill
                  color={theme.palette.ui.neutral.grey5}
                  text={
                    tag.name.length > 10
                      ? tag.name.slice(0, 9) + '...'
                      : tag.name
                  }
                  className='tags'
                />
              );
            else if (index === 3)
              return (
                <>
                  <Pill
                    color={theme.palette.ui.neutral.grey5}
                    text={`+${tags.length - 3}`}
                    className='tags'
                  />
                  <Tooltip position='left' className='tooltip'>
                    <div className='tooltip-container'>
                      <p className='tooltip-heading'>({tags.length}) TAGS:</p>
                      <div className='tooltip-body'>
                        {tags.map(tag => (
                          <p key={tag.traitId} className='tooltip-content'>
                            {tag.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </Tooltip>
                </>
              );
          })}
        </>
      ) : (
        ''
      )}
    </StyledTags>
  );
};

const StyledColumnComponents = {
  name: customerNameStyledComponent,
  defaultShippingAddress: defaultShippingAddressStyledComponent,
  status: statusStyledComponent,
  tags: tagsStyledComponent,
};

export default StyledColumnComponents;
