"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var hasPermission_1 = require("./hasPermission");
var useActiveLink = function (navigationLinks, path) {
    var _a = __read((0, react_1.useState)(navigationLinks), 2), links = _a[0], setLinks = _a[1];
    var getActivePermitLinks = (0, react_1.useCallback)(function (currentLinks) {
        var tabId = getActiveTabId(currentLinks, path);
        var permitLinks = [];
        currentLinks.forEach(function (link) {
            if (link.id === tabId) {
                permitLinks.push(__assign(__assign({}, link), { active: true }));
            }
            else if ((0, hasPermission_1.hasPermission)([link.permission])) {
                permitLinks.push(__assign(__assign({}, link), { active: false }));
            }
        });
        return permitLinks;
    }, [path]);
    (0, react_1.useEffect)(function () {
        setLinks(function (currentLinks) {
            return getActivePermitLinks(currentLinks);
        });
    }, [getActivePermitLinks]);
    var getActiveTabId = function (currentLinks, path) {
        var activeId = 1;
        currentLinks.forEach(function (link) {
            if (path.includes(link.url)) {
                activeId = link.id;
            }
        });
        return activeId;
    };
    return { links: links };
};
exports.default = useActiveLink;
