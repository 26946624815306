import React from 'react'
import ButtonWithDropdown from 'components/buttonWithDropdown'

const Tags = ({ tagsArray, handleTagsChange, selectedTags, tags }) => {
  const selectedTagsCheck = `${selectedTags?.length > 2 ? `${selectedTags?.join(', ').slice(0, 10)}...` : selectedTags?.join(', ')}`;
  return (
    <ButtonWithDropdown
      data={[
        {
          type: 'checkbox',
          data: tagsArray,
          filteredData: tags,
          onClickHandler: handleTagsChange
        }
      ]}
      label={`
        Tags: ${
          tagsArray?.length === tags?.length
            ? 'All'
            : tags?.length
            ? selectedTagsCheck
            : 'All'
         }
        `}
      btnIcon={'DownArrow'}
      iconPosition='right'
    />
  )
}

export default Tags
