import React from 'react';
import ButtonWithDropdown from 'components/buttonWithDropdown';

const AddMore = ({ optionsArr, clickHandler }) => {
  return (
    <ButtonWithDropdown
      data-testid='add-more'
      data={[
        {
          type: 'checkbox',
          data: optionsArr,
          onClickHandler: clickHandler,
        },
      ]}
      label={'Add More'}
      btnTransparent={true}
      btnIcon={'Add'}
    />
  );
};

export default AddMore;
