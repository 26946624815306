import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledCustomerPage = styled.div`
  overflow-y: auto;
  display: flex;
  cursor: default;

  .customer-name-column {
    width: 22%;
  }

  .left-section {
    // width: calc(100% - 360px);
    width: 100%;
    padding: 10px 15px 0 0;

    .button-with-icons {
      margin-right: 14px;
    }

    .top-buttons {
      .button-with-icons:focus {
        border: 1px solid ${theme.palette.brand.primary.white} !important;
      }
    }

    .filters-bar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .button-with-icons {
        margin-right: 0;
      }

      .bulk-operations {
        display: flex;
        font-family: Gilroy;
        font-size: 14px;
        line-height: 16px;

        .text-section {
          margin-top: 12px;

          span {
            ${theme.typography.link};
            color: ${theme.palette.ui.cta.blue};
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .table-component {
      .table-cell {
      }
      .table-row {
      }
      .table-row:hover {
        .tags {
          background: ${theme.palette.ui.neutral.grey4};
        }
      }
    }

    .search-component {
      width: 80%;
    }
  }

  .right-section {
    width: 360px;
  }
`;

export const StyledWrapper = styled.div`
  margin-top: 66px;
  text-align: center;
  color: ${theme.palette.brand.primary.charcoal};
`;
export const StyledTitle = styled.div`
  margin-bottom: 32px;
  ${theme.typography.kicker};
  color: ${theme.palette.brand.primary.gray};
`;
export const StyledMessage = styled.div`
  ${theme.typography.subtitle2};
`;
export const StyledLink = styled.div`
  margin-top: 46px;
  ${theme.typography.link};
  color: ${theme.palette.ui.cta.blue};
  cursor: pointer;
`;
export const AddressLink = styled.div`
  ${theme.typography.link};
  color: ${theme.palette.ui.cta.blue};
  cursor: pointer;
`;

export const StyledAddCustomer = styled.div`
  display: flex;
  flex-direction: column;
  .name {
    display: flex;
    flex-direction: column;
    .label {
    }
    .fields {
      display: flex;
      div {
        margin: 0 10px 0 0;
      }
    }
  }
`;
export const StyledModalWrapper = styled.div`
  display: flex;
  border-radius: 20px;
  .title-name{
    margin-left:50px
  }
  .title-other{
    margin-left:50px
    margin-top:35px
    margin-bottom:0px
  }
  .error {
    ${theme.typography.h6};
    color: ${theme.palette.ui.cta.red};
  }
  
  .flex-stack {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  
  .form-spinner {
    z-index: 1000;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.46);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    .global {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
    }
  }
  
  .left-section {
    flex: 1 0 0;
    border-radius: 5px 0 0 5px;
    padding: 30px;
    background: ${theme.palette.ui.neutral.grey5};
    color: ${theme.palette.brand.primary.charcoal};

    .header-text {
      ${theme.typography.subtitle2}
    }
    
    .sub-heading {
      ${theme.typography.link};
      color: ${theme.palette.ui.cta.blue};
      margin-left: 12px;
    }

    .selected-customers-list {
      display: flex;
      
      .list-item:hover {
        background: rgba(0,0,0,0);
      }
      
      .details {
        .user-name {
          ${theme.typography.h6};
          margin-top: 25px;
        }
        .user-id {
          ${theme.typography.h4};
        }
      }
    }
  }
  .right-section {
    flex: 3 0 0;
    padding: 30px 30px 30px 0;
    
    .input__parent {
      .input__label {
         ${theme.typography.h6}
         color: ${theme.palette.brand.primary.gray}
      }
      .input__wrapper {
        .Inputstyles__StyledInput-sc-1e3p0z8-2 {
           ${theme.typography.h6}
           color: ${theme.palette.brand.primary.charcoal}
        }
      }
    }
    
    .dd-lable {
       ${theme.typography.h6}
       color: ${theme.palette.brand.primary.gray}
    }
    .option-label {
      ${theme.typography.h6}
      color: ${theme.palette.brand.primary.charcoal}
    }
  }

  .form {
    padding: 0 80px 100px 50px;
  }
`;
export const StyledInputWrapper = styled.div`
  margin-bottom: 20px;
  margin-left: 50px;
  &.inline {
    display: flex;
    justify-content: start;
    width: 100%;

    div:first-child {
      margin-right: 16px;
    }
  }

  .list {
    margin-bottom: 21px;
  }

  .custom-dropdown-status {
    width: 100%;

    .styles__DropDownList-bw4l9p-4 {
      ${theme.typography.h6}
      color: ${theme.palette.brand.primary.charcoal}
      background: #fff;
      border: 1px solid #d5d9dd;
      border-radius: 5px;
      width: 100%;

      p {
        padding: 5px 0 5px 30px;
      }

      p:hover {
        background: ${theme.palette.ui.neutral.grey5};
      }

      .default-checkbox {
        padding: 5px 0 20px 30px;
      }
    }

    .dropdown-view {
      ${theme.typography.h6};
      color: ${theme.palette.brand.primary.charcoal};
      margin: 0 !important;
    }
  }

  .custom-dropdown {
    width: 100%;

    .styles__DropDownList-bw4l9p-4 {
      ${theme.typography.h6}
      color: ${theme.palette.brand.primary.charcoal}
      background: #fff;
      margin-top: 5px;
      border: 1px solid #d5d9dd;
      border-radius: 5px;
      padding: 20px;
    }

    .dropdown-view {
      ${theme.typography.h6};
      color: ${theme.palette.brand.primary.charcoal};
    }
  }

  &.error--margin {
    margin: -10px 0 20px 0;
  }
`;
export const StyledCaption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 14px;
  margin-bottom: 15px;

  .pagination {
    color: ${theme.palette.brand.primary.gray};
  }
`;
