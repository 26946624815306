import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';

const initialState = {
  customerDetailsLoading: true,
  customerDetails: {},
  editedCustomer: {},
  tags: [],
};

const getCustomerDetailsById = (state, action) => {
  return {
    ...state,
    customerDetails: action.payload,
  };
};

const clearCustomerDetails = () => {
  return initialState;
};

const updatedCustomerSuccess = (state, action) => {
  return {
    ...state,
    editedCustomer: action.payload,
    customerDetails: action.payload,
  };
};

const getCustomerLoadingStatus = (state, action) => {
  return {
    ...state,
    customerDetailsLoading: action.payload,
  };
};

const getCustomerTags = (state, action) => {
  return {
    ...state,
    tags: action.payload,
  };
};

export default createReducer(initialState, {
  [ACTIONS.GET_CUSTOMER_DETAILS_BY_ID]: getCustomerDetailsById,
  [ACTIONS.CLEAR_CUSTOMER_DETAILS]: clearCustomerDetails,
  [ACTIONS.GET_CUSTOMER_TAGS]: getCustomerTags,
  [ACTIONS.CUSTOMER_LOADING]: getCustomerLoadingStatus,
  [ACTIONS.UPDATED_CUSTOMER_SUCCESS]: updatedCustomerSuccess,
});
