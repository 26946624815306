"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decreaseDate = exports.increaseDate = exports.isoDateFormatter = exports.basicDateFormatter = exports.getCalenderYearsRange = exports.ISO_DATE_TIME_FORMAT = exports.dateFormatter = void 0;
var date_fns_1 = require("date-fns");
function dateFormatter(value, formatTo) {
    if (!value) {
        return null;
    }
    return (0, date_fns_1.format)((0, date_fns_1.parseISO)(value), formatTo);
}
exports.dateFormatter = dateFormatter;
exports.ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SS[Z]';
function getCalenderYearsRange(yearIndex) {
    if (yearIndex === void 0) { yearIndex = 5; }
    // Calender supports upto 10 years
    var noOfYears = 10;
    var startYearIndex = noOfYears - yearIndex;
    var endYearIndex = noOfYears - startYearIndex;
    var oneYearFromNow = new Date();
    var fromYear = oneYearFromNow.getFullYear() - endYearIndex;
    var toYear = oneYearFromNow.getFullYear() + startYearIndex;
    var YEARS_ARRAY = [fromYear];
    while (fromYear <= toYear) {
        fromYear++;
        YEARS_ARRAY.push(fromYear);
    }
    return YEARS_ARRAY;
}
exports.getCalenderYearsRange = getCalenderYearsRange;
function basicDateFormatter(value, formatTo) {
    if (!value) {
        return null;
    }
    return (0, date_fns_1.format)(value, formatTo);
}
exports.basicDateFormatter = basicDateFormatter;
function isoDateFormatter(value) {
    if (!value) {
        return null;
    }
    return new Date(value).toISOString();
}
exports.isoDateFormatter = isoDateFormatter;
function increaseDate(value, increaseBy) {
    if (value === undefined || value == '') {
        return null;
    }
    return (0, date_fns_1.addDays)(value, increaseBy);
}
exports.increaseDate = increaseDate;
function decreaseDate(value, decreaseBy) {
    if (value === undefined || value == '') {
        return null;
    }
    return (0, date_fns_1.sub)(value, { days: decreaseBy });
}
exports.decreaseDate = decreaseDate;
