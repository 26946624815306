import styled from 'styled-components';
import { theme, ExpandableCard } from '@teamfabric/copilot-ui';

export const StyledAdditionalCustomerInfoCard = styled.div`
  padding: 32px;
  background: ${theme.palette.brand.primary.white};
  border: 1px solid ${theme.palette.ui.neutral.grey3};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;

  .title {
    display: flex;
    flex: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    ${theme.typography.body}
  }

  .attribute-count {
    ${theme.typography.h6}
  }

  .card-content {
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }

  .card-item {
    display: flex;
    flex: 48%;
    flex-direction: column;
    gap: 8px;
  }

  .card-item-title {
    ${theme.typography.h6}
    color: ${theme.palette.brand.primary.gray};
  }

  .card-item-body {
    ${theme.typography.h6}
    color: ${theme.palette.brand.primary.charcoal};
    font-family: Gilroy-Medium;
    line-height: 16px;
    word-wrap: break-word;
  }
`;

export const StyledExpandableCard = styled(ExpandableCard)`
  padding-top: 32px;
  padding-bottom: 18px;
`;