"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationGroupActionTypes = exports.FormMode = exports.OrganizationDetailsActionTypes = exports.OrganizationGroupTypeList = exports.OrganizationGroupSortByList = exports.OrganizationUserStatusList = exports.OrganizationAddressActionTypes = exports.OrganizationsActionTypes = void 0;
var OrganizationsActionTypes;
(function (OrganizationsActionTypes) {
    OrganizationsActionTypes["START_REQUEST"] = "START_REQUEST";
    OrganizationsActionTypes["GET_ORGANIZATIONS"] = "GET_ORGANIZATIONS";
    OrganizationsActionTypes["SET_SEARCH_SUGGESIONS"] = "SET_SEARCH_SUGGESIONS";
    OrganizationsActionTypes["SET_SEARCH_QUERY_TERM"] = "SET_SEARCH_QUERY_TERM";
    OrganizationsActionTypes["SET_SEARCH_LOADING_STATUS"] = "SET_SEARCH_LOADING_STATUS";
    OrganizationsActionTypes["CLEAR_FILTERS"] = "CLEAR_FILTERS";
    OrganizationsActionTypes["SET_TABLE_LOADING_STATUS"] = "SET_TABLE_LOADING_STATUS";
    OrganizationsActionTypes["SET_TABLE_PAGE_NUMBER"] = "SET_TABLE_PAGE_NUMBER";
    OrganizationsActionTypes["SET_TABLE_SORT_BY_COLUMN"] = "SET_TABLE_SORT_BY_COLUMN";
    OrganizationsActionTypes["SET_ORGANIZATIONS_TABLE_ERROR"] = "SET_ORGANIZATIONS_TABLE_ERROR";
    OrganizationsActionTypes["SET_ORGANIZATIONS_TABLE_API_COUNTER"] = "SET_ORGANIZATIONS_TABLE_API_COUNTER";
    OrganizationsActionTypes["SET_ORGANIZATIONS_TABLE_INITIALIZE"] = "SET_ORGANIZATIONS_TABLE_INITIALIZE";
    OrganizationsActionTypes["SET_TABLE_LIST_FILTER_BY_KEY"] = "SET_TABLE_LIST_FILTER_BY_KEY";
})(OrganizationsActionTypes = exports.OrganizationsActionTypes || (exports.OrganizationsActionTypes = {}));
var OrganizationAddressActionTypes;
(function (OrganizationAddressActionTypes) {
    OrganizationAddressActionTypes["START_REQUEST"] = "START_REQUEST";
    OrganizationAddressActionTypes["SET_ORGANIZATIONS_ADDRESS_LIST"] = "SET_ORGANIZATIONS_ADDRESS_LIST";
    OrganizationAddressActionTypes["SET_ORGANIZATIONS_ADDRESS"] = "SET_ORGANIZATIONS_ADDRESS";
    OrganizationAddressActionTypes["SET_GROUP_LIST"] = "SET_GROUP_LIST";
    OrganizationAddressActionTypes["SET_TOASTR"] = "SET_TOASTR";
    OrganizationAddressActionTypes["SET_LIST_ADDRESS_REQUEST"] = "SET_LIST_ADDRESS_REQUEST";
    OrganizationAddressActionTypes["SET_ORGANIZATION_NAME"] = "SET_ORGANIZATION_NAME";
})(OrganizationAddressActionTypes = exports.OrganizationAddressActionTypes || (exports.OrganizationAddressActionTypes = {}));
exports.OrganizationUserStatusList = [
    'PENDING',
    'INACTIVE',
    'ACTIVE',
];
exports.OrganizationGroupSortByList = [
    'updatedAt',
    'groupType',
    'email',
    'name',
];
exports.OrganizationGroupTypeList = [
    'Department',
    'Department',
    'Subsidiary',
    'Business Unit',
    'Team',
    'User',
    'Customer',
];
var OrganizationDetailsActionTypes;
(function (OrganizationDetailsActionTypes) {
    OrganizationDetailsActionTypes["VIEW_ORGANIZATION"] = "VIEW_ORGANIZATION";
    OrganizationDetailsActionTypes["SET_ORGANIZATION_TOAST"] = "SET_ORGANIZATION_TOAST";
    OrganizationDetailsActionTypes["SET_VIEW_ORGANIZATION_LOADING_STATUS"] = "SET_VIEW_ORGANIZATION_LOADING_STATUS";
    OrganizationDetailsActionTypes["SET_VIEW_ORGANIZATION_DETAILS_FORM_MODE"] = "SET_VIEW_ORGANIZATION_DETAILS_FORM_MODE";
    OrganizationDetailsActionTypes["SET_ORGANIZATION_FORM_STATUS"] = "SET_ORGANIZATION_FORM_STATUS";
})(OrganizationDetailsActionTypes = exports.OrganizationDetailsActionTypes || (exports.OrganizationDetailsActionTypes = {}));
var FormMode;
(function (FormMode) {
    FormMode["VIEW"] = "VIEW";
    FormMode["EDIT"] = "EDIT";
    FormMode["ADD"] = "ADD";
})(FormMode = exports.FormMode || (exports.FormMode = {}));
var OrganizationGroupActionTypes;
(function (OrganizationGroupActionTypes) {
    OrganizationGroupActionTypes["VIEW_ORGANIZATION_GROUP"] = "VIEW_ORGANIZATION_GROUP";
    OrganizationGroupActionTypes["SET_GROUP_TOAST"] = "SET_GROUP_TOAST";
    OrganizationGroupActionTypes["SET_GROUP_LOADING_STATUS"] = "SET_GROUP_LOADING_STATUS";
    OrganizationGroupActionTypes["SET_GROUP_DETAILS_FORM_MODE"] = "SET_GROUP_DETAILS_FORM_MODE";
})(OrganizationGroupActionTypes = exports.OrganizationGroupActionTypes || (exports.OrganizationGroupActionTypes = {}));
