export const PAGINATION = 10
export const CUSTOMER_ORDERS_COLUMNS = [
  { title: 'Order Id', accessor: 'orderId', isSortable: true },
  { title: 'Price', accessor: 'orderTotal', isSortable: true },
  { title: 'Status', accessor: 'status', isSortable: true },
  { title: 'Date Placed', accessor: 'createdAt', isSortable: true }
]
export const STATUS_MAP = {
  ORDER_CREATED: 'Created',
  ORDER_PAYMENT_AUTHORIZED: 'Payment Authorized',
  ORDER_SHIPPED: 'Shipped',
  ORDER_PARTIALLY_SHIPPED: 'Partially Shipped',
  ORDER_CANCELLED: 'Cancelled',
  ORDER_DELIVERED: 'Delivered'
}
