import React, { useEffect, useState } from 'react';
import { Box, EmptyPage, Input, PageHeader, theme, useToast } from 'ds4-beta';
import { hasPermission } from 'src/hooks/hasPermission';
import {
  COMMON_BREADCRUMB,
  CUSTOMER_PERMISSIONS,
  OTHER_ERROR,
  PERMISSION_ERROR,
} from 'src/lib/data/constants';
import { StyledH5 } from '../styles';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCustomer,
  createCustomerV3,
} from 'src/modules/Customers/actions';
import { isEmailValid, isNameValid } from 'src/lib/utils/helpers';

const AddCustomer = ({ isV3API }) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [title, setTitle] = useState('');
  const [suffix, setSuffix] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [savingCustomer, setSavingCustomer] = useState(false);
  const canCreateCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_CREATE]);
  const createdCustomer = useSelector(
    state => state.customerIndex.createdCustomer
  );
  const dispatch = useDispatch();
  const showToast = useToast();

  window.updateBreadcrumb([
    ...COMMON_BREADCRUMB,
    {
      label: 'Add Customer',
      url: '/customers/customers/new',
    },
  ]);

  useEffect(() => {
    if (createdCustomer && savingCustomer) {
      setSavingCustomer(false);
      if (createdCustomer.error) {
        // show toast message
        showToast({
          id: 'customer-create-error',
          label:
            createdCustomer.error.message || OTHER_ERROR.CUSTOMERS_CREATE_ERROR,
          variant: 'error',
        });
      } else {
        navigate(
          `/customers/customers/${
            createdCustomer._id || createdCustomer.id
          }/addresses`,
          {
            state: { isNewCustomer: true },
          }
        );
      }
    }
  }, [createdCustomer]);

  if (!canCreateCustomer) {
    return (
      <EmptyPage
        headerText={PERMISSION_ERROR.MAIN_ERROR}
        description={PERMISSION_ERROR.MAIN_ERROR_SECONDARY}
      />
    );
  }

  const onSave = () => {
    let hasError = false;
    if (!isV3API) {
      if (!isNameValid(name)) {
        hasError = true;
        setNameError('Name should be at least 3 characters');
      }
    } else {
      if (!isNameValid(firstName)) {
        hasError = true;
        setFirstNameError('First name should be at least 3 characters');
      }
      if (!isNameValid(lastName)) {
        hasError = true;
        setLastNameError('Last name should be at least 3 characters');
      }
    }
    if (!isEmailValid(email)) {
      hasError = true;
      setEmailError('Email format incorrect');
    }
    if (!hasError) {
      setSavingCustomer(true);
      // make network call here
      if (isV3API) {
        // create customerV3 object and call V3 API
        dispatch(
          createCustomerV3({
            name: {
              title,
              firstName,
              middleName,
              lastName,
              suffix,
            },
            emailAddress: email,
          })
        );
      } else {
        dispatch(
          createCustomer({
            name,
            email,
            partyType: 'P',
            isActive: false,
          })
        );
      }
    }
  };

  return (
    <>
      <PageHeader
        h1Text='Add Customer'
        primaryButtons={[
          {
            text: 'Cancel',
            variant: 'secondary',
            onClick: () => {
              navigate(`/customers/customers/`);
            },
          },
          {
            text: 'Save',
            onClick: onSave,
            // isDisabled: savingCustomer,
            isLoading: savingCustomer,
          },
        ]}
      />
      <Box
        padding={[4, 4, 4, 4]}
        margin={{ top: 4 }}
        border={{ radius: 2, width: '1px', color: theme.color.grey[200] }}
      >
        <StyledH5>Basic information</StyledH5>
        <Box margin={{ top: 4 }} />
        {!isV3API ? (
          <Input
            inputProps={{
              onChange: e => {
                setNameError('');
                setName(e.target.value);
              },
              id: 'new-customer-name',
              dataTestid: 'new-customer-name',
              disabled: savingCustomer,
            }}
            message={nameError}
            messageType={nameError ? 'error' : 'normal'}
            required
            label='Customer name'
          />
        ) : (
          <>
            <Input
              inputProps={{
                onChange: e => {
                  setTitle(e.target.value);
                },
                id: 'new-v3-customer-title',
                dataTestid: 'new-v3-customer-title',
                disabled: savingCustomer,
              }}
              label='Title'
            />
            <Box margin={{ top: 4 }} />
            <Input
              inputProps={{
                onChange: e => {
                  setFirstNameError('');
                  setFirstName(e.target.value);
                },
                id: 'new-v3-customer-first-name',
                dataTestid: 'new-v3-customer-first-name',
                disabled: savingCustomer,
              }}
              required
              message={firstNameError}
              messageType={firstNameError ? 'error' : 'normal'}
              label='First name'
            />
            <Box margin={{ top: 4 }} />
            <Input
              inputProps={{
                onChange: e => {
                  setMiddleName(e.target.value);
                },
                id: 'new-v3-customer-middle-name',
                dataTestid: 'new-v3-customer-middle-name',
                disabled: savingCustomer,
              }}
              label='Middle name'
            />
            <Box margin={{ top: 4 }} />
            <Input
              inputProps={{
                onChange: e => {
                  setLastNameError('');
                  setLastName(e.target.value);
                },
                id: 'new-v3-customer-last-name',
                dataTestid: 'new-v3-customer-last-name',
                disabled: savingCustomer,
              }}
              required
              message={lastNameError}
              messageType={lastNameError ? 'error' : 'normal'}
              label='Last name'
            />
            <Box margin={{ top: 4 }} />
            <Input
              inputProps={{
                onChange: e => {
                  setSuffix(e.target.value);
                },
                id: 'new-v3-customer-suffix',
                dataTestid: 'new-v3-customer-suffix',
                disabled: savingCustomer,
              }}
              label='Suffix'
            />
          </>
        )}
        <Box margin={{ top: 4 }} />
        <Input
          inputProps={{
            onChange: e => {
              setEmailError('');
              setEmail(e.target.value);
            },
            id: 'new-customer-email',
            dataTestid: 'new-customer-email',
            disabled: savingCustomer,
          }}
          message={emailError}
          messageType={emailError ? 'error' : 'normal'}
          required
          label='Customer email'
        />
      </Box>
    </>
  );
};

export default AddCustomer;
