import { createGlobalStyle } from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    margin: 0px;
    color: ${theme.palette.brand.primary.charcoal};
    background-color: ${theme.palette.ui.neutral.grey5};
    height: 100%;
    min-height: 100%;
  }

  #__next {
    height: 100%;
  }

  .reach-router {
    height: 100%;
    width: 100%;
  }

  .app_root {
    height: 100%;
    width: 100%;
  }

  .app_children {
    padding: 26px 24px;
    width: 100%;
    height: 100%;
  }

  .app_children > * {
    height: 100%;
  }

  .d-flex {
    display: flex;
  }

  .justify-center {
    justify-content: center;

    .justify-start {
      justify-content: flex-start;
    }
  }

  .align-center {
    align-items: center;
  }

  .h-100 {
    height: 100%
  }

  .w-100 {
    width: 100%;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .pr-20 {
    padding-right: 20px;
  }

  .pl-20 {
    padding-left: 20px;
  }

  .p-20 {
    padding: 20px;
  }

  .p30 {
    padding: 30px;
  }

  .p-10 {
    padding: 10px;
  }

  .m-20 {
    margin: 20px;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-auto {
    overflow: auto;
  }

  .error {
    ${theme.typography.h6};
    color: ${theme.palette.ui.cta.red};
    margin: 4px 0;
  }

  .error-colored {
    color: ${theme.palette.ui.cta.red};
  }

  a, a:visited {
    text-decoration: none;
  }

  .inactive {
    color: ${theme.palette.brand.primary.gray};
  }
`;
export default GlobalStyles;
