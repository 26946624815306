import React, { useState, useEffect } from 'react';
import ButtonWithDropdown from 'components/buttonWithDropdown';

const TotalAmountSpent = ({
  totalAmountSpentMin,
  totalAmountSpentMax,
  updateOtherFilters,
}) => {
  const [error, setError] = useState();

  useEffect(() => {
    setError(
      totalAmountSpentMin &&
        totalAmountSpentMax &&
        totalAmountSpentMin >= totalAmountSpentMax
        ? 'Minimum amount should be lesser than Maximum amount'
        : ''
    );
  }, [totalAmountSpentMin, totalAmountSpentMax]);
  const minAmount = totalAmountSpentMin > 0 ? totalAmountSpentMin : '';
  const amountSep = totalAmountSpentMin > 0 && totalAmountSpentMax > 0 ? ' - ' : '';
  const maxAmount = totalAmountSpentMax > 0 ? totalAmountSpentMax : ''
  return (
    <ButtonWithDropdown
      label={`
        Total Amount Spent:
        ${
          totalAmountSpentMin > 0 || totalAmountSpentMax > 0
            ? `
            ${minAmount}
            ${amountSep}
            ${maxAmount}
          `
            : 'All'
        }
      `}
      data={[
        {
          type: 'input',
          data: [
            {
              label: 'Minimum',
              isFloatedLabel: false,
              errorMessage: error,
              error: Boolean(error) || false,
              inputProps: {
                type: 'number',
                name: 'totalAmountSpentMin',
                min: 0,
                value: totalAmountSpentMin,
              },
            },
            {
              label: 'Maximum',
              isFloatedLabel: false,
              inputProps: {
                type: 'number',
                name: 'totalAmountSpentMax',
                min: 0,
                value: totalAmountSpentMax,
              },
            },
          ],
          onClickHandler: updateOtherFilters,
        },
      ]}
      btnIcon={'DownArrow'}
      iconPosition='right'
    />
  );
};

export default TotalAmountSpent;
