"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractsActionTypes = exports.ContractFormType = exports.ContractStatus = void 0;
var ContractStatus;
(function (ContractStatus) {
    ContractStatus["ACTIVE"] = "ACTIVE";
    ContractStatus["INACTIVE"] = "INACTIVE";
    ContractStatus["DRAFT"] = "DRAFT";
})(ContractStatus = exports.ContractStatus || (exports.ContractStatus = {}));
var ContractFormType;
(function (ContractFormType) {
    ContractFormType["ADD"] = "ADD";
    ContractFormType["VIEW"] = "VIEW";
    ContractFormType["EDIT"] = "EDIT";
})(ContractFormType = exports.ContractFormType || (exports.ContractFormType = {}));
var ContractsActionTypes;
(function (ContractsActionTypes) {
    ContractsActionTypes["START_REQUEST"] = "START_REQUEST";
    ContractsActionTypes["GET_CONTRACTS"] = "GET_CONTRACTS";
    ContractsActionTypes["GET_CONTRACTS_BY_ID"] = "GET_CONTRACTS_BY_ID";
    ContractsActionTypes["UPDATE_CONTRACTS_BY_ID"] = "UPDATE_CONTRACTS_BY_ID";
    ContractsActionTypes["SET_SEARCH_SUGGESIONS"] = "SET_SEARCH_SUGGESIONS";
    ContractsActionTypes["SET_SEARCH_QUERY_TERM"] = "SET_SEARCH_QUERY_TERM";
    ContractsActionTypes["SET_SEARCH_LOADING_STATUS"] = "SET_SEARCH_LOADING_STATUS";
    ContractsActionTypes["CLEAR_FILTERS"] = "CLEAR_FILTERS";
    ContractsActionTypes["SET_TABLE_LOADING_STATUS"] = "SET_TABLE_LOADING_STATUS";
    ContractsActionTypes["SET_TABLE_PAGE_NUMBER"] = "SET_TABLE_PAGE_NUMBER";
    ContractsActionTypes["SET_TABLE_LIST_FILTER_BY_KEY"] = "SET_TABLE_LIST_FILTER_BY_KEY";
    ContractsActionTypes["SET_TABLE_SORT_BY_COLUMN"] = "SET_TABLE_SORT_BY_COLUMN";
    ContractsActionTypes["SET_CONTRACTS_TABLE_ERROR"] = "SET_CONTRACTS_TABLE_ERROR";
    ContractsActionTypes["SET_CONTRACTS_TABLE_INITIALIZE"] = "SET_CONTRACTS_TABLE_INITIALIZE";
    ContractsActionTypes["SET_CONTRACTS_TABLE_API_COUNTER"] = "SET_CONTRACTS_TABLE_API_COUNTER";
    ContractsActionTypes["CREATE_CONTRACT_REQUEST"] = "CREATE_CONTRACT_REQUEST";
    ContractsActionTypes["CREATE_CONTRACT_SUCCESS"] = "CREATE_CONTRACT_SUCCESS";
    ContractsActionTypes["CREATE_CONTRACT_FAILED"] = "CREATE_CONTRACT_FAILED";
    ContractsActionTypes["CONTRACT_REQUEST"] = "CONTRACT_REQUEST";
    ContractsActionTypes["CONTRACT_SUCCESS"] = "CONTRACT_SUCCESS";
    ContractsActionTypes["CONTRACT_FAILED"] = "CONTRACT_FAILED";
    ContractsActionTypes["CLOSE_NOTIFICATION"] = "CLOSE_NOTIFICATION";
    ContractsActionTypes["GET_ORGANIZATIONS_LIST"] = "GET_ORGANIZATIONS_LIST";
    ContractsActionTypes["GET_PRICE_LIST"] = "GET_PRICE_LIST";
    ContractsActionTypes["GET_PRICE_LIST_SKU"] = "GET_PRICE_LIST_SKU";
    ContractsActionTypes["GET_PRICE_LIST_SKU_REQUEST"] = "GET_PRICE_LIST_SKU_REQUEST";
    ContractsActionTypes["RESET_CONTRACTS_FORM"] = "RESET_CONTRACTS_FORM";
})(ContractsActionTypes = exports.ContractsActionTypes || (exports.ContractsActionTypes = {}));
