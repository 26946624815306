"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumberCompact = exports.transformSegmentFilters = void 0;
var transformSegmentFilters = function (rawFilters) {
    var filters = {};
    if (rawFilters.statuses.length === 1) {
        filters['isActive'] = rawFilters.statuses.includes('active') ? true : false;
    }
    return filters;
};
exports.transformSegmentFilters = transformSegmentFilters;
var formatNumberCompact = function (number) {
    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
    }).format(number);
};
exports.formatNumberCompact = formatNumberCompact;
