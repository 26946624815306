import styled from 'styled-components'

export const StyledAutoComplete = styled.div`
  margin: 25px 0;
  .hidden{
    display: none !important
  }
  .searchLoader{
    flex: 1 1 auto;
    height: 50px;
  }
  .searhContainer{
    flex: 1 1 auto;
    display: flex;
  }
  .search-btn:focus {
    border: 1px solid rgb(0, 0, 0, 0);
  }
`
