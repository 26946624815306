// Generated from /Users/vishnu.d/WebstormProjects/copilot-mfa-customer/src/modules/Segmentation/conditionBuilder/grammar/ConditionalGrammar.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by ConditionalGrammarParser.

export default class ConditionalGrammarVisitor extends antlr4.tree
  .ParseTreeVisitor {
  // Visit a parse tree produced by ConditionalGrammarParser#start.
  visitStart(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#expression.
  visitExpression(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#InnerExpression.
  visitInnerExpression(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#NumberComparison.
  visitNumberComparison(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#StringOperation.
  visitStringOperation(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#NullBooleanOperation.
  visitNullBooleanOperation(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#logicalOp.
  visitLogicalOp(ctx) {
    return this.visitChildren(ctx);
  }

  // Visit a parse tree produced by ConditionalGrammarParser#comparisonOp.
  visitComparisonOp(ctx) {
    return this.visitChildren(ctx);
  }
}
