"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEGMENTS_PAGE_TITLE = exports.SEGMENT_STATUS = exports.SEGMENTATION_PAGE_TABLE_HEADERS = void 0;
var SEGMENTATION_PAGE_TABLE_HEADERS = function (_a) {
    var name = _a.name, population = _a.population, status = _a.status, description = _a.description;
    return [
        {
            className: 'segment-name-column',
            accessor: 'segmentName',
            isSortable: true,
            render: name,
            title: 'Segment Name',
        },
        {
            className: 'segment-population-column',
            accessor: 'count',
            isSortable: true,
            render: population,
            title: 'Population',
        },
        {
            className: 'status-column',
            accessor: 'status',
            isSortable: true,
            render: status,
            title: 'Status',
        },
        {
            className: 'description-column',
            accessor: 'description',
            isSortable: false,
            render: description,
            title: 'Description',
        },
    ];
};
exports.SEGMENTATION_PAGE_TABLE_HEADERS = SEGMENTATION_PAGE_TABLE_HEADERS;
exports.SEGMENT_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    ERROR: 'Error',
    BUILDING: 'Building...',
};
exports.SEGMENTS_PAGE_TITLE = 'Customer Segments';
