import API, { API_URL } from 'src/business-layer-client';
import { PAGINATION } from 'modules/CustomerDetails/constants';

export const getAllTagsApi = async () => {
  return API.identity.get(API_URL.GET_ALL_TAGS);
};

export const getAllCustomersApi = async (offset, limit) => {
  return API.identity.get(API_URL.GET_ALL_CUSTOMERS(offset, limit));
};

export const getCustomersFilterApi = async params => {
  return API.identity.post(API_URL.GET_FILTERED_CUSTOMERS, params);
};

export const addTagsToCustomersBulkApi = async params => {
  return API.identity.post(API_URL.ADD_TAGS_TO_CUSTOMERS, params)
};

export const removeTagsToCustomersBulkApi = async params => {
  return API.identity.patch(API_URL.REMOVE_TAGS_FROM_CUSTOMERS, params);
};

export const getCustomerByIdApi = async customerId => {
  return API.identity.get(API_URL.GET_CUSTOMER_BY_ID(customerId));
};

export const getCustomerOrdersApi = async ({
  customerId,
  limit = PAGINATION,
  offset,
  sortBy,
  sortDirection,
}) => {
  return API.oms.get(
    API_URL.GET_CUSTOMER_ORDERS({
      id: customerId,
      offset,
      limit,
      sortBy,
      sortDirection,
    })
  );
};

export const addTagToCustomer = async ({ customerId, tag }) => {
  return API.identity.post(API_URL.ADD_TAG_TO_CUSTOMER(customerId), {
    tag,
  });
};

export const removeTagFromCustomer = async ({ customerId, tag }) => {
  return API.identity.delete(
    API_URL.REMOVE_TAG_FROM_CUSTOMER(customerId),
    {
      data: { tag },
    }
  );
};

export const addCustomer = async params => {
  return API.identity.post(API_URL.ADD_CUSTOMER, params);
};

export const toggleCustomer = async ({ userId }) => {
  return API.identity.put(API_URL.TOGGLE_CUSTOMER(userId));
};

export const getPriceList = async params => {
  return API.offers.get(API_URL.GET_PRICE_LISTS(params));
};

export const getPriceListSku = async params => {
  return API.offers.post(API_URL.GET_PRICE_LIST_SKU, params);
};
