import React, { useState, useEffect } from 'react';
import { navigate, useLocation } from '@reach/router';
import { Navigation } from '@teamfabric/copilot-ui';
import { StyledNavigation } from './style';
import { useConfig } from 'src/hooks/header';

const SideNavigation = () => {
  const { pathname } = useLocation();
  const { config } = useConfig();
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const getActiveId = links => {
      for (const link of links) {
        if (pathname.includes(link.label.toLowerCase())) {
          return link.id;
        }
      }
    };
    let activeId = getActiveId(config.links);
    let visibleLinks = config.links.filter(link => link.visible);
    visibleLinks = visibleLinks.map(link => {
      link['active'] = link.id === activeId;
      return link;
    });
    setLinks(visibleLinks);
  }, [config.links]);

  const clickHandler = e => {
    e.preventDefault();
    navigate(e.target.pathname);
    setActiveTab(e.target.pathname);
  };

  const setActiveTab = url => {
    const draftlinks = config.links
      .map(link => {
        link['active'] = link.url === url;
        return link;
      })
      .filter(link => link.visible);
    setLinks(draftlinks);
  };

  return (
    <StyledNavigation>
      <div className='side-navigation'>
        <Navigation
          className='secondary'
          links={links}
          onClick={e => clickHandler(e)}
          orientation='vertical'
        />
      </div>
    </StyledNavigation>
  );
};

export default SideNavigation;
