import React from 'react';
import { EmptyPage } from 'ds4-beta';
import { hasPermission } from 'src/hooks/hasPermission';
import { CUSTOMER_PERMISSIONS, PERMISSION_ERROR } from 'lib/data/constants';
import CustomerPageCDP from './customerCDP';

const CustomerPage = props => {
  const readCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_READ]);
  if (!readCustomer) {
    return (
      <EmptyPage
        headerText={PERMISSION_ERROR.MAIN_ERROR}
        description={PERMISSION_ERROR.MAIN_ERROR_SECONDARY}
      />
    );
  }
  return (
    <>
      <CustomerPageCDP {...props} />
    </>
  );
};

export default CustomerPage;
