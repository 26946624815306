import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  GridCol,
  GridRow,
  Input,
  Modal,
  PageHeader,
  SkeletonButton,
  SkeletonFieldLabel,
  SkeletonH1,
  SkeletonH3,
  Tab,
  TabItem,
  Tooltip,
  theme,
  useToast,
} from 'ds4-beta';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermission } from 'src/hooks/hasPermission';
import {
  COMMON_BREADCRUMB,
  COUNTRIES,
  CUSTOMER_PERMISSIONS,
} from 'lib/data/constants';
import {
  ACTIONS,
  addToCustomerAddresses,
  getCustomerAddresses,
  resetCustomerAddresses,
  setDefaultCustomerAddress,
  submitCustomerAddresses,
  updateCustomerAddress,
} from 'modules/CustomerAddresses/actions';
import { getCustomerDetailsById } from 'modules/CustomerDetails/actions';
import { StyledH5 } from '../styles';
import { getNameString, getUniqueId } from 'src/lib/utils/helpers';

const canUpdateAddress = hasPermission([
  CUSTOMER_PERMISSIONS.CUST_ADDRESS_UPDATE,
]);
const canAddAddress = hasPermission([CUSTOMER_PERMISSIONS.CUST_ADDRESS_CREATE]);
const canDeleteAddress = hasPermission([
  CUSTOMER_PERMISSIONS.CUST_ADDRESS_DELETE,
]);

const noErrors = {
  addressLine1: 'normal',
  addressLine2: 'normal',
  addressLine3: 'normal',
  addressLine4: 'normal',
  postalCode: 'normal',
  country: 'normal',
  county: 'normal',
  state: 'normal',
  city: 'normal',
};

const addressFields = Object.keys(noErrors);

const CustomerAddresses = ({ customerId, location, isV3API }) => {
  const customer = useSelector(state => state.customerDetails.customerDetails);
  const addresses = useSelector(state => state.customerAddresses);

  const showToast = useToast();
  const dispatch = useDispatch();
  const [canSave, setCanSave] = useState(true);
  const [canAddShippingAddress, setCanAddShippingAddress] = useState(true);
  const [canAddBillingAddress, setCanAddBillingAddress] = useState(true);
  const [askConfirmation, setAskConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getCustomerDetailsById(customerId, isV3API));
    dispatch(getCustomerAddresses(customerId, isV3API));
  }, [customerId, dispatch]);

  useEffect(() => {
    if (addresses.customerAddressesSaved) {
      showToast({
        id: 'customer_address_saved',
        label: 'Addresses updated successfully!',
        variant: 'default',
      });
      dispatch({ type: ACTIONS.CLOSE_NOTIFICATION });
    }
    let [_billing, _shipping, _hasError, _isUnsaved] = [
      true,
      true,
      false,
      false,
    ];
    addresses.customerAddresses.forEach(a => {
      if (!a.isDeleted && a.isNew) {
        if (a.type === 'M' || a.address?.type === 'SHIPPING') {
          _shipping = false;
        } else {
          _billing = false;
        }
      }
      if (!a.isDeleted && a.errors) {
        if (Object.values(a.errors).includes('error')) {
          _hasError = true;
        }
        if (
          !_hasError &&
          !_isUnsaved &&
          addresses.customerAddressesFromServer
        ) {
          // check if all fields are same or not
          const savedAddress = addresses.customerAddressesFromServer.find(
            ad => (ad._id && ad._id === a._id) || (ad.id && ad.id === a.id)
          );
          const _savedAddress =
            (savedAddress && savedAddress.address) || savedAddress;
          const _a = a.address || a;
          _isUnsaved = _savedAddress
            ? addressFields.find(_prop => _savedAddress[_prop] !== _a[_prop])
            : true;
        }
      }
    });
    setCanAddBillingAddress(_billing);
    setCanAddShippingAddress(_shipping);
    setAskConfirmation(_hasError || _isUnsaved);
  }, [addresses]);

  window.updateBreadcrumb([
    ...COMMON_BREADCRUMB,
    {
      label: isV3API ? getNameString(customer) : customer.name,
      url: `/customers/customers/${customer._id || customer.id}`,
    },
    {
      label: 'Manage addresses',
      url: `/customers/customers/${customer._id || customer.id}/addresses`,
    },
  ]);

  const verifyAllAddress = () => {
    let hasError = false;
    addresses.customerAddresses
      .filter(a => !a.isDeleted)
      .forEach(address => {
        const errors = JSON.parse(JSON.stringify(noErrors));
        const _address = isV3API ? address.address : address;
        if (!_address.addressLine1 || _address.addressLine1.length == 0) {
          errors.addressLine1 = 'error';
          hasError = true;
        } else {
          errors.addressLine1 = 'normal';
        }
        if (!_address.city || _address.city.length == 0) {
          errors.city = 'error';
          hasError = true;
        } else {
          errors.city = 'normal';
        }
        if (!_address.country || _address.country.length == 0) {
          errors.country = 'error';
          hasError = true;
        } else {
          errors.country = 'normal';
        }
        dispatch(updateCustomerAddress({ ...address, errors }));
      });
    return hasError;
  };

  const onSave = () => {
    const hasError = verifyAllAddress();
    if (!hasError) {
      dispatch(
        submitCustomerAddresses(
          customer._id || customer.id,
          addresses.customerAddresses,
          isV3API
        )
      );
    } else {
      showToast({
        id: 'customer-address-with-error',
        label: 'Kindly fix error(s) in one or more addresses',
        variant: 'error',
      });
    }
  };

  return addresses.loadingCustomerAddresses ? (
    <AddressCardLoader />
  ) : (
    <>
      <PageHeader
        h1Text={isV3API ? getNameString(customer) : customer.name}
        primaryButtons={[
          {
            text: 'Cancel',
            variant: 'secondary',
            onClick: () => {
              navigate(
                location?.state?.isNewCustomer
                  ? `/customers/customers/`
                  : `/customers/customers/${customer._id || customer.id}`
              );
            },
          },
          {
            text: 'Save',
            onClick: onSave,
            isDisabled: !(
              canUpdateAddress &&
              canDeleteAddress &&
              canAddAddress &&
              canSave
            ),
            isLoading: addresses.savingCustomerAddresses,
          },
        ]}
      />
      <Tab
        showTabChangeModal={
          askConfirmation || !(canAddBillingAddress && canAddShippingAddress)
        }
        tabChangeModal={{
          onConfirmClicked: () => dispatch(resetCustomerAddresses()),
        }}
      >
        <TabItem label='Shipping Addresses'>
          <Box margin={{ bottom: 4, top: 5 }} />
          {addresses.customerAddresses
            .filter(
              a =>
                (a.type === 'M' || a.address?.type === 'SHIPPING') &&
                !a.isDeleted
            )
            // .sort(a => (a.isDefault || a.address?.isDefault ? -1 : 1))
            .map(a => (
              <AddressCard
                key={a._id || a.id}
                address={a}
                setCanSave={setCanSave}
                isV3API={isV3API}
              />
            ))}
          <Button
            variant='secondary'
            text='Add Shipping Address'
            icon='Add'
            isDisabled={!canAddShippingAddress}
            onClick={() => {
              const id = getUniqueId();
              dispatch(
                addToCustomerAddresses({
                  ...(isV3API
                    ? { id, address: { type: 'SHIPPING' } }
                    : { type: 'M', _id: id }),
                  isNew: true,
                })
              );
            }}
          />
        </TabItem>
        <TabItem label='Billing Addresses'>
          <Box margin={{ bottom: 4, top: 5 }} />
          {addresses.customerAddresses
            .filter(
              a =>
                (a.type === 'P' || a.address?.type === 'BILLING') &&
                !a.isDeleted
            )
            // .sort(a => (a.isDefault || a.address?.isDefault ? -1 : 1))
            .map(a => (
              <AddressCard
                key={a._id || a.id}
                address={a}
                setCanSave={setCanSave}
                isV3API={isV3API}
              />
            ))}
          <Button
            variant='secondary'
            text='Add Billing Address'
            icon='Add'
            isDisabled={!canAddBillingAddress}
            onClick={() => {
              const id = getUniqueId();
              dispatch(
                addToCustomerAddresses({
                  ...(isV3API
                    ? { id, address: { type: 'BILLING' } }
                    : { type: 'P', _id: id }),
                  isNew: true,
                })
              );
            }}
          />
        </TabItem>
      </Tab>
    </>
  );
};

const AddressCard = ({ address: propsAddress = {}, setCanSave, isV3API }) => {
  const [defaultAddModal, setDefaultAddModal] = useState(false);
  const [address, setAddress] = useState(propsAddress);
  const [errors, setErrors] = useState(propsAddress.errors || noErrors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCustomerAddress({ ...address, errors }));
  }, [errors]);

  useEffect(() => {
    setAddress(propsAddress);
    if (propsAddress.errors) {
      setErrors(propsAddress.errors);
    }
  }, [propsAddress]);

  const onBlur = field => {
    return () => {
      setCanSave(true);
      const fieldsToVerify = ['addressLine1', 'city'];
      // country is a Dropdown, it will not be handled here
      const value = _address[field];
      if (fieldsToVerify.indexOf(field) !== -1) {
        setErrors({
          ...errors,
          [field]: value && value.length > 0 ? 'normal' : 'error',
        });
      } else {
        setErrors({ ...errors });
      }
    };
  };

  const _address = address.address || address; // to keep things backward compatible

  return (
    <Box
      padding={[4, 4, 4, 4]}
      margin={{ bottom: 4 }}
      border={{ radius: 2, width: '1px', color: theme.color.grey[200] }}
    >
      <Box flex={{ justifyContent: 'space-between' }}>
        <StyledH5>
          {address.isDefault && 'Default '}
          {`${
            _address.type === 'M' || _address.type === 'SHIPPING'
              ? 'Shipping'
              : 'Billing'
          } address`}
        </StyledH5>
        <Box flex gap={2}>
          {canDeleteAddress && (
            <Button
              variant='tertiary'
              text={'Delete'}
              icon='Trash'
              onClick={() => {
                dispatch(
                  updateCustomerAddress({ ...address, isDeleted: true })
                );
              }}
            />
          )}
          {/* {canUpdateAddress && (
            <Button
              variant='secondary'
              isDisabled={address.isDefault}
              text={address.isDefault ? 'Default' : 'Make Default'}
              onClick={() => {
                dispatch(setDefaultCustomerAddress(address));
                setCanSave(true);
              }}
            />
          )} */}
        </Box>
      </Box>
      <GridRow padding={0}>
        <GridCol sm={6} md={4} lg={4}>
          <Box flex={{ flexDirection: 'column' }} gap={4}>
            <Input
              width='100%'
              inputProps={{
                value: _address.addressLine1,
                isDisabled: !canUpdateAddress,
                placeholder: 'Address line 1',
                onBlur: onBlur('addressLine1'),
                onChange: event => {
                  if (isV3API) {
                    setAddress({
                      ...address,
                      address: {
                        ...address.address,
                        addressLine1: event.target.value.trim(),
                      },
                    });
                  } else {
                    setAddress({
                      ...address,
                      addressLine1: event.target.value.trim(),
                    });
                  }
                  setCanSave(false);
                },
              }}
              required
              label='Address line 1'
              messageType={errors.addressLine1}
            />
            <Input
              width='100%'
              inputProps={{
                value: _address.addressLine2,
                isDisabled: !canUpdateAddress,
                placeholder: 'Address line 2',
                onBlur: onBlur('addressLine2'),
                onChange: event => {
                  setAddress(
                    isV3API
                      ? {
                          ...address,
                          address: {
                            ...address.address,
                            addressLine2: event.target.value.trim(),
                          },
                        }
                      : {
                          ...address,
                          addressLine2: event.target.value.trim(),
                        }
                  );
                  setCanSave(false);
                },
              }}
              label='Address line 2'
            />
            <Input
              width='100%'
              inputProps={{
                value: _address.addressLine3,
                isDisabled: !canUpdateAddress,
                placeholder: 'Address line 3',
                onBlur: onBlur('addressLine3'),
                onChange: event => {
                  setAddress(
                    isV3API
                      ? {
                          ...address,
                          address: {
                            ...address.address,
                            addressLine3: event.target.value.trim(),
                          },
                        }
                      : {
                          ...address,
                          addressLine3: event.target.value.trim(),
                        }
                  );
                  setCanSave(false);
                },
              }}
              label='Address line 3'
            />
            <Input
              width='100%'
              inputProps={{
                value: _address.addressLine4,
                isDisabled: !canUpdateAddress,
                placeholder: 'Address line 4',
                onBlur: onBlur('addressLine4'),
                onChange: event => {
                  setAddress(
                    isV3API
                      ? {
                          ...address,
                          address: {
                            ...address.address,
                            addressLine4: event.target.value.trim(),
                          },
                        }
                      : {
                          ...address,
                          addressLine4: event.target.value.trim(),
                        }
                  );
                  setCanSave(false);
                },
              }}
              label='Address line 4'
            />

            <GridRow padding={0}>
              <GridCol sm={6} md={6} lg={6}>
                <Dropdown
                  label='Country'
                  messageType={errors.country}
                  options={COUNTRIES}
                  placeholder='Country'
                  required
                  disabled={!canUpdateAddress}
                  // onBlur={onBlur('country')}
                  value={
                    _address.country
                      ? COUNTRIES.find(
                          c =>
                            c.label.toLowerCase() ===
                              _address.country.toLowerCase() ||
                            c.id.toLowerCase() ===
                              _address.country.toLowerCase()
                        )
                      : undefined
                  }
                  onChange={option => {
                    setAddress(
                      isV3API
                        ? {
                            ...address,
                            address: {
                              ...address.address,
                              country: option.id,
                            },
                          }
                        : {
                            ...address,
                            country: option.id,
                          }
                    );
                    setCanSave(true);
                    setErrors({
                      ...errors,
                      country: 'normal',
                    });
                  }}
                  width='100%'
                />
              </GridCol>
              <GridCol sm={6} md={6} lg={6}>
                <Input
                  inputProps={{
                    value: _address.city,
                    isDisabled: !canUpdateAddress,
                    placeholder: 'City',
                    onBlur: onBlur('city'),
                    onChange: event => {
                      setAddress(
                        isV3API
                          ? {
                              ...address,
                              address: {
                                ...address.address,
                                city: event.target.value.trim(),
                              },
                            }
                          : {
                              ...address,
                              city: event.target.value.trim(),
                            }
                      );
                      setCanSave(false);
                    },
                  }}
                  required
                  label='City'
                  messageType={errors.city}
                  width='100%'
                />
              </GridCol>
            </GridRow>
            <Box flex={{ justifyContent: 'space-between' }} gap={4}>
              <Input
                inputProps={{
                  value: _address.county,
                  isDisabled: !canUpdateAddress,
                  placeholder: 'County',
                  onBlur: onBlur('county'),
                  onChange: event => {
                    setAddress(
                      isV3API
                        ? {
                            ...address,
                            address: {
                              ...address.address,
                              county: event.target.value.trim(),
                            },
                          }
                        : {
                            ...address,
                            county: event.target.value.trim(),
                          }
                    );
                    setCanSave(false);
                  },
                }}
                // required
                label='County'
                messageType={errors.county}
              />
              <Input
                inputProps={{
                  value: _address.state,
                  isDisabled: !canUpdateAddress,
                  placeholder: 'State',
                  onBlur: onBlur('state'),
                  onChange: event => {
                    setAddress(
                      isV3API
                        ? {
                            ...address,
                            address: {
                              ...address.address,
                              state: event.target.value.trim(),
                            },
                          }
                        : {
                            ...address,
                            state: event.target.value.trim(),
                          }
                    );
                    setCanSave(false);
                  },
                }}
                // required
                label='State'
                messageType={errors.state}
              />
            </Box>
            <Box flex={{ justifyContent: 'space-between' }} gap={4}>
              <Input
                inputProps={{
                  value: _address.zipCode,
                  isDisabled: !canUpdateAddress,
                  placeholder: 'Zipcode',
                  onBlur: onBlur('zipCode'),
                  onChange: event => {
                    setAddress(
                      isV3API
                        ? {
                            ...address,
                            address: {
                              ...address.address,
                              zipCode: event.target.value.trim(),
                            },
                          }
                        : {
                            ...address,
                            zipCode: event.target.value.trim(),
                          }
                    );
                    setCanSave(false);
                  },
                }}
                // required
                label='Zipcode'
                messageType={errors.postalCode} // don't change it to zipCode
              />
              <Input
                inputProps={{
                  value: _address.postalCode,
                  isDisabled: !canUpdateAddress,
                  placeholder: 'Postal code',
                  onBlur: onBlur('postalCode'),
                  onChange: event => {
                    setAddress(
                      isV3API
                        ? {
                            ...address,
                            address: {
                              ...address.address,
                              postalCode: event.target.value.trim(),
                            },
                          }
                        : {
                            ...address,
                            postalCode: event.target.value.trim(),
                          }
                    );
                    setCanSave(false);
                  },
                }}
                // required
                label='Postal code'
                messageType={errors.postalCode}
              />
            </Box>
            {address.isDefault ? (
              <Tooltip
                id='tooltip-default-address'
                placement='bottom'
                text='Set another channel as default to change this setting.'
                theme='dark'
                showOnDisabled
                showArrow
              >
                <Checkbox label='Set as default' checked disabled />
              </Tooltip>
            ) : (
              <>
                <Checkbox
                  label='Set as default'
                  onClick={() => setDefaultAddModal(true)}
                  disabled={!canUpdateAddress}
                />
                <Modal
                  headerText={'Set this address as default?'}
                  description={`This ${
                    _address.type === 'M' || _address.type === 'SHIPPING'
                      ? 'shipping'
                      : 'billing'
                  } address will replace your default ${
                    _address.type === 'M' || _address.type === 'SHIPPING'
                      ? 'shipping'
                      : 'billing'
                  } address. Do you want to proceed?`}
                  isVisible={defaultAddModal}
                  onClose={() => setDefaultAddModal(false)}
                  onBackdropClick={() => setDefaultAddModal(false)}
                  footerButtons={[
                    {
                      onClick: () => setDefaultAddModal(false),
                      text: 'Cancel',
                      variant: 'secondary',
                    },
                    {
                      onClick: () => {
                        dispatch(setDefaultCustomerAddress(address));
                        setCanSave(true);
                        setDefaultAddModal(false);
                      },
                      text: 'Yes, Confirm',
                      variant: 'primary',
                    },
                  ]}
                />
              </>
            )}
          </Box>
        </GridCol>
      </GridRow>
    </Box>
  );
};

const AddressCardLoader = () => (
  <Box>
    <Box flex={{ justifyContent: 'space-between' }}>
      <Box>
        <SkeletonH1 />
      </Box>
      <Box flex gap={2}>
        <SkeletonButton />
        <SkeletonButton />
      </Box>
    </Box>
    <Tab>
      <TabItem label={`Shipping Addresses`} disabled>
        <Box margin={{ bottom: 4, top: 5 }} />
        <SkeletonH3 />
        {Array(7)
          .fill('')
          .map((_, idx) => (
            <Box key={`key-${idx}`}>
              <Box margin={{ bottom: 4 }} />
              <SkeletonFieldLabel />
              <Box margin={[2, 0, 0, 0]} />
              <SkeletonH3 />
            </Box>
          ))}
      </TabItem>
      <TabItem label={`Billing Addresses`} disabled />
    </Tab>
  </Box>
);

export default CustomerAddresses;
