import React from 'react';
import CustomerDetailsPageCDP from 'modules/CustomerDetails/customerDetailsCDP';
import { hasPermission } from 'src/hooks/hasPermission';
import { CUSTOMER_PERMISSIONS, PERMISSION_ERROR } from 'src/lib/data/constants';
import { Jumbotron } from '@teamfabric/copilot-ui';

const CustomerDetailsPage = props => {
  const canReadCustomer = hasPermission([CUSTOMER_PERMISSIONS.CUST_READ]);
  if (!canReadCustomer) {
    return (
      <Jumbotron
        primaryText={PERMISSION_ERROR.MAIN_ERROR}
        secondaryText={PERMISSION_ERROR.MAIN_ERROR_SECONDARY}
      />
    );
  }
  return (
    <div>
      <CustomerDetailsPageCDP {...props} />
    </div>
  );
};
export default CustomerDetailsPage;
