"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearSearch = exports.getOrganizationsData = exports.handleListResponse = exports.setError = void 0;
var mfa_communication_1 = require("@copilot/mfa-communication");
var _types_1 = require("src/@types");
var constants_1 = require("../constants");
var setError = function (dispatch, errorMsg) {
    dispatch({
        type: _types_1.OrganizationsActionTypes.SET_ORGANIZATIONS_TABLE_ERROR,
        payload: errorMsg,
    });
};
exports.setError = setError;
var handleListResponse = function (dispatch, response) {
    if (Object.prototype.hasOwnProperty.call(response, "error")) {
        (0, exports.setError)(dispatch, "Error loading organizations list.");
    }
    else {
        dispatch({
            type: _types_1.OrganizationsActionTypes.GET_ORGANIZATIONS,
            payload: response,
        });
    }
};
exports.handleListResponse = handleListResponse;
var getOrganizationsData = function (dispatch, props) {
    var offset = props.offset, name = props.name, sortOrder = props.sortOrder, sortBy = props.sortBy, isActive = props.isActive;
    dispatch({ type: _types_1.OrganizationsActionTypes.SET_TABLE_LOADING_STATUS, payload: true });
    var requestObject = {
        limit: constants_1.LIMIT,
        offset: offset,
        name: name,
        sortOrder: sortOrder,
        sortBy: sortBy
    };
    // when nothing is selected, it will be an empty string
    // When empty string do not send `isActive` filter
    if (typeof isActive === "boolean") {
        requestObject['isActive'] = isActive;
    }
    mfa_communication_1.CSRController.listOrganization(requestObject).then(function (response) {
        (0, exports.handleListResponse)(dispatch, response);
    }).catch(function (e) {
        console.error(e);
        (0, exports.handleListResponse)(dispatch, { "error": { 'message': '', 'statusCode': 400 } });
    });
};
exports.getOrganizationsData = getOrganizationsData;
var clearSearch = function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: _types_1.OrganizationsActionTypes.CLEAR_FILTERS, payload: null });
        return [2 /*return*/];
    });
}); };
exports.clearSearch = clearSearch;
