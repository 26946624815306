"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("store/utils");
var actions_1 = require("./actions");
var initialState = {
    segments: [],
    filterAttributes: [],
};
var actions = (_a = {},
    _a[actions_1.ACTIONS.SET_SEGMENTS] = function (state, action) {
        return __assign(__assign({}, state), { segments: action.payload });
    },
    _a[actions_1.ACTIONS.SET_FILTER_ATTRS] = function (state, action) {
        return __assign(__assign({}, state), { filterAttributes: action.payload });
    },
    _a);
exports.default = (0, utils_1.createReducer)(initialState, actions);
