"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupAddresses = exports.setLoader = exports.getOrganization = exports.getOrganizationGroupList = exports.updateOrganizationAddress = exports.createOrganizationAddress = exports.viewOrganizationAddress = exports.getOrganizationAddresses = exports.resetToastr = void 0;
var mfa_communication_1 = require("@copilot/mfa-communication");
var _types_1 = require("src/@types");
/**
 * @param dispatch
 * @description This action helps to reset the Snackbar status
 */
var resetToastr = function (dispatch) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.SET_TOASTR,
        payload: { message: '', type: 'message', showToaster: false },
    });
};
exports.resetToastr = resetToastr;
/**
 *
 * @param dispatch
 * @param error
 * @returns error object
 * @description This function handles the error from all api calls
 */
var handleError = function (dispatch, error) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.SET_TOASTR,
        payload: { message: error === null || error === void 0 ? void 0 : error.message, type: 'alert', showToaster: true },
    });
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: false,
    });
    return error;
};
/**
 *
 * @param dispatch
 * @param props
 * @param organizationId
 * @description This action gets all the addresses to be listed in the table
 */
var getOrganizationAddresses = function (dispatch, props, organizationId) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    mfa_communication_1.CSRController.listOrganizationAddress(props, organizationId)
        .then(function (response) {
        handleListAddress(response, dispatch);
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.getOrganizationAddresses = getOrganizationAddresses;
/**
 * @param dispatch
 * @param props
 * @description This action gets he details of the selected address
 */
var viewOrganizationAddress = function (dispatch, props) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    mfa_communication_1.CSRController.ViewOrganizationAddress(props)
        .then(function (response) {
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.SET_ORGANIZATIONS_ADDRESS,
            payload: response,
        });
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
            payload: false,
        });
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.viewOrganizationAddress = viewOrganizationAddress;
/**
 * @param response
 * @param dispatch
 * @param isCreate
 * @returns
 */
var handleCreateUpdateOrganization = function (response, dispatch, toastText) {
    if (!(response === null || response === void 0 ? void 0 : response.error)) {
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.SET_TOASTR,
            payload: {
                message: "Organization address ".concat(toastText, " successfully"),
                type: 'message',
                showToaster: true,
            },
        });
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.SET_ORGANIZATIONS_ADDRESS,
            payload: response,
        });
        return response;
    }
    else {
        throw response;
    }
};
/**
 * @param response
 * @param dispatch
 * @returns
 */
var handleListAddress = function (response, dispatch) {
    if (!(response === null || response === void 0 ? void 0 : response.error)) {
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.SET_ORGANIZATIONS_ADDRESS_LIST,
            payload: response,
        });
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
            payload: false,
        });
        return response;
    }
    else {
        throw response;
    }
};
/**
 * @param dispatch
 * @param props
 * @param organizationId
 * @description This action helps create an address
 */
var createOrganizationAddress = function (dispatch, props, organizationId) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    return mfa_communication_1.CSRController.CreateOrganizationAddress(props, organizationId)
        .then(function (response) {
        return handleCreateUpdateOrganization(response, dispatch, 'created');
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.createOrganizationAddress = createOrganizationAddress;
/**
 * @param dispatch
 * @param props
 * @param organizationId
 * @param orgAddrId
 * @description This action helps update an existing address
 */
var updateOrganizationAddress = function (dispatch, props, organizationId, orgAddrId) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    return mfa_communication_1.CSRController.EditOrganizationAddress(props, organizationId, orgAddrId)
        .then(function (response) {
        return handleCreateUpdateOrganization(response, dispatch, 'updated');
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.updateOrganizationAddress = updateOrganizationAddress;
/**
 * @param dispatch
 * @param props
 * @param organizationId
 * @description This action helps in fetching all the groups associated to the address
 */
var getOrganizationGroupList = function (dispatch, props, organizationId) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    mfa_communication_1.CSRController.listOrganizationGroup(props, organizationId)
        .then(function (response) {
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.SET_GROUP_LIST,
            payload: response,
        });
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.getOrganizationGroupList = getOrganizationGroupList;
/**
 * @param dispatch
 * @param props
 * @description This action helps in fetching the organization based on orgId for the breadcrumb
 */
var getOrganization = function (dispatch, props) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    mfa_communication_1.CSRController.viewOrganization(props)
        .then(function (response) {
        dispatch({
            type: _types_1.OrganizationAddressActionTypes.SET_ORGANIZATION_NAME,
            payload: response.name,
        });
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.getOrganization = getOrganization;
/**
 * @param dispatch
 * @param props
 * @description This action helps in fetching the organization based on orgId for the breadcrumb
 */
var setLoader = function (dispatch, loaderState) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: loaderState,
    });
};
exports.setLoader = setLoader;
var getGroupAddresses = function (dispatch, params) {
    dispatch({
        type: _types_1.OrganizationAddressActionTypes.START_REQUEST,
        payload: true,
    });
    mfa_communication_1.CSRController.listOrganizationGroupAddress(params)
        .then(function (response) {
        handleListAddress(response, dispatch);
    })
        .catch(function (e) {
        console.error(e);
        return handleError(dispatch, e);
    });
};
exports.getGroupAddresses = getGroupAddresses;
