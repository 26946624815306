import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';

const initialState = {
  savingCustomerAddresses: false,
  customerAddressesSaved: false,
  loadingCustomerAddresses: false,
  customerAddresses: [],
  customerAddressesFromServer: [],
};

const getCustomerAddressesLoadingStatus = (state, action) => {
  return {
    ...state,
    loadingCustomerAddresses: action.payload,
  };
};

const getCustomerAddresses = (state, action) => {
  return {
    ...state,
    customerAddresses: action.payload,
    customerAddressesFromServer: action.payload,
  };
};

const addToCustomerAddresses = (state, action) => {
  return {
    ...state,
    customerAddresses: [...state.customerAddresses, action.payload],
  };
};

const updateCustomerAddress = (state, action) => {
  return {
    ...state,
    customerAddresses: state.customerAddresses.map(address =>
      (action.payload._id && action.payload._id === address._id) ||
      (action.payload.id && action.payload.id === address.id)
        ? action.payload
        : address
    ),
  };
};

const setDefaultCustomerAddress = (state, action) => {
  return {
    ...state,
    customerAddresses: state.customerAddresses.map(address =>
      (action.payload._id && action.payload._id === address._id) ||
      (action.payload.id && action.payload.id === address.id)
        ? action.payload
        : {
            ...address,
            isDefault:
              (address.type && address.type === action.payload.type) ||
              (address.address?.type &&
                address.address.type === action.payload.address.type)
                ? false
                : address.isDefault,
          }
    ),
  };
};

const savingCustomerAddress = state => {
  return {
    ...state,
    savingCustomerAddresses: true,
    customerAddressesSaved: false,
  };
};

const customerAddressesSaved = state => {
  const savedAddress = state.customerAddresses.map(address => ({
    ...address,
    isNew: false,
    errors: undefined,
  }));
  return {
    ...state,
    customerAddresses: savedAddress,
    customerAddressesFromServer: savedAddress,
    savingCustomerAddresses: false,
    customerAddressesSaved: true,
  };
};

const closeNotification = state => {
  return {
    ...state,
    customerAddressesSaved: false,
  };
};

const resetCustomerAddress = state => {
  return {
    ...state,
    customerAddresses: state.customerAddressesFromServer,
  };
};

export default createReducer(initialState, {
  [ACTIONS.GET_CUSTOMER_ADDRESSES]: getCustomerAddresses,
  [ACTIONS.RESET_CUSTOMER_ADDRESSES]: resetCustomerAddress,
  [ACTIONS.ADD_TO_CUSTOMER_ADDRESSES]: addToCustomerAddresses,
  [ACTIONS.UPDATE_CUSTOMER_ADDRESS]: updateCustomerAddress,
  [ACTIONS.SET_DEFAULT_CUSTOMER_ADDRESS]: setDefaultCustomerAddress,
  [ACTIONS.LOADING_CUSTOMER_ADDRESSES]: getCustomerAddressesLoadingStatus,
  [ACTIONS.SAVING_CUSTOMER_ADDRESSES]: savingCustomerAddress,
  [ACTIONS.CUSTOMER_ADDRESSES_SAVED]: customerAddressesSaved,
  [ACTIONS.CLOSE_NOTIFICATION]: closeNotification,
});
