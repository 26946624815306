"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEnabledEnvironment = exports.isEnabledAccount = exports.hasPermission = void 0;
var copilot_utilities_1 = require("@teamfabric/copilot-utilities");
var hasPermission = function (permissionNames) {
    var isIdv2Login = localStorage.getItem('isLoggedInWithIdV2');
    if (isIdv2Login === 'true') {
        var hasAccess = copilot_utilities_1.rbac.checkPermission({
            allowedPermissions: permissionNames,
        }).hasAccess;
        return hasAccess;
    }
    else {
        return true;
    }
};
exports.hasPermission = hasPermission;
var isEnabledAccount = function (flag) {
    var sessionAccountId = window.sessionStorage.accountId;
    return (flag.active &&
        flag.strategies[0].parameters.userIds.split(',').includes(sessionAccountId));
};
exports.isEnabledAccount = isEnabledAccount;
var isEnabledEnvironment = function (flag) {
    if (!flag.active) {
        return false;
    }
    var enabledEnvironment = false;
    flag.strategies[0].scopes.forEach(function (scope) {
        switch (scope.environment_scope) {
            case 'development': {
                if (window.location.host.includes('localhost') ||
                    window.location.host.includes('dev02')) {
                    enabledEnvironment = true;
                }
                break;
            }
            case 'staging': {
                if (window.location.host.includes('stg02')) {
                    enabledEnvironment = true;
                }
                break;
            }
            case 'prod02': {
                if (window.location.host.includes('prod02')) {
                    enabledEnvironment = true;
                }
                break;
            }
            case 'sandbox': {
                if (window.location.host.includes('sandbox')) {
                    enabledEnvironment = true;
                }
                break;
            }
            case 'production': {
                if (window.location.host.includes('live')) {
                    enabledEnvironment = true;
                }
                break;
            }
            default: {
                console.warn('Unexpected environment scope', scope.environment_scope);
                break;
            }
        }
    });
    return enabledEnvironment;
};
exports.isEnabledEnvironment = isEnabledEnvironment;
